import { v1 as uuidV1 } from 'uuid';
import { dossierCEESidebarDocumentFileTypes } from '../../enums';
import { MANDATORY_DOSSIER_CEE_DOCUMENT } from '../../messages';
import { isSitePhotoDocument, isSitePhotoMandatoryDocument } from '../utils/utils';

export const formatSitePhotosAsDocument = (dossierCEE) => {
    const { uploadDocuments = [], mandatoryDocuments = [] } = dossierCEE;
    const sitePhotoFileDownloadUrls = uploadDocuments.filter(isSitePhotoDocument).map(({ fileDownloadUrl, comment }) => ({ fileDownloadUrl, comment }));
    const formattedSitePhoto = {
        uuid: uuidV1(),
        title: `Photos du site`.concat(sitePhotoFileDownloadUrls.length > 0 ? ` (${sitePhotoFileDownloadUrls.length})` : ''),
        fileDownloadUrls: sitePhotoFileDownloadUrls,
        fileType: dossierCEESidebarDocumentFileTypes.IMAGES,
        documentType: dossierCEESidebarDocumentFileTypes.SITE_PHOTO,
        message: MANDATORY_DOSSIER_CEE_DOCUMENT,
        position: 10,
        available: true,
        valid: true,
    };

    if (isSitePhotoMandatoryDocument(mandatoryDocuments)) {
        if (sitePhotoFileDownloadUrls.length === 0) {
            formattedSitePhoto.available = false;
            formattedSitePhoto.valid = false;
        }

        return formattedSitePhoto;
    }

    return sitePhotoFileDownloadUrls.length > 0 && formattedSitePhoto;
};
