import {
    LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_ONE,
    LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_TWO,
    LABEL_SELECT_BACKUP_ENERGY,
    LABEL_SELECT_RACKING_PROFILE,
} from '../../product-constants';
import { LABEL_INPUT_TANK_CAPACITY_IN_LITER } from '../../form_labels';

export default [
    {
        property: LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_ONE,
        label: LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_ONE,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_TWO,
        label: LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_TWO,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_SELECT_BACKUP_ENERGY,
        label: LABEL_SELECT_BACKUP_ENERGY,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_SELECT_RACKING_PROFILE,
        label: LABEL_SELECT_RACKING_PROFILE,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_INPUT_TANK_CAPACITY_IN_LITER,
        label: LABEL_INPUT_TANK_CAPACITY_IN_LITER,
        type: 'string',
        required: true,
    },
];
