import { useCallback, useMemo, useState } from 'react';
import dialogsActions from '../../../../../../../store/actions/ui/dialogs';
import { useDispatch } from 'react-redux';
import { FINDER_SEARCH_ERROR } from '../../../../../../../utils/messages/dialog';
import { debounce } from 'lodash';

export const useFinder = ({
    finderService,
    formatSearchResult = null,
    onUpdateElement = null,
    onCreateElement = null,
    formatDataOnCreate,
    toAppendToSearchString,
    formatDataOnEdit,
    onEditElement,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchedEntities, setSearchEntities] = useState([]);
    const dispatch = useDispatch();

    const formatOptions = useCallback((entities) => (formatSearchResult ? formatSearchResult(entities) : entities.map((entity) => ({ label: entity.socialReason, value: entity }))), [formatSearchResult]);

    const unDebounceSearch = useCallback(
        ({ value: searchString }) => {
            setIsLoading(true);
            finderService
                .search(searchString)
                .then(({ data }) => {
                    setSearchEntities(formatOptions(data));
                    setIsLoading(false);
                })
                .catch((e) => {
                    dispatch(dialogsActions.showFailureDialog({ message: FINDER_SEARCH_ERROR }));
                    setSearchEntities([]);
                    setIsLoading(false);
                });
        },
        [dispatch, formatOptions, finderService]
    );

    const onSearch = useMemo(() => debounce((values) => unDebounceSearch(values), 300), [unDebounceSearch]);
    const createEntity = (data) => async () => finderService.create(formatDataOnCreate ? formatDataOnCreate(data) : data);

    const onCreate = (data) => (onCreateElement ? onCreateElement(createEntity(data)) : createEntity(data));

    const editEntity = (id, data) => async () => finderService.edit(id, formatDataOnEdit ? formatDataOnEdit(data) : data);

    const onEdit = (id, data) => (onEditElement ? onEditElement(editEntity(id, data)) : editEntity(id, data));
    const onSelect = ({ option }) => onUpdateElement && onUpdateElement(option.value);

    return {
        isLoading,
        searchedEntities,
        onSearch,
        onSelect,
        onCreate,
        onEdit,
    };
};
