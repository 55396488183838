export const UPLOAD_DOCUMENT_NOT_DELETABLE_DOSSIER_CEE_SENT = 'Ce document est rattaché à un dossier de financement déjà envoyé et ne peut donc pas être supprimé';
export const UPLOAD_DOCUMENT_NOT_DELETABLE_INVOICED = 'Ce devis est rattaché à une facture valide et ne peut donc pas être supprimé';

export const NO_UPLOADED_DOCUMENTS = "Aucun document n'a été ajouté.";

export const UPLOAD_DOCUMENT_CREATED = 'Votre document a bien été enregistré';

export const UPLOAD_DOCUMENT_CHECK_REPORT_UPDATED = 'Le document a bien été mis à jour';

export const LABEL_REFUSAL_REASON = 'Motif de refus';
export const LABEL_ENTER_REFUSAL_REASON = 'Veuillez rentrer le motif de refus du document';
