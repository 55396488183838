import { useState, useEffect } from 'react';

export default function ({ file, downloadUrl } = {}) {
    const [url, setUrl] = useState();
    useEffect(() => {
        if (file) setUrl(URL.createObjectURL(file));
        else if (downloadUrl) setUrl(encodeURI(downloadUrl));
    }, [file, downloadUrl]);

    return { url };
}
