import { v1 as uuidV1 } from 'uuid';
import {
    getDocumentDownloadLinkAvailableOrUnsignedFileDownloadUrl,
    getDocumentFileTypeFromDownloadUrlOrUnsignedFileDownloadUrl,
    getDocumentTitle,
    isDocumentDownloadLinkOrUnsignedFileDownloadUrlAvailable,
} from '../utils/utils';
import { dossierCEEDocumentTypes } from '../../enums';
import { INVALID_DOSSIER_CEE_QUOTATION, VALID_DOSSIER_CEE_CLAIM_ASSIGNMENT } from '../../messages';

export const formatClaimAssignmentAsDocument = (dossierCEE) => {
    const {
        quotation: { claimAssignment },
    } = dossierCEE;

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.CLAIM_ASSIGNMENT),
        documentType: dossierCEEDocumentTypes.CLAIM_ASSIGNMENT,
        subTitle: VALID_DOSSIER_CEE_CLAIM_ASSIGNMENT,
        available: isDocumentDownloadLinkOrUnsignedFileDownloadUrlAvailable(claimAssignment),
        fileDownloadUrl: getDocumentDownloadLinkAvailableOrUnsignedFileDownloadUrl(claimAssignment),
        fileType: getDocumentFileTypeFromDownloadUrlOrUnsignedFileDownloadUrl(claimAssignment),
        message: INVALID_DOSSIER_CEE_QUOTATION,
        position: 9,
    };
};
