import { productCategoriesValues as productCategories, productSubCategories } from '../enums';
import { LABEL_SELECT_PRODUCT_SUB_CATEGORY } from '../form_labels';
import operationSheetNames from '../operation-sheet-names';

export default [
    {
        property: 'category',
        label: 'Catégorie',
        type: 'string',
        required: true,
    },
    {
        property: 'title',
        label: 'Nom',
        type: 'string',
        requiredIf: ({ operationSheetName }) => ![operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName),
    },
    {
        property: 'brand',
        label: 'Marque',
        type: 'string',
        requiredIf: ({ operationSheetName }) => ![operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName),
    },
    {
        property: 'reference',
        label: 'Référence',
        type: 'string',
        requiredIf: ({ operationSheetName }) => ![operationSheetNames.BAT_EN_109, operationSheetNames.BAR_EN_109].includes(operationSheetName),
    },
    {
        property: 'certification',
        label: 'Certification',
        type: 'string',
        requiredIf: ({ category }) => category === productCategories.ISOLANT,
    },
    {
        property: 'subCategory',
        label: LABEL_SELECT_PRODUCT_SUB_CATEGORY,
        type: 'string',
        requiredIf: ({ category }) => Object.keys(productSubCategories).includes(category),
    },
    {
        property: 'technicalSheetFile',
        label: 'Fiche technique produit',
        type: 'string',
        requiredIf: (product) => {
            return !product?.uuid && !product.technicalSheetUrl;
        },
    },
];
