export default {
    RENOLIB: { name: 'RENOLIB', siren: 848905949 },
    VITOGAZ: {
        name: 'VITOGAZ FRANCE',
        siren: 323069112,
        contractMandatoryInformation:
            "Prime déduite et financée par VITOGAZ FRANCE (SIREN : 323 069 112) au titre du dispositif des Certificats d'Economie d'Energie. Cette incitation financière, hors champ d'application de la TVA (remise appliquée sur le total TTC), sera réévaluée le cas échéant à la fin de l'opération en fonction des données techniques indiquées sur l'attestation sur l'Honneur et sur la facture.",
    },
    ENR_GP: { name: 'ENR GP', siren: 901683235 },
    ENR_GUYANE: { name: 'ENR GUYANE', siren: 902252857 },
    ENR_FREE_MARTINIQUE: { name: 'ENR FREE MARTINIQUE', siren: 901309518 },
    ZENITH_SOLAR_DISTRIBUTION: { name: 'ZENITH SOLAR DISTRIBUTION', siren: 810046813 },
    GMG_TECH_SAS: { name: 'GMG TECH SAS', siren: 891945180 },
    IDEX: { name: 'IDEX ÉNERGIES', siren: 315871640 },
    TEKSIAL: { name: 'TEKSIAL', siren: 501498141 },
    EBS_ENERGIE: { name: 'EBS', siren: 533333118 },
    OK: { name: 'OK', siren: 532817418 },
    SOGEFA: { name: 'SOGEFA', siren: 890397961 },
};
