import { DOSSIER_CEE_RGE_CERTIFICATES } from '../../messages';
import { dossierCEESidebarDocumentFileTypes } from '../../enums';

export const extractRgeDocuments = (dossierCEE) => {
    return dossierCEE?.rgeCertificates?.length > 0
        ? [
              {
                  fileType: dossierCEESidebarDocumentFileTypes.PDFS,
                  valid: true,
                  available: true,
                  documentType: DOSSIER_CEE_RGE_CERTIFICATES,
                  fileDownloadUrls: dossierCEE.rgeCertificates.map(({ fileDownloadUrl, reference }) => fileDownloadUrl),
                  position: 10,
                  title: `${DOSSIER_CEE_RGE_CERTIFICATES}`,
              },
          ]
        : [];
};
