export const QUOTATION_NOT_SENDABLE_NOR_PRINTABLE = "Merci de compléter et enregistrer le devis avant d'effectuer cette action";
export const QUOTATION_NOT_SENDABLE_AND_SIGNED = 'Ce devis a déjà été signé par le client et ne peut donc plus être envoyé';
export const QUOTATION_NOT_SENDABLE_AND_INVOICED = 'Ce devis a déjà été facturé et ne peut donc plus être envoyé';
export const QUOTATION_NOT_SENDABLE_BECAUSE_OF_EXPIRATION = 'La date de fin de validité de ce devis est dépassée. Le devis ne peut plus être envoyé';
export const QUOTATION_NOT_SENDABLE_BECAUSE_OF_WASTE_DEPOT = 'Ce devis contient une gestion de dechets non valide';
export const QUOTATION_NOT_INVOICEABLE = 'Merci de signer le devis avant de pouvoir créer la facture';
export const QUOTATION_NOT_INVOICEABLE_AND_INVOICED = 'Ce devis a déjà été facturé';
export const QUOTATION_NOT_DELETABLE = 'Ce devis a déjà été signé et ne peut donc plus être supprimé';
export const QUOTATION_NOT_DELETABLE_API_MESSAGE = 'Ce devis a déjà été signé et par conséquent ne peut plus être modifié ou supprimé';
export const QUOTATION_NOT_DELETABLE_AND_INVOICED = 'Ce devis a déjà été facturé et ne peut donc plus être supprimé';
export const QUOTATION_NOT_ADDABLE_OPERATIONS = "Merci de complémenter l'opération ci-dessus avant d'ajouter une nouvelle";
export const QUOTATION_NOT_ADDABLE_OPERATIONS_IF_RENOVATION_GLOBAL = 'Vous ne pouvez pas ajouter une operation si vous réalisez une operation de renovation global (temporaire)';
export const QUOTATION_NOT_INVOICEABLE_BECAUSE_OF_ADVANCE_PAYMENT_INVOICE = "Merci de facturer votre facture d'acompte avant de créer la facture finale";
export const QUOTATION_NOT_INVOICEABLE_BECAUSE_ADVANCE_PAYMENT_INVOICE_ALREADY_EXISTS = "Une facture d'acompte existe déjà pour ce devis";
export const QUOTATION_NOT_INVOICEABLE_BECAUSE_CLASSIC_INVOICE_ALREADY_EXISTS = 'Une facture existe déjà pour ce devis';
export const QUOTATION_NOT_INVOICEABLE_WITH_ADVANCE_PAYMENT_INVOICE = "La facture d'acompte a déjà été facturée pour ce devis";

export const NO_QUOTATIONS = "Aucun devis n'a été créé.";
export const ONE_EURO_QUOTATION_BTN = 'Calculer le devis à 1 €';
export const NO_RESULTS = 'Aucun résultat ne correspond à votre recherche.';
export const NO_SENT_QUOTATIONS = 'Aucun devis envoyé.';
export const NO_SIGNED_QUOTATIONS = 'Aucun devis à facturer.';
export const NO_QUOTATION_VAT_CERTIFICATES = 'Aucune attestation TVA trouvée.';
export const ADMINISTRATIVE_PROBLEM_MESSAGE = 'Problème administratif';
export const TABLE_COLUMN_ANNEXES = 'Annexe(s)';

export const TABLE_COLUMN_CADRES_CONTRIBUTION = 'Cadre(s) de contribution';
export const LABEL_CONSULT_CADRE_CONTRIBUTION = 'Consulter le cadre';
export const LABEL_NO_CADRE_CONTRIBUTION = 'Aucun cadre';

export const BACK_TO_QUOTATION = 'Retourner au devis en cours de saisie';

export const WASTE_MANAGEMENT_DEPOT_CREATION = 'Votre depot a été crée avec succes';
export const WASTE_MANAGEMENT_MODALITIES_DEFAULT_VALUE = 'Tri effectué';

export const INSTALLED_EQUIPMENTS_COUNT_MUST_BE_EQUAL_TO_ROOMS_COUNT =
    "Le nombre d'équipements installés doit être égale ou supérieur au nombre de pièces du logement avec un émetteur de chauffage (ex: T4 pour 4 pièces principales, soit 3 chambres et un séjour)";

export const FINANCING_TITLE = 'Financement du reste à charge';
export const FINANCING_TOOLTIP_CONTENT =
    "Découvrez notre solution du financement du reste à charge ! Avec notre partenaire Budgetlyss, faites profitez vos clients d'une solution simple pour financer le reste à charge de vos clients. Budgetlyss est une société spécialisée dans les offres de crédits innovants.L'objectif ? Vous permettre d'avoir plus d'arguments auprès de vos clients pour réaliser les chantiers";

export const CREDIT_FINANCING_MESSAGE =
    "Utiliser notre partenaire de financement BudgetLyss si votre bénéficiaire souhaite un crédit pour financer le reste à charge ou saisir les informations d'un autre partenaire financier.";

export const FINANCING_SWITCH_TOOLTIP_CONTENT = 'Activer/Désactiver Budgetlyss';

export const CALCULATE_ZERO_EURO_QUOTATION = 'Calculer le devis à 0 €';

export const TEKSIAL_CONTRACT_REQUIRED = 'Vous devez posséder un contrat TEKSIAL';
