import {
    GET_ORGANIZATION_EMAILS_SUCCESS,
    GET_ORGANIZATION_EMAILS_FAILURE,
    GET_CUSTOMER_EMAILS_SUCCESS,
    GET_CUSTOMER_EMAILS_FAILURE,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_FAILURE,
    GET_EMAILS_ANALYTICS_SUCCESS,
} from '../types/mails-history.types';

const initState = () => ({
    emailsSent: [],
    analytics: {
        emailsSent: { totalDifferentCustomers: 0, totalEmailsSent: 0, totalEmailsOpened: 0 },
    },
});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_ORGANIZATION_EMAILS_SUCCESS:
            return {
                ...state,
                emailsSent: action.payload,
            };
        case GET_ORGANIZATION_EMAILS_FAILURE:
            return {
                ...state,
                emailsSent: [],
            };
        case GET_CUSTOMER_EMAILS_SUCCESS:
            return {
                ...state,
                emailsSent: action.payload,
            };
        case GET_CUSTOMER_EMAILS_FAILURE:
            return {
                ...state,
                emailsSent: [],
            };
        case RESEND_EMAIL_SUCCESS:
            return {
                ...state,
            };
        case RESEND_EMAIL_FAILURE:
            return {
                ...state,
            };
        case GET_EMAILS_ANALYTICS_SUCCESS: {
            return {
                ...state,
                analytics: action.payload.analytics,
            };
        }
        default:
            return state;
    }
};
