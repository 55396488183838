import { addressFieldsDescriptorGenerator } from './index';
import {
    LABEL_INPUT_FIRST_NAME,
    LABEL_INPUT_LAST_NAME,
    LABEL_INPUT_NUMBER_OF_DEPENDENTS,
    LABEL_INPUT_REFERENCE_TAX_INCOME,
    LABEL_INPUT_TAX_NOTICE_ISSUE_DATE,
    LABEL_INPUT_TAX_NOTICE_NUMBER,
    LABEL_INPUT_TAX_NOTICE_REFERENCE,
} from '../../form_labels';

export const manualFiscalInformationFormDescriptor = ({ required = true } = {}) => {
    return [
        {
            property: 'taxNoticeNumber',
            label: LABEL_INPUT_TAX_NOTICE_NUMBER,
            type: 'string',
            required,
        },
        {
            property: 'taxNoticeReference',
            label: LABEL_INPUT_TAX_NOTICE_REFERENCE,
            type: 'string',
            required,
        },
        {
            property: 'numberOfDependents',
            label: LABEL_INPUT_NUMBER_OF_DEPENDENTS,
            type: 'number',
            required,
        },
        {
            property: 'referenceTaxIncome',
            label: LABEL_INPUT_REFERENCE_TAX_INCOME,
            type: 'number',
            required,
        },
        {
            property: 'taxNoticeIssueDate',
            label: LABEL_INPUT_TAX_NOTICE_ISSUE_DATE,
            type: 'text',
            required,
        },
        {
            property: 'declarerOneLastName',
            type: 'text',
            label: LABEL_INPUT_LAST_NAME,
            required,
        },
        {
            property: 'declarerOneFirstName',
            type: 'text',
            label: LABEL_INPUT_FIRST_NAME,
            required,
        },
        ...addressFieldsDescriptorGenerator(),
    ];
};
