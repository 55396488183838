import React, { forwardRef } from 'react';
import { UPLOAD_INPUT_TEST_ID } from '../../tests/utils/tests-ids';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const UnStyledFileInput = forwardRef(({ className, defaultId, processFiles, authorizedFileTypes, children }, inputFileRef) => {
    function handleChange(evt) {
        const { files } = evt.target;
        processFiles(files);
    }
    return (
        <div className={className}>
            {children}
            <input ref={inputFileRef} data-testid={UPLOAD_INPUT_TEST_ID} id={defaultId} type='file' role='button' aria-label='File upload' accept={authorizedFileTypes} onChange={handleChange} />
            <label htmlFor={defaultId} />
        </div>
    );
});

export const FileInput = styled(UnStyledFileInput)`
    input {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    label {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
        background: transparent;
        z-index: 1;
    }
`;

FileInput.propTypes = {
    defaultId: PropTypes.string,
    processFiles: PropTypes.func,
    authorizedFileTypes: PropTypes.string,
};
