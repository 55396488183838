import { v1 as uuidV1 } from 'uuid';
import { getDocumentDownloadLink, getDocumentTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';
import { dossierCEEDocumentTypes } from '../../enums';
import { INVALID_DOSSIER_CEE_POST_RENOVATION_REPORT } from '../../messages';

export const formatPostRenovationReportAsDocument = (dossierCEE) => {
    const { postRenovationReport } = dossierCEE;
    const fileType = { postRenovationReport };

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.POST_RENOVATION_REPORT),
        available: isDocumentDownloadLinkAvailable(postRenovationReport),
        fileDownloadUrl: getDocumentDownloadLink(postRenovationReport),
        documentType: dossierCEEDocumentTypes.POST_RENOVATION_REPORT,
        fileType,
        message: INVALID_DOSSIER_CEE_POST_RENOVATION_REPORT,
        position: 5,
    };
};
