import invoiceHelper from '../../invoice-helper';
import { v1 as uuidV1 } from 'uuid';
import { dossierCEESidebarDocumentFileTypes, productStatuses } from '../../enums';
import { MANDATORY_DOSSIER_CEE_DOCUMENT } from '../../messages';
import { isOperationOfRenovationGlobal } from '../../renovation-global-helper';

function getOperationsProducts(operations) {
    return operations
        .map(({ operationSheetName, products, renovationGlobal }) => {
            if (isOperationOfRenovationGlobal({ operationSheetName })) return getOperationsProducts(renovationGlobal?.operations);
            return products;
        })
        .flat();
}

function getQuotationOperationFormattedUrlProduct(operationProduct) {
    const { product = {} } = operationProduct;

    if (product.status === productStatuses.CONFIRMED && product?.technicalSheetUrl) {
        let url = product.technicalSheetUrl;

        if (url.includes('http:')) url = url.replace('http:', 'https:');

        return url;
    }
}

export const formatTechnicalSheetAsDocument = (dossierCEE) => {
    const { invoice } = dossierCEE;

    if (!invoiceHelper.isInvoiceActive(invoice))
        return {
            uuid: uuidV1(),
            title: 'Fiche technique produit(s)',
            documentType: 'Fiche technique produit(s)',
            subTitle: 'La facture doit être finalisée',
            position: 10,
            available: false,
            valid: true,
        };

    const quotationOperationProducts = getOperationsProducts(invoice.operations);

    if (quotationOperationProducts.length === 0)
        return {
            uuid: uuidV1(),
            title: 'Fiche technique produit(s)',
            subTitle: 'La facture doit être finalisée',
            documentType: 'Fiche technique produit(s)',
            position: 10,
            available: false,
            valid: true,
        };

    const technicalSheetFileDownloadUrls = quotationOperationProducts.map((operatioProduct) => getQuotationOperationFormattedUrlProduct(operatioProduct)).filter((url) => url);

    const technicalSheetWording = technicalSheetFileDownloadUrls.length === 1 ? 'Fiche technique du produit' : 'Fiches techniques des produits';

    if (technicalSheetFileDownloadUrls.length === 0)
        return {
            uuid: uuidV1(),
            title: 'Fiche technique produit(s)',
            subTitle: 'La facture doit être finalisée',
            documentType: 'Fiche technique produit(s)',
            position: 10,
            available: false,
            valid: true,
        };
    return {
        uuid: uuidV1(),
        title: `${technicalSheetWording} (${technicalSheetFileDownloadUrls.length})`,
        fileDownloadUrls: technicalSheetFileDownloadUrls,
        fileType: dossierCEESidebarDocumentFileTypes.PDFS,
        documentType: 'Fiche technique produit(s)',
        message: MANDATORY_DOSSIER_CEE_DOCUMENT,
        position: 10,
        available: true,
        valid: true,
    };
};
