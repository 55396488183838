import { useState, useCallback } from 'react';
import _ from 'lodash';
import { isValidValue } from '../../utils';

export default function ({ defaultFormState = {}, defaultFormMode = '', onChangeCallback } = {}) {
    const [state, setState] = useState(defaultFormState);
    const [errors, setErrors] = useState({});
    const [mode, setMode] = useState(defaultFormMode);

    const resetFields = ({ propertyToResets }) => {
        setState((currentState) => {
            const updatedState = { ...currentState };
            for (const property of propertyToResets) {
                delete updatedState[property];
            }
            return {
                ...updatedState,
            };
        });
    };

    // doubling the function in fear that the consequence of changing just the handleChangeFunction if too big.
    const handleChangeWithLodash = useCallback(
        ({ name, value, stateUpdates = {}, propertyToResets = [] }) => {
            setState((currentState) => {
                //using lodash here, prevent the state to not be "audit.auditBuilding.name":"hello" and more audit: {auditBuilding:{name: "hello"}}
                if (isValidValue(name)) _.set(currentState, name, value);
                return { ...currentState, ...stateUpdates };
            });

            if (onChangeCallback) onChangeCallback({ name, value });
            if (propertyToResets.length > 0) resetFields({ propertyToResets });
        },
        [onChangeCallback]
    );

    const handleChange = useCallback(
        ({ name, value, stateUpdates = {}, propertyToResets = [] }) => {
            const inputChanges = {};
            if (isValidValue(name)) inputChanges[name] = value;

            setState((currentState) => ({
                ...currentState,
                ...inputChanges,
                ...stateUpdates,
            }));

            if (onChangeCallback) onChangeCallback({ name, value });
            if (propertyToResets.length > 0) resetFields({ propertyToResets });
        },
        [onChangeCallback]
    );

    const reset = useCallback((stateUpdates = {}) => {
        setState(stateUpdates);
        setErrors({});
    }, []);

    function updateState(updates = {}) {
        setState((currentState) => ({
            ...currentState,
            ...updates,
        }));
    }

    return {
        state,
        errors,
        mode,
        handleChange,
        setErrors,
        resetFields,
        reset,
        updateState,
        setMode,
        handleChangeWithLodash,
    };
}
