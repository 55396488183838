export default [
    {
        property: 'professionalSignatureDate',
        label: 'Date de signature du professionnel',
        type: 'string',
    },
    {
        property: 'beneficiarySignatureDate',
        label: 'Date de signature du bénéficiaire',
        type: 'string',
        required: true,
    },
];
