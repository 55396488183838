import { lazy } from 'react';

export const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem('page-has-been-force-refreshed') || 'false');
        try {
            const component = await componentImport();
            window.localStorage.setItem('page-has-been-force-refreshed', 'false');
            return component;
        } catch (error) {
            console.error({ error });
            if (!pageHasAlreadyBeenForceRefreshed && error?.message?.includes('Loading chunk')) {
                window.localStorage.setItem('page-has-been-force-refreshed', 'true');
                throw new Error('ChunkError');
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });
