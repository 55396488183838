import _ from 'lodash';
import { DOSSIER_CEE_PAGE_TAX_NOTICES_CARD_ON_SIDEBAR_UUID } from '../../default-uuids';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentBlockTypes, dossierCEESidebarDocumentFileTypes, housingSituations } from '../../enums';
import { shouldRetrieveAllOccupants } from '../../dossier-cee-helper';
import { DOSSIER_CEE_TAX_NOTICES_LABEL } from '../../messages';

function formatTaxNoticesLabel(count = 1) {
    return DOSSIER_CEE_TAX_NOTICES_LABEL.concat(count > 1 ? ` (${count})` : '');
}

function getQuotationRenovationAddressOccupants(quotation) {
    const householdIncomeBonification = _.get(quotation, 'address.householdIncomeBonification', {});
    if (!householdIncomeBonification.valid) return [];
    const { housings } = householdIncomeBonification;
    return housings
        .map(({ occupants = [] }) => {
            return occupants;
        })
        .flat();
}

function getDossierCEERenovationAddressNonClassicSituationOccupants(dossierCEE) {
    const householdIncomeBonification = _.get(dossierCEE, 'quotation.address.householdIncomeBonification', {});
    const nonClassicOccupants = [];

    if (householdIncomeBonification.valid) {
        const { housings } = householdIncomeBonification;
        housings.forEach(({ occupants = [], situation }) => {
            if (situation === housingSituations.CLASSIC) return;
            nonClassicOccupants.push(occupants);
        });
    }

    return nonClassicOccupants.flat();
}

function getTaxNoticesDocumentTitle(nonClassicOccupantsTaxNoticeFileDownloadUrls = []) {
    return formatTaxNoticesLabel(nonClassicOccupantsTaxNoticeFileDownloadUrls.length);
}

const getTaxNoticeOccupants = (dossierCEE) => {
    const { quotation } = dossierCEE;
    if (shouldRetrieveAllOccupants(quotation)) {
        return getQuotationRenovationAddressOccupants(quotation);
    }
    return getDossierCEERenovationAddressNonClassicSituationOccupants(dossierCEE);
};

const formatTaxNoticesAsDocumentWhenImpotIsDown = (occupants) => {
    const areDocumentsPdfs = occupants[0].fileDownloadUrl ? occupants[0].fileDownloadUrl.includes('.pdf') : false;
    const occupantsWithTaxNotice = occupants.filter((occupant) => occupant.fileDownloadUrl);

    return {
        uuid: DOSSIER_CEE_PAGE_TAX_NOTICES_CARD_ON_SIDEBAR_UUID,
        title: getTaxNoticesDocumentTitle(occupants),
        isImpotDown: true,
        available: true,
        documentType: dossierCEEDocumentTypes.TAX_NOTICE,
        fileDownloadUrls: areDocumentsPdfs ? occupantsWithTaxNotice.map((occupant) => occupant.fileDownloadUrl) : occupantsWithTaxNotice.length > 0 ? occupantsWithTaxNotice : occupants,
        fileType: areDocumentsPdfs ? dossierCEESidebarDocumentFileTypes.PDFS : dossierCEESidebarDocumentFileTypes.IMAGES,
        blockType: dossierCEESidebarDocumentBlockTypes.TAX_NOTICES,
        position: 8,
    };
};

export const formatTaxNoticesAsDocument = (dossierCEE) => {
    const occupants = getTaxNoticeOccupants(dossierCEE);
    if (occupants.length === 0) return null;

    const occupantsTaxNoticeFileDownloadUrls = occupants.map(({ taxNoticeFileDownloadUrl }) => ({ fileDownloadUrl: taxNoticeFileDownloadUrl }));
    return formatTaxNoticesAsDocumentWhenImpotIsDown(occupantsTaxNoticeFileDownloadUrls);
};
