import React from 'react';
import { Card } from '@renolib/renolib-ui-kit';

import logoImage from '../../assets/img/logo/logo-colorized.svg';
import clockImage from '../../assets/img/others/clock.png';

import MaintenanceStyled from './Maintenance.styled';

function Maintenance() {
    return (
        <MaintenanceStyled>
            <Card>
                <main>
                    <img src={clockImage} alt='clock' />
                    <div>
                        <h2>Patience</h2>
                        <p>Le service est indisponible pour le moment.</p>
                        <p>Notre équipe technique travaille dessus.</p>
                        <p>
                            Veuillez réessayer dans quelques minutes.{' '}
                            <span role='img' aria-label='muscle'>
                                &#128170;
                            </span>
                        </p>
                    </div>
                </main>
                <footer>
                    <img src={logoImage} alt='logo' />
                </footer>
            </Card>
        </MaintenanceStyled>
    );
}

export default Maintenance;
