function formatFileName(fileName) {
    return fileName.replaceAll(/[ ]/g, '_');
}

function downloadFile({ fileName, fileDownloadUrl }) {
    const linkTag = document.createElement('a');
    linkTag.href = fileDownloadUrl;
    linkTag.target = '_blank';
    linkTag.rel = 'noopener noreferrer';
    linkTag.download = formatFileName(fileName);

    document.body.appendChild(linkTag);
    linkTag.click();
    linkTag.parentNode.removeChild(linkTag);
}

function downloadCSVFile({ fileName, csvString }) {
    const universalBOM = '\uFEFF';
    const fileDownloadUrl = 'data:text/csv; charset-utf-8,'.concat(encodeURIComponent(universalBOM.concat(csvString)));
    downloadFile({ fileName: fileName.concat('.csv'), fileDownloadUrl });
}

export default { downloadFile, downloadCSVFile };
