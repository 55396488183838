export const CREATE_UPLOAD_DOCUMENT_REQUEST = 'CREATE_UPLOAD_DOCUMENT_REQUEST';
export const CREATE_UPLOAD_DOCUMENT_SUCCESS = 'CREATE_UPLOAD_DOCUMENT_SUCCESS';
export const CREATE_UPLOAD_DOCUMENT_FAILURE = 'CREATE_UPLOAD_DOCUMENT_FAILURE';

export const GET_UPLOAD_DOCUMENTS_REQUEST = 'GET_UPLOAD_DOCUMENTS_REQUEST';
export const GET_UPLOAD_DOCUMENTS_SUCCESS = 'GET_UPLOAD_DOCUMENTS_SUCCESS';
export const GET_UPLOAD_DOCUMENTS_FAILURE = 'GET_UPLOAD_DOCUMENTS_FAILURE';

export const GET_UPLOAD_DOCUMENT_REQUEST = 'GET_UPLOAD_DOCUMENT_REQUEST';
export const GET_UPLOAD_DOCUMENT_SUCCESS = 'GET_UPLOAD_DOCUMENT_SUCCESS';
export const GET_UPLOAD_DOCUMENT_FAILURE = 'GET_UPLOAD_DOCUMENT_FAILURE';

export const DELETE_UPLOAD_DOCUMENT_REQUEST = 'DELETE_UPLOAD_DOCUMENT_REQUEST';
export const DELETE_UPLOAD_DOCUMENT_SUCCESS = 'DELETE_UPLOAD_DOCUMENT_SUCCESS';
export const DELETE_UPLOAD_DOCUMENT_FAILURE = 'DELETE_UPLOAD_DOCUMENT_FAILURE';

export const DELETE_FILE_UPLOAD_DOCUMENT_REQUEST = 'DELETE_FILE_UPLOAD_DOCUMENT_REQUEST';
export const DELETE_FILE_UPLOAD_DOCUMENT_SUCCESS = 'DELETE_FILE_UPLOAD_DOCUMENT_SUCCESS';
export const DELETE_FILE_UPLOAD_DOCUMENT_FAILURE = 'DELETE_FILE_UPLOAD_DOCUMENT_FAILURE';

export const GET_CHECK_DOCUMENT_REQUEST = 'GET_CHECK_DOCUMENT_REQUEST';
export const GET_CHECK_DOCUMENT_SUCCESS = 'GET_CHECK_DOCUMENT_SUCCESS';
export const GET_CHECK_DOCUMENT_FAILURE = 'GET_CHECK_DOCUMENT_FAILURE';

export const RESET_DOCUMENT_CHECK_MESSAGE = 'RESET_DOCUMENT_CHECK_MESSAGE';

export const UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_REQUEST = 'UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_REQUEST';
export const UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_SUCCESS = 'UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_SUCCESS';
export const UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_FAILURE = 'UPDATE_UPLOAD_DOCUMENT_CHECK_REPORT_FAILURE';
