export default {
    primary: '#1d3495',
    primaryLight0: '#E5E8F3',
    primaryLight1: '#1d34951c',
    primaryLight2: '#4c93e3',
    primaryLight3: '#0026c8',
    primaryDark: '#02197a',
    primaryDark0: '#1d3495c0',
    primaryDark1: '#1d3495c0',
    primaryDark2: '#176ac7',
    primaryDark3: '#001c96',
    primaryOutlineLight: '#1D349519',
    primaryOverlay: 'rgba(29, 44, 107, 0.75)',
    secondary: '#00928f',
    label: '#74798c',
    secondaryLight1: '#31aeac33',
    secondaryLight2: '#03828035',
    secondaryLight3: '#31aeac',
    secondaryDark: '#097877',
    labelDark: '#097877',
    secondaryDark1: '#31aeacf8',
    secondaryDark2: '#038280ca',
    light: '#ffffff',
    lightDark: '#ffffff',
    lightGray: '#A0A5BA',
    black: '#000000',
    white: '#ffffff',
    quotationBlocksBorder: '#33f1e6',
    green: '#4ebc9d',
    red: '#e46526',
    warning: '#e2b844',
    tableBlock: '#f8f8f8',
    tableBorder: '#9ea5c1',
    error: '#dd2c00',
    noValidated: '#eaeaea',
    pending: '#e2b844',
    confirmed: '#31aeac',
    rejected: '#e6743c',
    container: '#f5f6fa',
    divider: '#e2e2e2',
    icon: '#90b1e1',
    new_gray: '#f6f8fa',
    new_orange: '#ff9635',
    emerald_green: '#5AC17A',
    blue_jeans: '#66A4FF',
    french_sky_blue: '#72A4FC',
    lighter_blue_jeans: '#66a4ff40',
    pale_aqua: '#D3E2FB',
    gray: '#808080',
    disabled: '#c5c5c5',
    shadow: {
        primary: '#90b1e161',
    },
    scrollbar: {
        thumb: {
            default: '#99a3ac',
        },
        thumbHover: {
            default: '#b30000',
        },
    },
    product: {
        status: { PENDING: '#e2b844', CONFIRMED: '#31aeac', UNVERIFIED: '#74798C1A' },
    },
    input: {
        label: '#74798c',
        placeholder: '#99a3ac',
        border: '#e4e4e4',
    },
    statusRed: '#e45050',
    statusGold: '#b6a97a',
    statusGreen: '#71d688',
    sea_green: '#2aa4a2',
    gold: 'rgba(233, 189, 92, 1)',
    deepSaffron: '#FF9635',
    fireOpal: '#E55055',
    light_dark: 'rgba(0, 0, 0, 0.5)',
    generic: {
        gray: '#D4849A',
        green: '#50C878',
        red: '#FF2626',
    },
    boxShadow: '0px 0px 8px #90b1e166',
    grean_sheen: '#6EBAA0 ',
    brass: '#BA9839',
    lightblue: '#66a4fe',
    dossierStatuses: {
        toSignQuotation: '#808080',
        waitingGrantLetter: '#E9BD5C',
        waitingInvoice: '#66A4FF',
        toComplete: '#E55050',
        inTreatmentRenolib: '#E98F5C',
        validatedByRenolib: '#5AC17A',
        toRegularize: '#A44B19',
        cancelled: '#F60000',
    },
};
