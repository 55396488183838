import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { v1 as uuidV1 } from 'uuid';
import { getDocumentTitle } from '../utils/utils';

export const formatAuditBeforeRenovationAsDocument = (dossierCEE) => {
    const { uploadDocuments = [] } = dossierCEE;
    const document = uploadDocuments.find(({ documentType, deleted }) => documentType === dossierCEEDocumentTypes.AUDIT_BEFORE_RENOVATION && !deleted);

    if (document)
        return {
            uuid: uuidV1(),
            title: getDocumentTitle(dossierCEEDocumentTypes.AUDIT_BEFORE_RENOVATION),
            fileDownloadUrl: document.fileDownloadUrl,
            documentType: dossierCEEDocumentTypes.AUDIT_BEFORE_RENOVATION,
            fileType: dossierCEESidebarDocumentFileTypes.PDF,
            available: !!document.fileDownloadUrl,
            position: 10,
        };

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.AUDIT_BEFORE_RENOVATION),
        documentType: dossierCEEDocumentTypes.AUDIT_BEFORE_RENOVATION,
        message: 'À ajouter',
        position: 10,
    };
};
