import { useCallback, useState } from 'react';

export default function ({ defaultTitle = '' } = {}) {
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState(defaultTitle);

    const handleShow = useCallback(() => {
        setShow(true);
    }, []);

    function handleHide() {
        setShow(false);
    }

    function toggle() {
        setShow(!show);
    }

    return {
        show,
        title,
        handleShow,
        handleHide,
        toggle,
        setTitle,
    };
}
