import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@renolib/renolib-ui-kit';
import styled from 'styled-components';
import dimensions from '../../config/dimensions';
import useFileViewer from '../hooks/useFileViewer';
import { IMAGE_VIEWER_TEST_ID } from '../../tests/utils/tests-ids';

function UnStyledImageViewer({ className, testId, file, downloadUrl }) {
    const { url } = useFileViewer({ file, downloadUrl });

    return (
        <Card data-testid={testId} className={className}>
            <img src={url} alt='' />
        </Card>
    );
}

const StyledImageViewer = styled(UnStyledImageViewer)`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${getMinHeight};
    max-height: ${getMaxHeight};
    padding: 3rem;

    > img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
    }
`;

function getMinHeight({ minHeight }) {
    return minHeight || dimensions.section.main;
}

function getMaxHeight({ minHeight }) {
    return minHeight || '100%';
}

export default function ImageViewer({ testId = IMAGE_VIEWER_TEST_ID, ...restProps }) {
    return <StyledImageViewer testId={testId} {...restProps} />;
}
ImageViewer.propTypes = {
    testId: PropTypes.string,
    file: PropTypes.shape({}),
    downloadUrl: PropTypes.string,
    minHeight: PropTypes.string,
};
