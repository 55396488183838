import { v1 as uuidV1 } from 'uuid';
import { dossierCEESidebarDocumentFileTypes, uploadDocumentTypesTranslations, uploadDocumentTypes } from '../../enums';
import { getDocumentDownloadLink, isDocumentDownloadLinkAvailable } from '../utils/utils';

export const formatAttestationPublicLightningAsDocument = (dossierCEE) => {
    const { quotation } = dossierCEE;

    const { attestations = [] } = quotation;

    const attestation = attestations.find(({ type }) => type === uploadDocumentTypes.ATTESTATION_PUBLIC_LIGHTNING);

    return {
        uuid: uuidV1(),
        title: uploadDocumentTypesTranslations[attestation.type],
        documentType: uploadDocumentTypes.ATTESTATION_PUBLIC_LIGHTNING,
        available: isDocumentDownloadLinkAvailable(attestation),
        fileDownloadUrl: getDocumentDownloadLink(attestation),
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: 'À signer',
        position: 13,
    };
};
