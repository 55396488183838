import setNewPasswordFormFieldsDescriptor from './set-new-password-form';
import { validateEmailFormField } from './_validations';

export default [
    {
        property: 'lastName',
        label: 'Nom',
        type: 'string',
        required: true,
    },
    {
        property: 'firstName',
        label: 'Prénom',
        type: 'string',
        required: true,
    },
    {
        property: 'email',
        label: 'Adresse email',
        type: 'string',
        dataType: 'emailAddress',
        required: true,
        validate: validateEmailFormField,
    },
    ...setNewPasswordFormFieldsDescriptor,
    {
        property: 'termsOfUseAccepted',
        label: 'Acceptation des conditions',
        type: 'boolean',
        required: true,
    },
];
