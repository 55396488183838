import React, { useState, useEffect, useCallback } from 'react';
import { Field } from '@renolib/renolib-ui-kit';
import Form from '../../../helpers/Form';
import styled from 'styled-components';

const UnStyledSelectOperations = ({ className, quotation, onChange, error }) => {
    const [selectedOperations, setSelectedOperations] = useState([]);

    const onUpdateOperations = ({ name, value }, formattedOperationSheetName) => {
        if (!value) {
            setSelectedOperations(selectedOperations.filter(({ uuid }) => uuid !== name));
            return;
        }
        const updatedOperations = [...selectedOperations];
        updatedOperations.push({ uuid: name, formattedOperationSheetName });
        setSelectedOperations(updatedOperations);
    };

    const setOperationIfThereIsOnlyOne = useCallback(() => {
        if (quotation.operations.length === 1) {
            const operation = quotation.operations[0];
            onChange({ name: 'operations', value: [operation] });
        }
    }, [quotation.operations, onChange]);

    useEffect(() => {
        onChange({ name: 'operations', value: selectedOperations });
    }, [selectedOperations, onChange]);

    useEffect(() => {
        setOperationIfThereIsOnlyOne();
    }, [setOperationIfThereIsOnlyOne]);

    return (
        <div className={className}>
            <Form.Group>
                {quotation.operations.map(({ formattedOperationSheetName, _id, uuid }) => (
                    <Field
                        key={`${quotation.uuid}-${uuid}`}
                        fluid
                        type='checkbox'
                        name={uuid}
                        label={formattedOperationSheetName}
                        disabled={quotation.operations.length === 1}
                        defaultChecked={quotation.operations.length === 1}
                        onChange={(value) => onUpdateOperations(value, formattedOperationSheetName)}
                    />
                ))}
                {error && <span>{error}</span>}
            </Form.Group>
        </div>
    );
};

export const SelectOperations = styled(UnStyledSelectOperations)`
    > div {
        > span:last-child {
            display: inline-block;
            margin-top: 0.25rem;
            font-style: italic;
            color: #dd2c00;
        }
    }
`;
