import moment from 'moment';
import React from 'react';
import { isATeksialDocument } from '../../data/companies.helper';
import { AdvancePaymentConfirmPayment } from '../../components/pages/invoice/AdvancePaymentConfirmPayment';
import { invoiceStatuses } from '../enums';
import operationHelper from '../operation-helper';

export const dispatchSpecificRules = (rule, data) => {
    const rules = {
        canSwitchSubcontractor: () => {
            const isAnOperationOnWhichWeCantChangeSubcontractor = ['BAR_EN_101', 'BAR_EN_102', 'BAR_EN_103'].some((shortName) =>
                operationHelper.isOperationSheetNameEqualToShortName(data.operation, { shortName })
            );
            return !(isAnOperationOnWhichWeCantChangeSubcontractor && isATeksialDocument(data?.invoice));
        },
        getAdvancePaymentDate: () => {
            const { advancePaymentInvoice } = data;
            if (!isATeksialDocument(advancePaymentInvoice)) return '';
            if (advancePaymentInvoice?.status === invoiceStatuses.PAID) return ` - Payé le: ${moment(advancePaymentInvoice?.paymentDate).format('DD/MM/YYYY')}`;
        },
        getConfirmedPaymentMinDate: () => {
            if (isATeksialDocument(data.document)) {
                return moment(data?.document?.quotationSignatureDate).add(8, 'day').toDate();
            }
            return undefined;
        },
        displayConfirmedPaymentInDossierCEE: () =>
            isATeksialDocument(data.document) && data.document.status !== 'PAID'
                ? () => (
                      <div data-name={'rendered-component'} style={{ width: '100%', marginRight: '5px' }}>
                          <AdvancePaymentConfirmPayment invoice={data?.document} onSuccess={data.reload} />
                      </div>
                  )
                : undefined,
        shouldRetrieveAllOccupants: () => {
            return isATeksialDocument(data.document);
        },
    };
    return rules[rule]();
};
