import { LABEL_CHECKBOX_OCCUPANT_IS_BENEFICIARY } from '../../form_labels';

export default [
    {
        property: 'beneficiary',
        label: LABEL_CHECKBOX_OCCUPANT_IS_BENEFICIARY,
        type: 'boolean',
        required: true,
    },
];
