import { GET_CUSTOMER_RENOVATION_ADDRESSES_SUCCESS, CLEAR_CUSTOMER_RENOVATION_ADDRESSES, CREATE_RENOVATION_ADDRESS_SUCCESS } from '../types/renovation-address.types';

function initState() {
    return {
        renovationAddresses: [],
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case GET_CUSTOMER_RENOVATION_ADDRESSES_SUCCESS:
            return {
                renovationAddresses: action.payload.renovationAddresses,
            };

        case CLEAR_CUSTOMER_RENOVATION_ADDRESSES:
            return {
                renovationAddresses: [],
            };
        case CREATE_RENOVATION_ADDRESS_SUCCESS:
            return {
                renovationAddresses: [...state.renovationAddresses, action.payload.renovationAddress],
            };
        default:
            return state;
    }
}
