import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import validationIcon from '../../../../assets/img/others/validation_check.svg';
import errorGrayIcon from '../../../../assets/img/others/error_gray.svg';
import uploadDocumentsIcon from '../../../../assets/img/others/upload-documents.svg';
import { documentPropType } from '../../../../utils/prop-types-schemas';
import colors from '../../../../config/colors';
import { LABEL_IMAGE_INVALID_DOCUMENT, LABEL_IMAGE_UNAVAILABLE_DOCUMENT, LABEL_IMAGE_VALID_DOCUMENT } from '../../../../utils/form_labels';
import { DOCUMENT_BLOCK_TEST_ID } from '../../../../tests/utils/tests-ids';

function UnStyledDocumentBlock({ className, document, selected, onSelectDocument, children }) {
    function handleSelectDocument() {
        if (document.available) onSelectDocument(document);
    }

    return (
        <div data-testid={DOCUMENT_BLOCK_TEST_ID} className={className} onClick={handleSelectDocument}>
            {displayDocumentIcon(document)}
            <span>- - -</span>
            <div>
                <span>{document.title}</span>
                <span>{displayDocumentMessage(document)}</span>
            </div>
            {children}
        </div>
    );
}

function displayDocumentIcon(document) {
    let src = document.available ? (document.valid ? validationIcon : errorGrayIcon) : errorGrayIcon;
    const alt = document.available ? (document.valid ? LABEL_IMAGE_VALID_DOCUMENT : LABEL_IMAGE_INVALID_DOCUMENT) : LABEL_IMAGE_UNAVAILABLE_DOCUMENT;

    if (document.isUploadButton) {
        src = uploadDocumentsIcon;
    }
    return <img src={src} alt={alt} />;
}

function displayDocumentMessage(document) {
    return (document.valid ? document.subTitle : document.message) || '';
}

const StyledDocumentBlock = styled(UnStyledDocumentBlock)`
    padding: 0.75rem 2rem;
    border-radius: 5px;
    margin-left: 2.5rem;
    background: ${getBackgroundColor};
    box-shadow: ${getBoxShadow};
    margin-bottom: 2rem;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${getCursor};
    transition: all 200ms ease-in-out;
    color: ${getTraitsColor};

    :hover {
        box-shadow: ${(props) => getBoxShadow(props, { hovered: true })};
    }

    > img {
        display: inline-block;
        position: absolute;
        height: 2rem;
        margin-right: 1rem;
        left: -0.83rem;
        z-index: 2;
    }

    > span {
        font-weight: bold;
        display: inline-block;
        position: absolute;
        height: 2rem;
        margin-right: 1rem;
        margin-top: 0.75rem;
        left: 1.18rem;
        z-index: 2;
    }

    > div {
        color: ${getTextColor};
        display: flex;
        flex-direction: column;
        text-align: center;

        > span {
            :first-child {
                font-weight: 600;
                margin-bottom: 0.25rem;
            }

            :last-child {
                margin-bottom: 0;
            }
        }
    }
`;

function getBackgroundColor({ document }) {
    return document.available || document.displayAlertColor ? colors.white : '#c9c9c9';
}

function getTextColor({ document }) {
    return document.available || document.displayAlertColor ? colors.primary : colors.white;
}

function getTraitsColor({ document }) {
    return document.available ? colors.primary : colors.disabled;
}

function getBoxShadow(props, { hovered = false } = {}) {
    const { document, selected } = props;
    const defaultBlurRadius = selected ? 15 : 10;
    const blurRadius = hovered ? (document.available ? 20 : defaultBlurRadius) : defaultBlurRadius;
    const alpha = hovered ? (selected ? 0.8 : 0.5) : selected ? 0.95 : 0.3;

    if (document.displayAlertColor) return `0 0 ${blurRadius}px 0 rgba(228, 101, 38, ${alpha})`;

    return `0 0 ${blurRadius}px 0 rgba(144, 177, 225, ${alpha})`;
}

function getCursor({ document }) {
    return document.available || document.displayAlertColor ? 'pointer' : 'not-allowed';
}

export default function DocumentBlock(props) {
    return <StyledDocumentBlock {...props} />;
}
DocumentBlock.propTypes = {
    document: documentPropType.isRequired,
    onSelectDocument: PropTypes.func.isRequired,
};
