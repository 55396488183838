import React from 'react';
import PropTypes from 'prop-types';
import { joinClassNames } from '../../utils';
import { usePdfToolbarVisibility } from '../hooks/usePdfToolbarVisibility';
import styled from 'styled-components';
import dimensions from '../../config/dimensions';
import colors from '../../config/colors';

const RenderLayoutPDFViewer = ({ testId, className, toolbarHidden, render }) => {
    const { toolbarVisible, handleTextLayerRender } = usePdfToolbarVisibility(toolbarHidden);

    const displayLayout = (isSidebarOpened, container, main) => {
        return (
            <div {...container.attrs} style={Object.assign({}, { width: '100%', height: '100%', overflow: 'hidden' }, container.attrs.style)}>
                {container.children}
                <div
                    {...main.attrs}
                    style={Object.assign(
                        {},
                        {
                            height: '100%',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                        },
                        main.attrs.style
                    )}>
                    {main.children}
                </div>
            </div>
        );
    };

    return (
        <div data-testid={testId} className={joinClassNames(className, getClassName(toolbarVisible, toolbarHidden))}>
            {render && render({ handleTextLayerRender, displayLayout: toolbarHidden ? displayLayout : null })}
        </div>
    );
};

export const StyledRenderLayoutPDFViewer = styled(RenderLayoutPDFViewer)`
    position: relative;
    height: ${getHeight};

    .zoom-bar {
        display: flex;
        position: absolute;
        z-index: 2;
        height: 10px;
    }
    > div {
        :first-child {
            width: 100%;
            height: ${getFirstDivHeight};
            display: flex;
            justify-content: center;
            position: ${getFirstDivPosition};
            left: ${getFirstDivLeft};
            top: ${getFirstDivTop};
            bottom: ${getFirstDivBottom};

            > div {
                > div {
                    overflow-x: hidden;
                    overflow-y: auto;
                    height: 80vh;
                    > div {
                        overflow-x: ${getOverflowX} !important;
                        height: 80vh;
                    }

                    ::-webkit-scrollbar {
                        width: ${dimensions.scrollbar.width};
                    }
                    ::-webkit-scrollbar-track {
                        background: ${colors.white};
                    }
                    ::-webkit-scrollbar-thumb {
                        background: ${colors.scrollbar.thumb.default};
                        border-radius: ${dimensions.scrollbar.radius};
                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background: ${colors.scrollbar.thumbHover.default};
                    }
                }
            }

            .viewer-page-layer {
                box-shadow: none;
            }

            .viewer-layout-toolbar {
                background: ${colors.white};
                border-radius: ${dimensions.radius} ${dimensions.radius} 0 0;
            }

            .viewer-toolbar-left {
                display: none;
            }

            .viewer-toolbar-center {
                flex: 1;
                display: flex;
                justify-content: center;
                margin-left: 2rem;
            }

            .viewer-toolbar-right {
                .viewer-toolbar-item {
                    // only display the full screen button
                    :not(:nth-child(1)) {
                        display: none;
                    }
                }
            }

            .viewer-tooltip-body,
            .viewer-popover-body {
                display: none;
            }
            .rpv-core-page-layer {
                margin: auto;
            }
        }

        &.action {
            position: absolute;
            margin-top: 15px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
        }
    }

    &.toolbar-hidden {
        > div {
            :first-child {
                .viewer-layout-toolbar {
                    display: none;
                }
            }
        }
    }

    &.contract-view {
        .action {
            position: relative;
        }
    }
`;

function getOverflowX({ overflowX }) {
    return overflowX || 'hidden';
}

function getHeight({ height }) {
    return height || '100%';
}
function getFirstDivHeight({ full, large, minHeight }) {
    if (minHeight) return minHeight;

    return full ? 'initial' : large ? '80vh' : '60vh';
}

function getFirstDivPosition({ full, minHeight }) {
    return full && !minHeight ? 'absolute' : 'initial';
}

function getFirstDivLeft({ full }) {
    return full ? 0 : 'initial';
}

function getFirstDivTop({ full }) {
    return full ? 0 : 'initial';
}

function getFirstDivBottom({ full }) {
    return full ? 0 : 'initial';
}

function getClassName(toolbarVisible, toolbarHidden) {
    return !toolbarVisible || toolbarHidden ? 'toolbar-hidden' : 'toolbar-visible';
}

StyledRenderLayoutPDFViewer.propTypes = {
    testId: PropTypes.string,
    full: PropTypes.bool,
    large: PropTypes.bool,
    toolbarHidden: PropTypes.bool,
    render: PropTypes.func,
};

RenderLayoutPDFViewer.propTypes = {
    className: PropTypes.string,
    testId: PropTypes.string,
    toolbarHidden: PropTypes.bool,
    render: PropTypes.func,
};
