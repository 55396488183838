import { Button } from '@renolib/renolib-ui-kit';
import React, { Fragment } from 'react';
import ConfirmPaymentModal from '../../core/misc/invoice/modals/ConfirmPaymentModal';
import { dispatchSpecificRules } from '../../../utils/specific_rules/specificRulesDispatcher';
import { useConfirmPayment } from '../../hooks/useConfirmPayment';
import PropTypes from 'prop-types';
import { invoicePropType } from '../../../utils/prop-types-schemas';

export const AdvancePaymentConfirmPayment = ({ invoice, onSuccess }) => {
    const { confirmPaymentForm, confirmPaymentModal, handleConfirmPayment, handleOpenConfirmPaymentModal } = useConfirmPayment({ invoice, onSuccess });
    return (
        <Fragment>
            <Button icon='add' onClick={handleOpenConfirmPaymentModal}>
                {'Confirmer le paiement'}
            </Button>
            {confirmPaymentModal.show && (
                <ConfirmPaymentModal
                    show={confirmPaymentModal.show}
                    onHide={confirmPaymentModal.handleHide}
                    minDate={dispatchSpecificRules('getConfirmedPaymentMinDate', { document: confirmPaymentForm?.state?.invoice })}
                    onCreate={handleConfirmPayment}
                    errors={confirmPaymentForm.errors}
                    onChange={confirmPaymentForm.handleChange}
                />
            )}
        </Fragment>
    );
};

AdvancePaymentConfirmPayment.propTypes = {
    invoice: invoicePropType,
    onSuccess: PropTypes.func,
};
