import { isNullishOrEmpty } from '.';
import { asyncJobScopes, asyncJobStatuses } from './enums';

function isEveryAsyncJobDone({ asyncJobs = [], asyncJobsTriggered = false } = {}) {
    if (isNullishOrEmpty(asyncJobs)) return !asyncJobsTriggered;

    return asyncJobs.filter((asyncJob) => asyncJob.scope === asyncJobScopes.DOCUMENT_GENERATION).every((asyncJob) => asyncJob.status === asyncJobStatuses.SUCCESS);
}

export default { isEveryAsyncJobDone };
