import React, { Fragment } from 'react';
import uploadDocumentHelper from '../../../../utils/upload-document-helper';
import Form from '../../../helpers/Form';
import { Field, Text } from '@renolib/renolib-ui-kit';

export const displayDpeOrAuditEnergeticForm = (documentType, formErrors, onChange) => {
    if (!uploadDocumentHelper.isDpeOrAuditEnergetic(documentType)) return <Fragment />;

    return (
        <Fragment>
            <Form.Group>
                <Text.Label>DPE/Audit énergétique</Text.Label>
                <Field
                    type='select'
                    fluid
                    name='dpeOrAuditType'
                    options={[
                        { label: 'DPE', value: 'DPE' },
                        { label: 'Audit énergétique ', value: 'AUDIT_ENERGETIC' },
                    ]}
                    error={formErrors.dpeOrAuditType}
                    onChange={onChange}
                    lightBackground={true}
                />
            </Form.Group>
        </Fragment>
    );
};
