import {
    GET_DOSSIERS_CEE_REQUEST,
    GET_DOSSIERS_CEE_SUCCESS,
    GET_DOSSIERS_CEE_FAILURE,
    GET_DOSSIER_CEE_REQUEST,
    GET_DOSSIER_CEE_SUCCESS,
    GET_DOSSIER_CEE_FAILURE,
    UPDATE_DOSSIER_CEE_REQUEST,
    UPDATE_DOSSIER_CEE_SUCCESS,
    UPDATE_DOSSIER_CEE_FAILURE,
    DOWNLOAD_DOSSIER_CEE_REQUEST,
    DOWNLOAD_DOSSIER_CEE_SUCCESS,
    DOWNLOAD_DOSSIER_CEE_FAILURE,
    SEND_DOSSIER_CEE_REQUEST,
    SEND_DOSSIER_CEE_SUCCESS,
    SEND_DOSSIER_CEE_FAILURE,
    GET_DOSSIERS_CEE_ANALYTICS_REQUEST,
    GET_DOSSIERS_CEE_ANALYTICS_SUCCESS,
    GET_DOSSIERS_CEE_ANALYTICS_FAILURE,
    REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_REQUEST,
    REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_SUCCESS,
    REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_FAILURE,
    UPDATE_DOSSIER_CEE_LOCALLY,
    SAVE_ADVANCE_PAYMENT_AGREEMENT_RESPONSE_FAILURE,
    SAVE_ADVANCE_PAYMENT_AGREEMENT_RESPONSE_REQUEST,
    SAVE_ADVANCE_PAYMENT_AGREEMENT_RESPONSE_SUCCESS,
} from '../types/dossier-cee.types';
import dossierCEEService from '../services/dossier-cee.service';
import { handleErrorResponse, parseError } from '../../api';
import formActions from './ui/form';
import dialogsActions from './ui/dialogs';
import zipUtils from '../../utils/zip-utils';
import dossierCEEHelper from '../../utils/dossier-cee-helper';
import { getPropertyFromObject, isValidValue } from '../../utils';
import { DOSSIER_CEE_TAX_NOTICES_REGENERATED, YOUR_CHOICE_HAS_BEEN_RECEIVED } from '../../utils/messages';
import accountHelper from '../../utils/account-helper';

/**
 * Get dossiers cee action
 * @param {Object} options
 * @param {Boolean} options.filterDossiersCEEWithoutPremium
 * @param {Boolean} options.filterDossiersCEEWithCompletedQuotation
 */
function getDossiersCEE(options = {}) {
    return (dispatch) => {
        if (!options.searchString) dispatch(formActions.startRequesting());
        dispatch({ type: GET_DOSSIERS_CEE_REQUEST });
        dossierCEEService
            .getDossiersCEE(options)
            .then(({ data }) => {
                dispatch({ type: GET_DOSSIERS_CEE_SUCCESS, payload: { dossiersCEE: data } });
                dispatch(formActions.stopRequesting());
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_DOSSIERS_CEE_FAILURE, payload: { errorMessage } });
                dispatch(formActions.stopRequesting());
            });
    };
}

function getDossierCEE({ dossierCEEUUID }) {
    return (dispatch, getState) => {
        const { account } = getState().auth;

        dispatch({ type: GET_DOSSIER_CEE_REQUEST });

        const getDossierCEEFunction = accountHelper.isRenolibControllerRole(account) ? dossierCEEService.getDossierCEEForAdministrator : dossierCEEService.getDossierCEE;

        getDossierCEEFunction(dossierCEEUUID)
            .then(({ data }) => {
                dispatch({ type: GET_DOSSIER_CEE_SUCCESS, payload: { dossierCEE: data } });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_DOSSIER_CEE_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage, redirect: '/dossiers-financement' }));
            });
    };
}

function updateDossierCEE({ dossierCEEUUID, updates }) {
    return (dispatch) => {
        dispatch({ type: UPDATE_DOSSIER_CEE_REQUEST });
        dispatch(formActions.startRequesting());

        dossierCEEService
            .updateDossierCEE(dossierCEEUUID, updates)
            .then(({ data }) => {
                dispatch({ type: UPDATE_DOSSIER_CEE_SUCCESS, payload: { dossierCEE: data } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre dossier cee a bien été mis à jour' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: UPDATE_DOSSIER_CEE_FAILURE, payload: { errorMessage } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function downloadDossierCEE({ dossierCEEUUID }, dossierCEE) {
    return (dispatch) => {
        dispatch({ type: DOWNLOAD_DOSSIER_CEE_REQUEST });
        dispatch(formActions.startRequesting());

        dossierCEEService
            .downloadDossierCEE(dossierCEEUUID)
            .then(({ data }) => {
                dispatch({ type: DOWNLOAD_DOSSIER_CEE_SUCCESS, payload: { dossierCEEData: data } });
                dispatch(formActions.stopRequesting());

                zipUtils.saveZipFile(data, dossierCEEHelper.getDossierCEEZipFilename(dossierCEE));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: DOWNLOAD_DOSSIER_CEE_FAILURE, payload: { errorMessage } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function sendDossierCEE({ dossierCEEUUID, sendingData = {} } = {}) {
    return (dispatch) => {
        dispatch({ type: SEND_DOSSIER_CEE_REQUEST });
        dispatch(formActions.startRequesting());

        dossierCEEService
            .sendDossierCEE(dossierCEEUUID, sendingData)
            .then(({ data: dossierCEE }) => {
                dispatch({ type: SEND_DOSSIER_CEE_SUCCESS, payload: { dossierCEE } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: dossierCEEHelper.getSendDossierCEESuccessMessage(dossierCEE, sendingData) }));
                dispatch(getDossiersCEEAnalytics());
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: SEND_DOSSIER_CEE_FAILURE, payload: { errorMessage } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function getDossiersCEEAnalytics() {
    return (dispatch) => {
        dispatch({ type: GET_DOSSIERS_CEE_ANALYTICS_REQUEST });

        dossierCEEService
            .getDossiersCEEAnalytics()
            .then(({ data }) => {
                dispatch({ type: GET_DOSSIERS_CEE_ANALYTICS_SUCCESS, payload: { analytics: data } });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_DOSSIERS_CEE_ANALYTICS_FAILURE, payload: { errorMessage } });
            });
    };
}

function refreshDossierCEERenovationAddress() {
    return (dispatch, geState) => {
        const { dossierCEE } = geState().dossiersCEEState;
        dispatch({ type: REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_REQUEST });
        dispatch(formActions.startRequesting());

        dossierCEEService
            .refreshDossierCEERenovationAddress(dossierCEE.uuid)
            .then(({ data }) => {
                dispatch({ type: REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_SUCCESS, payload: { dossierCEE: data } });
                dispatch(formActions.stopRequesting());
                handleRefreshDossierCEERenovationAddressResponse(dispatch, data);
            })
            .catch(handleErrorResponse({ dispatch, errorType: REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_FAILURE }));
    };
}

function handleRefreshDossierCEERenovationAddressResponse(dispatch, dossierCEE) {
    const housings = getPropertyFromObject(dossierCEE, 'quotation.address.householdIncomeBonification.housings', []);
    const occupantsWithTaxNoticeFileCount = housings.reduce((accumulator, { occupants = [] }) => {
        return accumulator + occupants.filter(({ taxNoticeFileDownloadUrl }) => isValidValue(taxNoticeFileDownloadUrl)).length;
    }, 0);
    if (occupantsWithTaxNoticeFileCount > 0) {
        dispatch(dialogsActions.showSuccessDialog({ message: DOSSIER_CEE_TAX_NOTICES_REGENERATED }));
    } else dispatch(dialogsActions.showFailureDialog({ message: "Une erreur est survenue et nous n'avons malheureusement toujours pas pu générer les avis d'imposition." }));
}

function updateDossierCEELocally({ dossierCEEUUID, updates }) {
    return {
        type: UPDATE_DOSSIER_CEE_LOCALLY,
        payload: { dossierCEEUUID, updates },
    };
}

function saveAdvancePaymentAgreementResponse({ dossierCEEUUID, energySavingCertificatePremiumAdvancePaymentAgreed }) {
    return (dispatch) => {
        dispatch({ type: SAVE_ADVANCE_PAYMENT_AGREEMENT_RESPONSE_REQUEST, payload: { dossierCEEUUID, energySavingCertificatePremiumAdvancePaymentAgreed } });
        dispatch(formActions.startRequesting());

        dossierCEEService
            .saveAdvancePaymentAgreementResponse(dossierCEEUUID, { energySavingCertificatePremiumAdvancePaymentAgreed })
            .then(({ data: dossierCEE }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: SAVE_ADVANCE_PAYMENT_AGREEMENT_RESPONSE_SUCCESS, payload: { dossierCEE } });
                dispatch(dialogsActions.showSuccessDialog({ message: YOUR_CHOICE_HAS_BEEN_RECEIVED }));
            })
            .catch(handleErrorResponse({ dispatch, errorType: SAVE_ADVANCE_PAYMENT_AGREEMENT_RESPONSE_FAILURE }));
    };
}

function updateDossierCEETaxImposition(data, dossierCEE, onSuccess) {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('data', JSON.stringify({ occupantUUID: data.occupant }));

        dispatch({ type: REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_REQUEST });
        dispatch(formActions.startRequesting());

        dossierCEEService
            .updateDossierCEETaxImposition(dossierCEE.uuid, formData, dossierCEE?.organization)
            .then(({ data }) => {
                dispatch({ type: REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_SUCCESS, payload: { dossierCEE: data } });
                dispatch(formActions.stopRequesting());
                handleRefreshDossierCEERenovationAddressResponse(dispatch, data);
                if (onSuccess) onSuccess();
            })
            .catch(handleErrorResponse({ dispatch, errorType: REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_FAILURE }));
    };
}

const updateDossierCeeForDocumentRegeneration = (type) => (primeUUID, data, onSuccess) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_DOSSIER_CEE_REQUEST });
        dispatch(formActions.startRequesting());

        return dossierCEEService
            .updateDossierCeeForDocumentRegeneration(primeUUID, { ...data, type })
            .then(({ data }) => {
                dispatch({ type: UPDATE_DOSSIER_CEE_SUCCESS, payload: { dossierCEE: data } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre dossier cee a bien été mis à jour' }));
                onSuccess(data)
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: UPDATE_DOSSIER_CEE_FAILURE, payload: { errorMessage } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
};
export default {
    getDossiersCEE,
    getDossierCEE,
    updateDossierCEE,
    downloadDossierCEE,
    sendDossierCEE,
    getDossiersCEEAnalytics,
    refreshDossierCEERenovationAddress,
    updateDossierCEELocally,
    saveAdvancePaymentAgreementResponse,
    updateDossierCEETaxImposition,
    updateDossierCeeForDocumentRegeneration,
};
