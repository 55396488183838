export default {
    radius: '5px',
    modal: {
        width: {
            small: '25%',
            normal: '40%',
            large: '60%',
        },
    },
    navbar: {
        height: '70px',
    },
    container: {
        header: {
            height: '2.5rem',
        },
    },
    section: {
        main: 'calc(100vh - 290px)',
    },
    scrollbar: {
        width: '0.4rem',
        radius: '10px',
    },
    onboardingBar: {
        height: '70px',
    },
    dialog: {
        errorIcon: {
            height: '4rem',
        },
    },
    badge: {
        minHeight: '1.5rem',
    },
};
