import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { getDocumentDownloadLink, getDocumentTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';

export const formatCadreContributionAsDocument = (dossierCEE) => {
    const {
        quotation: { cadreContribution },
    } = dossierCEE;

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.CADRE_CONTRIBUTION),
        documentType: dossierCEEDocumentTypes.CADRE_CONTRIBUTION,
        available: isDocumentDownloadLinkAvailable(cadreContribution),
        fileDownloadUrl: getDocumentDownloadLink(cadreContribution),
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        position: 2,
    };
};
