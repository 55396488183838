import React, { createContext, useState } from 'react';

import { sidebarStates } from '../../utils/enums';

const LayoutContext = createContext(null);

export { LayoutContext };

export default ({ children }) => {
    const [sidebarState, setSidebarState] = useState(sidebarStates.DEFAULT);
    const [togglerVisible, setTogglerVisible] = useState(false);
    const [togglerHovered, setTogglerHovered] = useState(false);
    const [sidebarHovered, setSidebarHovered] = useState(false);

    const toggleSidebar = () => {
        if ([sidebarStates.DEFAULT, sidebarStates.EXPANDED].includes(sidebarState)) {
            setSidebarState(sidebarStates.SHRINKED);
        } else setSidebarState(sidebarStates.EXPANDED);
    };

    const togglePhoneSidebar = () => {
        if ([sidebarStates.DEFAULT, sidebarStates.SHRINKED].includes(sidebarState)) {
            setSidebarState(sidebarStates.EXPANDED);
        } else setSidebarState(sidebarStates.SHRINKED);
    };

    const enterToggler = () => {
        setTogglerVisible(true);
        setTogglerHovered(true);
    };

    const exitToggler = () => {
        setTogglerHovered(false);
        if (sidebarState !== sidebarStates.SHRINKED && !sidebarHovered) {
            setTogglerVisible(false);
        }
    };

    const enterSidebar = () => {
        setTogglerVisible(true);
        setSidebarHovered(true);
    };

    const exitSidebar = () => {
        setSidebarHovered(false);
        if (sidebarState !== sidebarStates.SHRINKED && !togglerHovered) {
            setTogglerVisible(false);
        }
    };

    return (
        <LayoutContext.Provider
            value={{
                sidebarState,
                toggleSidebar,
                togglerVisible,
                enterToggler,
                exitToggler,
                enterSidebar,
                exitSidebar,
                togglePhoneSidebar,
            }}>
            {children}
        </LayoutContext.Provider>
    );
};
