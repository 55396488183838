import { keyframes } from 'styled-components';

export const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const pulse = keyframes`
    0% {
        transform: scale(0.8);
        opacity: 0.5;
    }

    50% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(0.8);
        opacity: 0.5
    }
`;
