import { LABEL_ACTIVITY_AREA, LABEL_APARTMENT_NUMBER, LABEL_INPUT_ADDRESS_NAME, LABEL_INPUT_ADDRESS_PHONE, LABEL_SELECT_BUILDING_SECTOR, LABEL_SELECT_HOUSING_TYPE } from '../form_labels';
import renovationAddressFormHelper from '../renovation-address-form-helper';
import renovationAddressHelper from '../renovation-address-helper';
import { addressFieldsDescriptorGenerator } from './common_descriptor_generator';

export default [
    ...addressFieldsDescriptorGenerator(),
    {
        property: 'addressName',
        label: LABEL_INPUT_ADDRESS_NAME,
        type: 'string',
        requiredIf: (data) => !renovationAddressFormHelper.isResidentialArea(data),
    },
    {
        property: 'phone',
        label: LABEL_INPUT_ADDRESS_PHONE,
        type: 'string',
        dataType: 'phoneNumber',
    },
    {
        property: 'buildingSector',
        label: LABEL_SELECT_BUILDING_SECTOR,
        type: 'string',
        required: true,
    },
    {
        property: 'housingType',
        label: LABEL_SELECT_HOUSING_TYPE,
        type: 'string',
        requiredIf: (data) => renovationAddressFormHelper.canDisplayHousingTypeSelect(data),
    },
    {
        property: 'activityArea',
        label: LABEL_ACTIVITY_AREA,
        type: 'string',
        requiredIf: (data) => renovationAddressFormHelper.canDisplayActivityAreaSelect(data),
    },
    {
        property: 'apartmentNumber',
        label: LABEL_APARTMENT_NUMBER,
        type: 'string',
        requiredIf: renovationAddressHelper.isApartment,
    },
];
