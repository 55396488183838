import { LABEL_MANUFACTURER_WARRANTY } from '../../product-constants';

export default [
    {
        property: LABEL_MANUFACTURER_WARRANTY,
        label: LABEL_MANUFACTURER_WARRANTY,
        type: 'number',
        required: true,
    },
];
