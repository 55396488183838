import { GENERATE_SIGNING_URL_SUCCESS } from '../types/signature.types';

function initState() {
    return { response: {} };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_SIGNING_URL_SUCCESS:
            return {
                response: payload,
            };

        default:
            return state;
    }
}
