import {
    LABEL_INSULATING_COVER_IS_FLEXIBLE,
    LABEL_INSULATING_COVER_IS_MADE_OF_MINERAL_WOOL_BASED_INSULATOR,
    LABEL_INSULATING_COVER_IS_NOT_AN_INSULATING_SLEEVE,
    LABEL_HEAT_TRANSFER_FLUID_TEMPERATURE,
    LABEL_MAXIMUM_SERVICE_TEMPERATURE,
    LABEL_FIFTY_DEGREES_THERMAL_RESISTANCE,
    LABEL_ONE_HUNDRED_DEGREES_THERMAL_RESISTANCE,
} from '../../product-constants';

export default [
    {
        property: LABEL_INSULATING_COVER_IS_FLEXIBLE,
        label: LABEL_INSULATING_COVER_IS_FLEXIBLE,
        type: 'boolean',
        required: true,
    },
    {
        property: LABEL_INSULATING_COVER_IS_MADE_OF_MINERAL_WOOL_BASED_INSULATOR,
        label: LABEL_INSULATING_COVER_IS_MADE_OF_MINERAL_WOOL_BASED_INSULATOR,
        type: 'boolean',
        required: true,
    },
    {
        property: LABEL_INSULATING_COVER_IS_NOT_AN_INSULATING_SLEEVE,
        label: LABEL_INSULATING_COVER_IS_NOT_AN_INSULATING_SLEEVE,
        type: 'boolean',
        required: true,
    },
    {
        property: LABEL_HEAT_TRANSFER_FLUID_TEMPERATURE,
        label: LABEL_HEAT_TRANSFER_FLUID_TEMPERATURE,
        type: 'number',
        required: true,
    },
    {
        property: LABEL_MAXIMUM_SERVICE_TEMPERATURE,
        label: LABEL_MAXIMUM_SERVICE_TEMPERATURE,
        type: 'number',
        required: true,
    },
    {
        property: LABEL_FIFTY_DEGREES_THERMAL_RESISTANCE,
        label: LABEL_FIFTY_DEGREES_THERMAL_RESISTANCE,
        type: 'number',
        required: true,
    },
    {
        property: LABEL_ONE_HUNDRED_DEGREES_THERMAL_RESISTANCE,
        label: LABEL_ONE_HUNDRED_DEGREES_THERMAL_RESISTANCE,
        type: 'number',
        required: true,
    },
];
