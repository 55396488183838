import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { getOperationOfRenovationGlobalIndex } from '../../renovation-global-helper';
import { getDocumentTitle, getDownloadUrl } from '../utils/utils';

export const formatRenovationGlobalTechnicians = (dossierCEE) => {
    const { invoice } = dossierCEE;

    if (!invoice) {
        return {
            uuid: uuidV1(),
            title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_TECHNICIANS),
            subTitle: '',
            available: false,
            defaultDocument: invoice,
            fileDownloadUrl: undefined,
            documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_TECHNICIANS,
            fileType: dossierCEESidebarDocumentFileTypes.PDF,
            message: 'À signer par le client',
            position: 9,
        };
    }

    const renovationGlobalIndex = getOperationOfRenovationGlobalIndex(invoice);

    const { documents } = invoice.operations[renovationGlobalIndex].renovationGlobal;
    const documentUrl = getDownloadUrl(documents.companiesList);

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_TECHNICIANS),
        subTitle: '',
        available: !!documentUrl,
        valid: !!documentUrl,
        fileDownloadUrl: documentUrl,
        documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_TECHNICIANS,
        defaultDocument: invoice,
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: !documentUrl ? 'À ajouter après finalisation de la facture' : '',
        position: 9,
    };
};
