import { HYBRID_SOLAR_CAPTORS, LABEL_NATURE_FLUID_CAPTORS } from '../../product-constants';
import { LABEL_INPUT_TANK_CAPACITY_IN_LITER } from '../../form_labels';

export default [
    {
        property: HYBRID_SOLAR_CAPTORS,
        label: HYBRID_SOLAR_CAPTORS,
        type: 'radio',
        required: true,
    },
    {
        property: LABEL_NATURE_FLUID_CAPTORS,
        label: LABEL_NATURE_FLUID_CAPTORS,
        type: 'select',
        required: true,
    },
    {
        property: LABEL_INPUT_TANK_CAPACITY_IN_LITER,
        label: LABEL_INPUT_TANK_CAPACITY_IN_LITER,
        type: 'string',
        required: true,
    },
];
