import entitiesUtils from './entities-utils';
import { commercialDiscountType } from './enums';

export const roundNumberToTwoDecimal = (number) => Math.round((Number(number) + Number.EPSILON) * 100) / 100;

export const convertPercentageToAmount = (value, advancePayment) => {
    if (commercialDiscountType.VALUE === advancePayment.type) {
        return advancePayment.amount;
    }

    return value * (advancePayment.amount / 100);
};

export const convertPercentageToEuro = (value = 0, advancePayment = {}, options = {}) => entitiesUtils.formatPrice(convertPercentageToAmount(value, advancePayment), options);

export const extractPartsToDisplay = (number = 0) => {
    if (!number) return { integerPart: '0', decimalPart: '00' };
    const numberStr = number.toFixed(2);
    const parts = numberStr.split('.');

    const decimalPart = parts[1].replace(/0+$/, '');

    return {
        integerPart: parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
        decimalPart: decimalPart ? decimalPart : '00',
    };
};
