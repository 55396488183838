export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const SEARCH_PRODUCTS_REQUEST = 'SEARCH_PRODUCTS_REQUEST';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAILURE = 'SEARCH_PRODUCTS_FAILURE';

export const RESET_PRODUCT_FINDER = 'RESET_PRODUCT_FINDER';

export const GET_OR_CREATE_FUEL_TANK_REMOVAL_PRODUCT_REQUEST = 'GET_OR_CREATE_FUEL_TANK_REMOVAL_PRODUCT_REQUEST';
export const GET_OR_CREATE_FUEL_TANK_REMOVAL_PRODUCT_SUCCESS = 'GET_OR_CREATE_FUEL_TANK_REMOVAL_PRODUCT_SUCCESS';
export const GET_OR_CREATE_FUEL_TANK_REMOVAL_PRODUCT_FAILURE = 'GET_OR_CREATE_FUEL_TANK_REMOVAL_PRODUCT_FAILURE';
