import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { MANDATORY_DOSSIER_CEE_DOCUMENT } from '../../messages';
import { getSitePhotosRenovationFileDownloadUrls, isSitePhotoAfterRenovationDocument, isSitePhotoMandatoryDocument } from '../utils/utils';

export const formatSitePhotosAfterRenovationAsDocument = (dossierCEE) => {
    const { uploadDocuments = [], mandatoryDocuments = [] } = dossierCEE;
    const sitePhotoAfterRenovationDocuments = uploadDocuments.filter(isSitePhotoAfterRenovationDocument);
    const sitePhotoAfterRenovationFileDownloadUrls = getSitePhotosRenovationFileDownloadUrls(sitePhotoAfterRenovationDocuments);

    const formattedSitePhotoAfterRenovation = {
        uuid: uuidV1(),
        title: `Photos du site après travaux`.concat(sitePhotoAfterRenovationFileDownloadUrls.length > 0 ? ` (${sitePhotoAfterRenovationFileDownloadUrls.length})` : ''),
        fileDownloadUrls: sitePhotoAfterRenovationFileDownloadUrls,
        fileType: dossierCEESidebarDocumentFileTypes.IMAGES,
        message: MANDATORY_DOSSIER_CEE_DOCUMENT,
        position: 10,
        available: true,
        valid: true,
        documentType: dossierCEEDocumentTypes.SITE_PHOTO_AFTER_RENOVATION,
    };

    if (isSitePhotoMandatoryDocument(mandatoryDocuments)) {
        if (sitePhotoAfterRenovationFileDownloadUrls.length === 0) {
            formattedSitePhotoAfterRenovation.available = false;
            formattedSitePhotoAfterRenovation.valid = false;
        }

        return formattedSitePhotoAfterRenovation;
    }

    return sitePhotoAfterRenovationFileDownloadUrls.length > 0 && formattedSitePhotoAfterRenovation;
};
