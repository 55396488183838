import {
    UPDATE_QUOTATION,
    UPDATE_OPERATION,
    DELETE_OPERATION,
    UPDATE_MAIN_PRODUCT,
    UPDATE_QUOTATION_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT,
    UPDATE_OTHER_PRODUCT,
    DELETE_OTHER_PRODUCT,
    DELETE_MAIN_PRODUCT,
    UPDATE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_TECHNICIAN,
    UPDATE_MAIN_PRODUCT_VARIATION,
    DELETE_MAIN_PRODUCT_VARIATION,
    UPDATE_WASTE_MANAGEMENT,
    UPDATE_QUOTATION_OPERATIONS,
    UPDATE_RENO_GLOBAL_MAIN_PRODUCT,
    TRIGGER_SAVE_AS_DRAFT,
    UPDATE_OPERATION_RENOVATION_GLOBAL,
    UPDATE_OPERATION_OPERATING_TYPE,
    UPDATE_MAR,
    UPDATE_QUOTATION_TYPE,
} from '../types/quotation.types';
import quotationFormHelper from '../../utils/quotation-form-helper';
import quotationActions from '../actions/quotation.actions';
import middlewareHelper from './middleware-helper';

export default ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        const nextAction = next(action);
        if (canTriggerSaveAsDraft(action)) {
            const { quotation } = getState().quotationsState;
            if (canSaveQuotationAsDraft(quotation, action.type)) {
                dispatch(quotationActions.saveQuotationAsDraft(quotationFormHelper.formatQuotationForSaving(quotation)));
            }
        }

        return nextAction;
    };

function canTriggerSaveAsDraft(action) {
    return [
        UPDATE_QUOTATION,
        UPDATE_QUOTATION_OPERATIONS,
        UPDATE_OPERATION,
        DELETE_OPERATION,
        UPDATE_MAIN_PRODUCT,
        UPDATE_RENO_GLOBAL_MAIN_PRODUCT,
        UPDATE_WASTE_MANAGEMENT,
        UPDATE_MAR,
        UPDATE_OTHER_PRODUCT,
        DELETE_OTHER_PRODUCT,
        DELETE_MAIN_PRODUCT,
        UPDATE_OPERATION_INSTALLATION_DATE,
        UPDATE_OPERATION_TECHNICIAN,
        UPDATE_MAIN_PRODUCT_VARIATION,
        DELETE_MAIN_PRODUCT_VARIATION,
        TRIGGER_SAVE_AS_DRAFT,
        UPDATE_OPERATION_RENOVATION_GLOBAL,
        UPDATE_QUOTATION_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT,
        UPDATE_OPERATION_OPERATING_TYPE,
        UPDATE_QUOTATION_TYPE,
    ].includes(action.type);
}

function canSaveQuotationAsDraft({ operations = [] }, type) {
    const strategies = {
        [UPDATE_OPERATION]: middlewareHelper.areAllOperationsAndProductsValid,
        [DELETE_OPERATION]: middlewareHelper.areAllOperationsAndProductsValid,
        [UPDATE_MAIN_PRODUCT]: middlewareHelper.areAllOperationsAndProductsValid,
        [UPDATE_OTHER_PRODUCT]: middlewareHelper.areAllOperationsAndProductsValid,
        [DELETE_OTHER_PRODUCT]: middlewareHelper.areAllOperationsAndProductsValid,
        [DELETE_MAIN_PRODUCT]: middlewareHelper.areAllOperationsAndProductsValid,
        [UPDATE_MAIN_PRODUCT_VARIATION]: middlewareHelper.areAllOperationsAndProductsValid,
        [DELETE_MAIN_PRODUCT_VARIATION]: middlewareHelper.areAllOperationsAndProductsValid,
        [UPDATE_OPERATION_INSTALLATION_DATE]: areAllOperationsInstallationDatesValid,
        [TRIGGER_SAVE_AS_DRAFT]: middlewareHelper.areAllOperationsAndProductsValid,
        [UPDATE_QUOTATION_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT]: middlewareHelper.areAllOperationsAndProductsValid,
        [UPDATE_OPERATION_RENOVATION_GLOBAL]: () => true,
        [UPDATE_OPERATION_OPERATING_TYPE]: () => true,
        [UPDATE_QUOTATION_TYPE]: () => true,
    };
    const selectedStrategy = strategies[type];

    return selectedStrategy ? selectedStrategy({ operations }) : true;
}

function areAllOperationsInstallationDatesValid({ operations }) {
    return operations.every(({ installationDate }) => installationDate.blockValidity.valid);
}
