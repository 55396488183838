export const removeNullInString = (str) => str.replace(/null/gi, '');

export const toNonNullString = (value) => value || '';

export const normalizeString = (str) => {
    if (!str) return;
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
};
