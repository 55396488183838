import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@renolib/renolib-ui-kit';
import styled from 'styled-components';
import 'leaflet';
import 'leaflet-draw';
import { getInseeCode } from '../../../../store/services/cadastre.service';
import { useCreateCadastralMap } from '../../../hooks/leaflet/useCreateCadastralMap';
import { normalizeString } from '../../../../utils/string_utils';

const StyledContainer = styled.div`
    .map-cadastralNumber {
        position: absolute;
        padding: 4px;
        color: #1d3495;
        white-space: nowrap;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        border: none;
        font-weight: bold;
    }
`;

export const DrawCadastralDocument = ({ address, city, postalCode, onSave, onGetAddressError, latitude, longitude }) => {
    const [selectedParcel, setSelectedParcel] = useState(null);
    const [initData, setInitData] = useState({ city, postalCode, code_insee: null, coordinates: null });

    const getAddressLatitudeAndLongitude = useCallback(() => {
        return { longitude: longitude, latitude: latitude, postalCode: postalCode, city: city };
    }, [longitude, latitude, city, postalCode]);

    const getLocationDataFromAddress = useCallback(
        async (address) => {
            const { longitude, latitude, postalCode, city } = await getAddressLatitudeAndLongitude(address);
            const { data } = await getInseeCode({ city, postalCode });

            const inseeFacets = data?.records?.find(({ fields }) => normalizeString(fields?.nom_comm) === normalizeString(city));

            if (!inseeFacets) {
                onGetAddressError("L'addresse renseigner n'est pas suffisament précise. Veuillez utiliser la recherche sur la carte");
            }

            const codeInsee = inseeFacets ? inseeFacets.fields.insee_com : '';

            const coordinates = { longitude, latitude };
            setInitData((initData) => ({ ...initData, postalCode, city, code_insee: codeInsee, coordinates }));
        },
        [onGetAddressError, getAddressLatitudeAndLongitude]
    );

    const onUpdateLocation = useCallback(
        (rawLocation) => {
            return getLocationDataFromAddress(rawLocation.display_name);
        },
        [getLocationDataFromAddress]
    );

    const { onTakeScreenShot } = useCreateCadastralMap({ codeInsee: initData.code_insee, coordinates: initData.coordinates, onSelect: setSelectedParcel, onUpdateLocation });

    useEffect(() => {
        getLocationDataFromAddress(address);
    }, [getLocationDataFromAddress, address]);

    const onRegister = useCallback(async () => {
        try {
            const screenShot = await onTakeScreenShot();
            onSave({ coordinates: initData.coordinates, blob: screenShot, parcel: selectedParcel });
        } catch (e) {
            console.error('While registering the cadastral plot', { e });
        }
    }, [selectedParcel, initData.coordinates, onTakeScreenShot, onSave]);

    return (
        <StyledContainer>
            <div id={'map'} style={{ height: '80vh', zIndex: 5 }} />
            <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
                <Button lightMode='1' onClick={onRegister} disabled={!selectedParcel}>
                    Enregistrer
                </Button>
            </div>
        </StyledContainer>
    );
};
