import { v1 as uuidV1 } from 'uuid';
import { dossierCEESidebarDocumentFileTypes, uploadDocumentTypesTranslations, uploadDocumentTypes } from '../../enums';
import { getDocumentDownloadLink, isDocumentDownloadLinkAvailable } from '../utils/utils';

export const formatCertificateOfCompliancyAsDocument = (dossierCEE) => {
    const { certificateOfCompliancy } = dossierCEE;
    return {
        uuid: uuidV1(),
        title: uploadDocumentTypesTranslations.CERTIFICATE_OF_COMPLIANCY,
        documentType: uploadDocumentTypes.CERTIFICATE_OF_COMPLIANCY,
        available: isDocumentDownloadLinkAvailable(certificateOfCompliancy),
        fileDownloadUrl: getDocumentDownloadLink(certificateOfCompliancy),
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: 'À signer',
        position: 13,
    };
};
