import { endpointV2, authHeaders, getAuthHeadersWithFormDataContentType, getAuthHeadersWithJSONContentType } from '../../api';
import storage from '../../utils/storage';

function getBaseUrl(organization) {
    return `/organizations/${organization?.uuid || storage.getOrganizationUUID()}/upload-documents`;
}

async function createUploadDocument(uploadDocumentData, organization) {
    return endpointV2.post(getBaseUrl(organization), uploadDocumentData, { headers: getAuthHeadersWithFormDataContentType() });
}

async function createParcelCadastralUploadDocument(uploadDocumentData, organization) {
    return endpointV2.post(`${getBaseUrl(organization)}/parcel-cadastral`, uploadDocumentData, { headers: getAuthHeadersWithFormDataContentType() });
}

async function getUploadDocuments(query) {
    return endpointV2.get(getBaseUrl(), {
        headers: authHeaders(),
        params: {
            ...query,
        },
    });
}

async function getUploadDocument(uploadDocumentUUID, organization) {
    return endpointV2.get(`${getBaseUrl(organization)}/${uploadDocumentUUID}`, {
        headers: authHeaders(),
    });
}

async function deleteUploadDocument(uploadDocumentUUID, accountUUID) {
    return endpointV2.delete(`${getBaseUrl()}/${uploadDocumentUUID}`, {
        headers: authHeaders(),
        params: {
            uuidAccount: accountUUID,
        },
    });
}

async function getUploadDocumentsCount(query) {
    return endpointV2.get(`${getBaseUrl()}/count`, {
        headers: authHeaders(),
        params: {
            ...query,
        },
    });
}

async function deleteFileFromUploadDocument({ uploadDocumentUUID, fileUUID }) {
    return endpointV2.delete(`${getBaseUrl()}/${uploadDocumentUUID}/file`, {
        headers: authHeaders(),
        params: { fileUUID },
    });
}

async function getDossierByQuotationFile(formData, organization) {
    return endpointV2.post(`${getBaseUrl(organization)}/get-dossier-by-quotation-file`, formData, { headers: getAuthHeadersWithFormDataContentType() });
}

async function checkIfDocumentAlreadyExists(dossierCEEId, documentType, operationUUID) {
    return endpointV2.get(`${getBaseUrl()}/check-document-exists`, {
        headers: authHeaders(),
        params: {
            dossierCEEId,
            documentType,
            operationUUID,
        },
    });
}

async function updateUploadDocumentCheckReport(organization, { uploadDocumentUUID, checkReport }) {
    return endpointV2.put(`${getBaseUrl(organization)}/${uploadDocumentUUID}/check-report`, { checkReport }, { headers: getAuthHeadersWithJSONContentType() });
}

async function getAnahGrantLetterAmount(formData) {
    return endpointV2.post(`${getBaseUrl()}/anah-grant-letter-amount`, formData, { headers: getAuthHeadersWithFormDataContentType() });
}

export default {
    createUploadDocument,
    getUploadDocuments,
    getUploadDocument,
    deleteUploadDocument,
    getUploadDocumentsCount,
    createParcelCadastralUploadDocument,
    deleteFileFromUploadDocument,
    checkIfDocumentAlreadyExists,
    getDossierByQuotationFile,
    updateUploadDocumentCheckReport,
    getAnahGrantLetterAmount,
};
