import { otherDocumentTypes } from '../../enums';
import { v1 as uuidV1 } from 'uuid';
import { getDocumentTitle } from '../utils/utils';

export const extractProductDocuments = (dossierCEE) => {
    const otherDocuments = dossierCEE.uploadDocuments.filter(({ documentType }) => documentType === otherDocumentTypes.SOLAR_CERTIFICATION);
    return otherDocuments.map(({ documentType, fileType, fileDownloadUrl, uuid }) => ({
        uuid: uuidV1(),
        title: getDocumentTitle(documentType),
        fileDownloadUrl,
        fileType,
        isDeletable: false,
        position: 4,
    }));
};
