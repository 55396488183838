import {
    LABEL_PANEL_POWER,
    NAME_PANEL_POWER,
    LABEL_PANEL_CELLS,
    NAME_PANEL_CELLS,
    LABEL_PANEL_DIMENSION,
    NAME_PANEL_DIMENSION,
    LABEL_PANEL_WEIGHT,
    NAME_PANEL_WEIGHT,
    LABEL_MANUFACTURER_WARRANTY,
    NAME_MANUFACTURER_WARRANTY,
    LABEL_LINEAR_WARRANTY_85PCT,
    NAME_LINEAR_WARRANTY_85PCT,
    LABEL_MAX_DC_VOLTAGE,
    NAME_MAX_DC_VOLTAGE,
    LABEL_YIELD,
    NAME_YIELD,
    LABEL_MATERIAL_WARRANTY,
    NAME_MATERIAL_WARRANTY,
    LABEL_MODEL,
    NAME_MODEL,
} from '../../product-constants';

import { photovoltaicPanelsSubCategories } from '../../enums';

export default [
    // PHOTOVOLTAIC_PANELS_SUPPLY
    {
        label: LABEL_PANEL_POWER,
        property: NAME_PANEL_POWER,
        type: 'number',
        requiredIf(formState) {
            return formState.subCategory === photovoltaicPanelsSubCategories.PHOTOVOLTAIC_PANELS_SUPPLY;
        },
    },
    {
        label: LABEL_PANEL_CELLS,
        property: NAME_PANEL_CELLS,
        type: 'number',
        requiredIf(formState) {
            return formState.subCategory === photovoltaicPanelsSubCategories.PHOTOVOLTAIC_PANELS_SUPPLY;
        },
    },
    {
        label: LABEL_PANEL_DIMENSION,
        property: NAME_PANEL_DIMENSION,
        type: 'string',
        requiredIf(formState) {
            return formState.subCategory === photovoltaicPanelsSubCategories.PHOTOVOLTAIC_PANELS_SUPPLY;
        },
    },
    {
        label: LABEL_PANEL_WEIGHT,
        property: NAME_PANEL_WEIGHT,
        type: 'number',
        requiredIf(formState) {
            return [photovoltaicPanelsSubCategories.MICRO_ONDULATOR, photovoltaicPanelsSubCategories.PHOTOVOLTAIC_PANELS_SUPPLY].includes(formState.subCategory);
        },
    },
    {
        label: LABEL_MANUFACTURER_WARRANTY,
        property: NAME_MANUFACTURER_WARRANTY,
        type: 'number',
        requiredIf(formState) {
            return formState.subCategory === photovoltaicPanelsSubCategories.PHOTOVOLTAIC_PANELS_SUPPLY;
        },
    },
    {
        label: LABEL_LINEAR_WARRANTY_85PCT,
        property: NAME_LINEAR_WARRANTY_85PCT,
        type: 'number',
        requiredIf(formState) {
            return formState.subCategory === photovoltaicPanelsSubCategories.PHOTOVOLTAIC_PANELS_SUPPLY;
        },
    },
    // MICRO_ONDULATOR
    {
        label: LABEL_MAX_DC_VOLTAGE,
        property: NAME_MAX_DC_VOLTAGE,
        type: 'string',
        requiredIf(formState) {
            return [photovoltaicPanelsSubCategories.MICRO_ONDULATOR, photovoltaicPanelsSubCategories.ONDULATOR].includes(formState.subCategory);
        },
    },
    {
        label: LABEL_YIELD,
        property: NAME_YIELD,
        type: 'string',
        requiredIf(formState) {
            return [photovoltaicPanelsSubCategories.MICRO_ONDULATOR, photovoltaicPanelsSubCategories.ONDULATOR].includes(formState.subCategory);
        },
    },
    {
        label: LABEL_MATERIAL_WARRANTY,
        property: NAME_MATERIAL_WARRANTY,
        type: 'string',
        requiredIf(formState) {
            return [photovoltaicPanelsSubCategories.MICRO_ONDULATOR, photovoltaicPanelsSubCategories.ONDULATOR].includes(formState.subCategory);
        },
    },
    // FIXATION_KIT
    {
        label: LABEL_MODEL,
        property: NAME_MODEL,
        type: 'string',
        requiredIf(formState) {
            return formState.subCategory === photovoltaicPanelsSubCategories.FIXATION_KIT;
        },
    },
];
