import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-date-picker';
import { v1 as uuidV1 } from 'uuid';
import { FieldError } from '@renolib/renolib-ui-kit';
import { inputTypes } from '../../utils/enums';
import entitiesUtils from '../../utils/entities-utils';
import Form from '../helpers/Form';
import { isTab } from '../layout/layout.helper';
import { debounce } from 'lodash';

export default function DatePicker({ id = uuidV1(), label, labelShown = true, name, size, defaultValue, inline = false, error, onChange, minDate, maxDate, disabled }) {
    const defaultId = useMemo(() => id, [id]);
    const [value, setValue] = useState(parseToDateObject(defaultValue));

    function handleChange(date) {
        setValue(date);
        const newDate = new Date(new Date(date).setHours(12));
        onChange({ name, value: newDate, type: inputTypes.DATE });
    }

    const handleChangeDebounce = debounce(handleChange, 1000);

    useEffect(() => {
        setValue(parseToDateObject(defaultValue));
    }, [defaultValue]);

    return (
        <Form.Group className={labelShown ? '' : 'no-label'} style={getFormGroupStyle(inline)}>
            {label && (
                <Form.Label htmlFor={defaultId} size={size} style={getFormLabelStyle(inline)}>
                    {entitiesUtils.capitalize(label).concat(inline ? ' : ' : '')}
                </Form.Label>
            )}
            <ReactDatePicker
                className='bg-white'
                id={defaultId}
                name={name}
                onChange={handleChangeDebounce}
                value={value}
                format={'dd/MM/yyyy'}
                clearIcon={<></>}
                onFocus={isTab() ? (e) => e.target.blur() : undefined} // for preventing the keyboard
                calendarIcon={<></>}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
            />
            {error && <FieldError>{error}</FieldError>}
        </Form.Group>
    );
}
DatePicker.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    labelShown: PropTypes.bool,
    name: PropTypes.string,
    size: PropTypes.string,
    defaultValue: PropTypes.any,
    inline: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
};

function parseToDateObject(dateString) {
    return dateString ? new Date(dateString) : null;
}

function getFormGroupStyle(inline) {
    return inline ? { display: inline ? 'flex' : 'block' } : {};
}

function getFormLabelStyle(inline) {
    return inline ? { display: 'flex', whiteSpace: 'no-wrap', minWidth: '115px', alignItems: 'center', marginRight: '0.25rem' } : {};
}
