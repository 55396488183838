import {
    SEARCH_CUSTOMERS_REQUEST,
    SEARCH_CUSTOMERS_SUCCESS,
    SEARCH_CUSTOMERS_FAILURE,
    GET_CUSTOMER_ANALYTICS_SUCCESS,
    GET_CUSTOMER_ANALYTICS_FAILURE,
    GET_CUSTOMERS_ANALYTICS_SUCCESS,
    GET_CUSTOMERS_ANALYTICS_FAILURE,
} from '../types/customer.types';
import { LOGOUT } from '../types/auth';

function initState() {
    return { customers: [], requesting: false, analytics: { customer: {}, customers: {} } };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCH_CUSTOMERS_REQUEST:
            return {
                customers: [],
                requesting: true,
            };

        case SEARCH_CUSTOMERS_SUCCESS:
            return {
                customers: payload.customers,
                requesting: false,
            };

        case SEARCH_CUSTOMERS_FAILURE:
            return {
                ...state,
                requesting: false,
            };
        case GET_CUSTOMER_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    customer: payload,
                },
            };
        case GET_CUSTOMER_ANALYTICS_FAILURE:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    customer: { totalOperationsRealized: 0, totalOperationsVolume: 0 },
                },
            };
        case GET_CUSTOMERS_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    customers: {
                        ...payload,
                    },
                },
            };
        case GET_CUSTOMERS_ANALYTICS_FAILURE:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    customers: {
                        countOfCompletedAndSentDossiersCEE: 0,
                    },
                },
            };

        case LOGOUT: {
            return initState();
        }

        default:
            return state;
    }
}
