import { getDocumentTitle, isDocumentDeletable, isDocumentShownUsingAnotherMethod } from '../utils/utils';

export const formatOtherUploadedDocumentsAsDocuments = (dossierCEE) => {
    const { uploadDocuments = [] } = dossierCEE;

    return uploadDocuments
        .filter((document) => !isDocumentShownUsingAnotherMethod(dossierCEE, document))
        .map(({ documentType, fileType, fileDownloadUrl, uuid }) => ({
            uuid,
            title: getDocumentTitle(documentType),
            documentType: documentType,
            fileDownloadUrl,
            fileType,
            isDeletable: isDocumentDeletable(dossierCEE),
            position: 9,
        }));
};
