import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@renolib/renolib-ui-kit/dist/index.css';
import { Provider } from 'react-redux';

import './styles/react-date-picker.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet/dist/images/marker-shadow.png';

import './index.css';
import * as serviceWorker from './serviceWorker';
import mousePositionHelper from './utils/mouse-position-helper';
import { initStore } from './store';

import App from './components/App';
import LayoutProvider from './components/providers/Layout';
import ResizeObserver from 'resize-observer-polyfill';

if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}

export const store = initStore();
window.store = store;

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <LayoutProvider>
                <App />
            </LayoutProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (!window.Cypress) {
    mousePositionHelper.handleMouseMove();
}
