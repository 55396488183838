import _ from 'lodash';

const operationSheetNamesLatestVersion = {
    BAR_TH_160: 'BAR_TH_160V_A54_4',
    BAT_TH_146: 'BAT_TH_146V_A54_4',
    BAR_EN_101: 'BAR_EN_101V_A54_5',
    BAR_EN_102: 'BAR_EN_102V_A39_2',
    BAR_EN_103: 'BAR_EN_103V_A39_5',
    BAR_EN_104: 'BAR_EN_104V_A54_2',
    BAR_TH_104: 'BAR_TH_104V_A41_3',
    BAR_TH_129: 'BAR_TH_129V_A27_3',
    BAR_TH_148: 'BAR_TH_148V_A15_2',
    BAR_TH_127: 'BAR_TH_127V_A54_5',
};
export const getOperationSheetNameFromNormalVersionOrCDP = (product, { operationSheetName }) =>
    product && !!product.detailsPerOperation[operationSheetName] ? operationSheetName : operationSheetName + '_CDP';

export const getOperationSheetNameFromNormalVersionOrCDPOrLatestVersionForRenovationGlobal = (product, { operationSheetName }) => {
    if (product && !!product.detailsPerOperation[operationSheetName]) return operationSheetName;
    else if (product && !!product.detailsPerOperation[operationSheetName + '_CDP']) return operationSheetName;

    const [rawOperationSheetName] = operationSheetName.split('V_');
    const lastestVersionName = operationSheetNamesLatestVersion[rawOperationSheetName];
    if (product && !!product.detailsPerOperation[lastestVersionName]) return lastestVersionName;
    else if (product && !!product.detailsPerOperation[lastestVersionName + '_CDP']) return lastestVersionName + '_CDP';
};

export const getDetailsPerOperationInNormalCdpOrLatestSheetForRG = (product, { operationSheetName }) => {
    if (!product) return {};
    const [rawOperationSheetName] = operationSheetName.split('V_');
    const lastestVersionName = operationSheetNamesLatestVersion[rawOperationSheetName];
    return (
        _.get(product, `detailsPerOperation.${operationSheetName}`) ||
        _.get(product, `detailsPerOperation.${operationSheetName}_CDP`, {}) ||
        _.get(product, `detailsPerOperation.${lastestVersionName}`) ||
        _.get(product, `detailsPerOperation.${lastestVersionName}_CDP`, {})
    );
};
