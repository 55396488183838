import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../config/colors';
import { rotate } from '../../config/animations';
import { joinClassNames } from '../../utils';

function UnStyledCircularProgress({ className, radius, strokeWidth, progress, animate }) {
    const normalizedRadius = radius - strokeWidth * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <svg className={joinClassNames(className, getClassName(animate))}>
            <circle
                cx={radius}
                cy={radius}
                r={normalizedRadius}
                fill='transparent'
                strokeWidth={strokeWidth}
                strokeDasharray={circumference + ' ' + circumference}
                strokeDashoffset={strokeDashoffset}
            />
        </svg>
    );
}

function getClassName(animate) {
    return animate ? 'animated' : 'non-animated';
}

const StyledCircularProgress = styled(UnStyledCircularProgress)`
    width: ${getWidth};
    height: ${getHeight};

    &.animated {
        animation: ${rotate} 800ms linear infinite;
    }

    &.non-animated {
        animation: none;
    }

    > circle {
        stroke: ${getCircleStrokeColor};
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
`;

function getWidth({ radius }) {
    return `${radius * 2}px`;
}

function getHeight({ radius }) {
    return `${radius * 2}px`;
}

function getCircleStrokeColor({ strokeColor }) {
    return colors[strokeColor];
}

export default function CircularProgress({ radius = 50, strokeWidth = 5, strokeColor = 'white', progress = 50, animate = false, ...restProps }) {
    return <StyledCircularProgress radius={radius} strokeWidth={strokeWidth} strokeColor={strokeColor} progress={progress} animate={animate} {...restProps} />;
}
CircularProgress.propTypes = {
    radius: PropTypes.number,
    strokeWidth: PropTypes.number,
    strokeColor: PropTypes.oneOf(['white', 'primary', 'secondary']),
    progress: PropTypes.number,
    animate: PropTypes.bool,
};
