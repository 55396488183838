export default function ({ min = 0 } = {}) {
    return [
        {
            property: 'quantity',
            label: 'Quantité',
            type: 'number',
            required: true,
            min,
        },
        {
            property: 'unit',
            label: 'Unité',
            type: 'number',
            required: true,
        },
        {
            property: 'price',
            label: 'Prix',
            type: 'number',
            required: true,
            min,
        },
        {
            property: 'vatRate',
            label: 'TVA',
            type: 'number',
            required: true,
        },
    ];
}
