import { useLeafletMap } from './useLeafletMap';
import { useLeafletParcelles } from './useLeafletParcelles';
import { useCallback, useEffect, useRef } from 'react';
import { useLeafletControl } from './useLeafletControl';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

export const useCreateCadastralMap = ({ codeInsee, coordinates, onSelect, onUpdateLocation }) => {
    const { map, isMapInit } = useLeafletMap({ codeInsee, coordinates });
    const { parcelles } = useLeafletParcelles({ codeInsee, coordinates, map });
    const parcellesRef = useRef();

    const { createToolBox, createSearchBar, onTakeScreenShot } = useLeafletControl({ map });

    useEffect(() => {
        parcellesRef.current = parcelles;
    }, [parcelles]);

    const displayCadastrePopup = useCallback(
        (e, editableLayers) => {
            const parcelles = parcellesRef.current;
            const layer = e.layer;

            const center = layer.getBounds().getCenter();
            editableLayers.clearLayers();
            editableLayers.addLayer(layer);

            const parcel = parcelles.find((parcelle) => {
                const point = [center.lng, center.lat];
                return booleanPointInPolygon(point, parcelle);
            });

            if (parcel) {
                map.current.panTo(layer.getBounds().getCenter());
                onSelect(parcel.properties);
            }
        },
        [map, onSelect]
    );

    useEffect(() => {
        if (!isMapInit) return;
        createSearchBar(onUpdateLocation);
        createToolBox({}, displayCadastrePopup);
    }, [createSearchBar, createToolBox, displayCadastrePopup, onUpdateLocation, isMapInit]);

    return {
        onTakeScreenShot: onTakeScreenShot(map),
    };
};
