import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { v1 as uuidV1 } from 'uuid';
import { Icons } from '@renolib/renolib-ui-kit';

import dimensions from '../../config/dimensions';
import colors from '../../config/colors';
import { fadeIn } from '../../config/animations';
import zIndices from '../../config/z-indices';
import { MODAL_TEST_ID } from '../../tests/utils/tests-ids';

import useCreatePortal from '../hooks/useCreatePortal';
import { LABEL_BUTTON_CLOSE_MODAL } from '../../utils/form_labels';
import { UtilitiesCSS } from '../layout/UtilitiesCSS';

const slideIn = keyframes`
    from {
        margin-top: -20rem;
    }
    to {
        margin-top: 5rem;
    }
`;

function UnStyledModal({ className, testId, id, children, onHide, title, headerStyles = {}, titleStyles = {}, footerStyles = {}, subTitle, closable, hideOnOutsideClick, actionView }) {
    const { container } = useCreatePortal();
    const defaultId = useMemo(() => id, [id]);

    function handleClick(evt) {
        if (hideOnOutsideClick && evt.target.id === defaultId) handleHide();
    }

    function handleHide() {
        if (onHide) onHide();
    }

    return ReactDOM.createPortal(
        <UtilitiesCSS>
            <div className={className}>
                <div data-testid={testId} id={defaultId} onClick={handleClick}>
                    <div>
                        <header>
                            {closable && (
                                <section className='icon'>
                                    <img src={Icons.closeIcon} role='button' aria-label={LABEL_BUTTON_CLOSE_MODAL} alt='Close modal' onClick={handleHide} />
                                </section>
                            )}
                            {title && (
                                <section className='title' style={headerStyles}>
                                    <span style={titleStyles}>{title}</span>
                                    {subTitle && <span className='sub-title'>{subTitle}</span>}
                                </section>
                            )}
                        </header>
                        <main>{children}</main>
                        {actionView && <footer style={footerStyles}>{actionView}</footer>}
                    </div>
                </div>
            </div>
        </UtilitiesCSS>,
        container
    );
}

const StyledModal = styled(UnStyledModal)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${getZIndex};
    background: rgba(29, 44, 107, 0.75);
    display: none;
    overflow: hidden;

    > div {
        height: 100%;
        overflow: auto;
        opacity: 0;
        transition: all 2000ms ease;

        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

        ${({ centered }) =>
            centered &&
            css`
                display: flex;
                justify-content: center;
                align-items: center;
            `}

        > div {
            background: ${colors.white};
            border-radius: 5px;
            box-shadow: 0 0 20px 0 rgba(144, 177, 225, 0.38);
            margin: 0 auto;
            margin-top: -20rem;
            margin-bottom: 3rem;
            opacity: 0;
            width: ${getWidth};
            padding: 1rem;
            transition: opacity 700ms ease;

            @media (max-width: 1400px) {
                width: 95%;
            }

            @media (max-width: 992px) {
                width: 70%;
            }

            @media (max-width: 600px) {
                width: 90%;
            }

            > header {
                > section {
                    &.icon {
                        display: flex;
                        justify-content: flex-end;

                        > img {
                            display: inline-block;
                            cursor: pointer;
                            transition: opacity 200ms ease-out;

                            :hover {
                                opacity: 0.6;
                            }
                        }
                    }

                    &.title {
                        text-align: center;
                        font-size: 0.9375rem;
                        color: ${colors.primary};
                        font-weight: 600;
                        padding: 0.5rem 1rem;
                        display: flex;
                        flex-direction: column;

                        > .sub-title {
                            display: inline-block;
                            margin-top: 0.25rem;
                            font-size: 0.9rem;
                            font-weight: 700;
                            text-align: center;
                        }
                    }
                }
            }

            > footer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 1.5rem;
                margin-bottom: 1rem;
            }
        }
    }

    ${({ show }) =>
        show &&
        css`
            display: block;

            > div {
                opacity: 1;

                > div {
                    animation: ${slideIn} 500ms forwards, ${fadeIn} 800ms forwards;
                }
            }
        `}
`;

function getZIndex({ type }) {
    return zIndices[type];
}

function getWidth({ size }) {
    return dimensions.modal.width[size];
}

export default function Modal({
    testId = MODAL_TEST_ID,
    id = uuidV1(),
    type = 'modal',
    size = 'normal',
    show = false,
    closable = true,
    hideOnOutsideClick = false,
    scrollable = true,
    centered = false,
    ...restProps
}) {
    return (
        <StyledModal
            testId={testId}
            id={id}
            type={type}
            size={size}
            show={show}
            closable={closable}
            hideOnOutsideClick={hideOnOutsideClick}
            scrollable={scrollable}
            centered={centered}
            {...restProps}
        />
    );
}
Modal.propTypes = {
    testId: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.oneOf(['modal', 'dialog']),
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    title: PropTypes.string,
    subTitle: PropTypes.string,
    titleStyles: PropTypes.shape({}),
    headerStyles: PropTypes.shape({}),
    footerStyles: PropTypes.shape({}),
    show: PropTypes.bool,
    closable: PropTypes.bool,
    hideOnOutsideClick: PropTypes.bool,
    actionView: PropTypes.node,
    scrollable: PropTypes.bool,
    centered: PropTypes.bool,
    onHide: PropTypes.func,
};

const ModalBody = styled.div`
    padding: ${(props) => props.padding || '1.5rem 1rem 1rem 1rem'};

    > * {
        :last-child {
            margin-bottom: 0;
        }
    }
`;
const ModalGroupTitle = styled.span`
    display: inline-block;
    margin: 1rem 0 0.75rem 0;
    font-weight: 700;
    text-transform: uppercase;
`;
Modal.Body = ModalBody;
Modal.GroupTitle = ModalGroupTitle;
