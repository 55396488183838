export const FISCAL_INFORMATION_FINDER_ROUTE = '/services/fiscal-information';

export const ADDRESS_FINDER_AUTOCOMPLETE_ROUTE = '/services/address-processing/autocomplete';
export const ADDRESS_FINDER_PLACE_DETAILS_ROUTE = '/services/address-processing/place-details';
export const ADDRESS_FINDER_GEO_CODE = '/services/address-processing/geo-code';

export const EXPORTS_ROUTE = '/exports';
export const GENERATE_EXPORT_ROUTE = '/generate';
export const GET_EXPORTS_AVAILABILITIES_ROUTE = '/availabilities';

export const SIGN_IN_ROUTE = '/connexion';

export const GET_OPERATION_SHEETS_ROUTE = '/operation-sheets';

export const SEARCH_INVOICEABLE_DELEGATEES_ROUTE = '/search-invoiceable-delegatees';
export const SEARCH_INVOICEABLE_OPERATIONS_ROUTE = '/search-invoiceable-operations';
export const UPLOAD_BILLING_NOTE_ROUTE = '/billing-note';
export const ADD_DELEGATEE_INVOICE_OPERATIONS_ROUTE = '/add-operations';
export const REMOVE_DELEGATEE_INVOICE_OPERATIONS_ROUTE = '/remove-operations';
export const SAVE_DELEGATEE_INVOICE_AS_DRAFT_ROUTE = '/save-as-draft';
export const FINALIZE_DELEGATEE_INVOICE_ROUTE = '/finalize';
export const RECTIFY_DELEGATEE_INVOICE_ROUTE = '/rectify';
export const CANCEL_DELEGATEE_INVOICE_ROUTE = '/cancel';
export const SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_ROUTE = '/send-for-verification';
export const PAY_DELEGATEE_INVOICE_ROUTE = '/pay';

export const REFRESH_ROUTE = '/refresh';

export const GET_ACTIVE_APPLICATION_CONFIGURATION_ROUTE = '/application/active-configuration';
export const SEND_CUSTOMER_REQUEST_ROUTE = '/send-customer-request';

export const DOSSIERS_ADMINISTRATOR_ROUTE = '/admin/dossiers';
export const DOSSIERS_CEE_ADMINISTRATOR_ROUTE = '/admin/dossiers-cee';
export const DOCUMENT_CHECK_CONFIGURATIONS_ADMINISTRATOR_ROUTE = '/admin/document-check-configurations';

export const CREDIT_FINANCING_ROUTE = '/services/credit-financing';

export function QUOTATIONS_ROUTE({ baseUrl }) {
    return `${baseUrl}/quotations`;
}

export function QUOTATION_ROUTE({ baseUrl, quotation }) {
    return QUOTATIONS_ROUTE({ baseUrl }).concat(`/${quotation.uuid}`);
}
