import { GET_ENERGY_RENOVATION_WORKS_SUCCESS } from '../types/energy-renovation-work.types';

function initState() {
    return {
        energyRenovationWorks: [],
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case GET_ENERGY_RENOVATION_WORKS_SUCCESS:
            return {
                ...state,
                energyRenovationWorks: action.payload.energyRenovationWorks,
            };

        default:
            return state;
    }
}
