import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypesOnlyForFrontDistinction } from '../../enums';

export const addOtherDocumentButton = () => {
    return {
        uuid: uuidV1(),
        title: 'Ajouter un autre document',
        isUploadButton: true,
        documentType: dossierCEEDocumentTypesOnlyForFrontDistinction.ADD_OTHER_DOCUMENT_BUTTON,
        subTitle: '',
        available: true,
        valid: true,
        fileDownloadUrl: '',
        fileType: '',
        message: '',
        position: 900,
    };
};
