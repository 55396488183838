import _ from 'lodash';

import {
    CREATE_QUOTATION_SUCCESS,
    GET_QUOTATIONS_REQUEST,
    GET_QUOTATIONS_SUCCESS,
    GET_QUOTATION_REQUEST,
    GET_QUOTATION_SUCCESS,
    PATCH_QUOTATION_SUCCESS,
    ADD_OPERATION,
    UPDATE_OPERATION,
    RESET_OPERATION,
    DELETE_OPERATION,
    ADD_PRODUCT,
    UPDATE_MAIN_PRODUCT,
    UPDATE_OTHER_PRODUCT,
    DELETE_OTHER_PRODUCT,
    DELETE_MAIN_PRODUCT,
    DELETE_ALL_OTHER_PRODUCTS,
    UPDATE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_TECHNICIAN,
    UPDATE_QUOTATION,
    SAVE_QUOTATION_SUCCESS,
    SAVE_QUOTATION_AS_DRAFT_SUCCESS,
    GET_QUOTATIONS_ANALYTICS_SUCCESS,
    SEND_QUOTATION_BY_EMAIL_SUCCESS,
    SEND_QUOTATION_BY_EMAIL_FAILURE,
    RESET_QUOTATION,
    ADD_MAIN_PRODUCT_VARIATION,
    UPDATE_MAIN_PRODUCT_VARIATION,
    DELETE_MAIN_PRODUCT_VARIATION,
    DELETE_QUOTATION_SUCCESS,
    UPDATE_QUOTATION_PRODUCT_SUCCESS,
    CLEAR_QUOTATION,
    REFUSE_QUOTATION_SUCCESS,
    UPDATE_WASTE_MANAGEMENT,
    RESET_QUOTATION_CONTRACT_SUCCESS,
    SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS,
    UPDATE_QUOTATION_OPERATIONS,
    RESTORE_QUOTATION,
    UPDATE_QUOTATION_FINANCING_REMAINING_COSTS,
    UPDATE_RENO_GLOBAL_MAIN_PRODUCT,
    UPDATE_QUOTATION_RENOVATION_GLOBAL_SUCCESS,
    UPDATE_OPERATION_RENOVATION_GLOBAL,
    UPDATE_QUOTATION_RENOVATION_GLOBAL_FAILURE,
    UPDATE_OPERATION_TYPE,
    UPDATE_QUOTATION_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT,
    CREATE_QUOTATION_ADVANCE_PAYMENTS_SUCCESS,
    UPDATE_QUOTATION_ADVANCE_PAYMENT_SUCCESS,
    DELETE_QUOTATION_ADVANCE_PAYMENT_SUCCESS,
    UPDATE_OPERATION_OPERATING_TYPE,
    UPDATE_MAR,
    UPDATE_QUOTATION_TYPE,
    CHECK_QUOTATION_DOCUMENTS_GENERATION_SUCCESS,
    CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_SUCCESS,
} from '../types/quotation.types';
import { financingTypes, productTypes, quotationStatuses } from '../../utils/enums';
import productFormHelper from '../../utils/product-form-helper';
import { isNonEmptyObject, roundNumberToTwoDecimal } from '../../utils';
import entitiesUtils from '../../utils/entities-utils';
import operationFormHelper from '../../utils/operation-form-helper';
import { getOperationOfRenovationGlobalIndex, isOperationOfRenovationGlobal } from '../../utils/renovation-global-helper';

function initState() {
    return {
        quotations: [],
        quotation: {},
        analytics: {
            quotations: { totalAmountOfQuotationsSent: 0, totalAmountOfQuotationsApproved: 0 },
        },
        loading: true,
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case CREATE_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case GET_QUOTATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_QUOTATIONS_SUCCESS:
            return {
                ...state,
                quotations: action.payload.quotations,
                loading: false,
            };

        case GET_QUOTATION_REQUEST:
            return {
                ...state,
                quotation: {},
            };

        case GET_QUOTATION_SUCCESS:
        case CHECK_QUOTATION_DOCUMENTS_GENERATION_SUCCESS:
        case CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case PATCH_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: {
                    ...action.payload.quotation,
                },
            };

        case ADD_OPERATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: [...state.quotation.operations, action.payload.operation],
                    ...resetPricingProperties(),
                },
            };

        case UPDATE_MAR: {
            const operationIndex = getOperationOfRenovationGlobalIndex(state.quotation);
            if (operationIndex === -1) return state;

            const operationsToUpdate = state.quotation.operations;
            let mar = operationsToUpdate[operationIndex].renovationGlobal.mar;

            operationsToUpdate[operationIndex].renovationGlobal.mar = {
                ...mar,
                price: mar.price || 0,
                [`${action.payload.name}`]: action.payload.value,
            };

            mar = operationsToUpdate[operationIndex].renovationGlobal.mar;

            Object.assign(operationsToUpdate[operationIndex].renovationGlobal.mar, { totalPrice: mar.price * mar.quantity });

            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: operationsToUpdate,
                },
            };
        }
        case UPDATE_WASTE_MANAGEMENT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    wasteManagement: {
                        ...action.payload.wasteManagement,
                    },
                },
            };
        case UPDATE_QUOTATION_OPERATIONS: {
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: action.payload.operations,
                },
            };
        }

        case UPDATE_OPERATION_RENOVATION_GLOBAL:
        case UPDATE_OPERATION: {
            const { additionalRelatedOperations = [], shouldUpdateProducts = false } = action.payload;

            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            const products = (shouldUpdateProducts ? action.payload.updates?.products : operation.products) || [];

                            return {
                                ...operation,
                                ...action.payload.updates,
                                products: updateOperationMainProduct({ products }, action.payload.mainProductUpdates),
                            };
                        } else {
                            const matchingAdditionRelatedOperation = extractMatchingAdditionalRelatedOperation(additionalRelatedOperations, operation);
                            if (isNonEmptyObject(matchingAdditionRelatedOperation)) {
                                return {
                                    ...operation,
                                    ...matchingAdditionRelatedOperation.updates,
                                };
                            }
                        }

                        return operation;
                    }),
                },
            };
        }

        case RESET_OPERATION:
            if (isOperationOfRenovationGlobal(action.payload.operation)) {
                return {
                    ...state,
                    quotation: {
                        ...state.quotation,
                        operations: [{ ...action.payload.operation, renovationGlobal: {} }],
                        ...resetPricingProperties({ renovationPremium: true }),
                    },
                };
            }
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) return action.payload.operation;

                        return operation;
                    }),
                    ...resetPricingProperties(),
                },
            };

        case UPDATE_OPERATION_TYPE:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations
                        .map((operation) => {
                            if (operation.uuid === action.payload.operationUUID) {
                                return action.payload.operation;
                            }

                            return operation;
                        })
                        .filter((operation) => operation.uuid === action.payload.operationUUID),
                    ...resetPricingProperties(),
                },
            };

        case DELETE_OPERATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.filter(({ uuid }) => uuid !== action.payload.operationUUID),
                    ...resetPricingProperties(),
                },
            };

        case ADD_PRODUCT:
            if (action.payload.operationOfRenovationGlobalUUID) {
                return {
                    ...state,
                    quotation: {
                        ...state.quotation,
                        operations: state.quotation.operations.map((operation) => {
                            if (operation.uuid === action.payload.operationOfRenovationGlobalUUID) {
                                return {
                                    ...operation,
                                    renovationGlobal: {
                                        ...operation.renovationGlobal,
                                        operations: operation.renovationGlobal.operations.map((operationOfRenovationGlobal) => {
                                            if (operationOfRenovationGlobal.uuid === action.payload.operationUUID) {
                                                const { products = [] } = operationOfRenovationGlobal;
                                                products.push(action.payload.product);

                                                return {
                                                    ...operationOfRenovationGlobal,
                                                    products,
                                                };
                                            }

                                            return operationOfRenovationGlobal;
                                        }),
                                    },
                                };
                            }
                            return operation;
                        }),
                    },
                };
            }
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            const { products = [] } = operation;
                            products.push(action.payload.product);

                            return {
                                ...operation,
                                products,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_MAIN_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (action.payload.operationsUUID.includes(operation.uuid)) {
                            return {
                                ...operation,
                                ...action.payload.operationUpdates,
                                products: updateOperationMainProduct(operation, action.payload.updates, action.payload.productUUID),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_QUOTATION_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operationFormHelper.updateNonEnergyRenovationOperationMainProduct(operation.products, action.payload.updates),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_OTHER_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.uuid === action.payload.productUUID) {
                                        const updatedProduct = {
                                            ...product,
                                            ...action.payload.updates,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedProduct).includes(property))) {
                                            updatedProduct.totalPrice = updatedProduct.quantity * updatedProduct.price;
                                        }

                                        return updatedProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case DELETE_OTHER_PRODUCT: {
            if (action.payload.operationOfRenovationGlobalUUID) {
                return {
                    ...state,
                    quotation: {
                        ...state.quotation,
                        operations: state.quotation.operations.map((operation) => {
                            if (operation.uuid === action.payload.operationOfRenovationGlobalUUID) {
                                return {
                                    ...operation,
                                    renovationGlobal: {
                                        ...operation.renovationGlobal,
                                        operations: operation.renovationGlobal.operations.map((operationOfRenovationGlobal) => {
                                            if (operationOfRenovationGlobal.uuid === action.payload.operationUUID)
                                                return {
                                                    ...operationOfRenovationGlobal,
                                                    products: operationOfRenovationGlobal.products.filter(({ uuid }) => uuid !== action.payload.productUUID),
                                                };
                                            return operationOfRenovationGlobal;
                                        }),
                                    },
                                };
                            }
                            return operation;
                        }),
                    },
                };
            }

            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            const additionalOperationChanges = {};
                            const operationProduct = operation.products.find(({ uuid }) => uuid === action.payload.productUUID);
                            if (operationFormHelper.isRemovalOldBoilerService(operationProduct)) {
                                additionalOperationChanges.removalOldBoiler = false;
                            }

                            return {
                                ...operation,
                                products: operation.products.filter(({ uuid }) => uuid !== action.payload.productUUID),
                                ...additionalOperationChanges,
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case DELETE_MAIN_PRODUCT: {
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.filter(({ uuid }) => uuid !== action.payload.productUUID),
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case DELETE_ALL_OTHER_PRODUCTS: {
            if (action.payload.operationOfRenovationGlobalUUID) {
                return {
                    ...state,
                    quotation: {
                        ...state.quotation,
                        operations: state.quotation.operations.map((operation) => {
                            if (operation.uuid === action.payload.operationOfRenovationGlobalUUID) {
                                return {
                                    ...operation,
                                    renovationGlobal: {
                                        ...operation.renovationGlobal,
                                        operations: operation.renovationGlobal.operations.map((operationOfRenovationGlobal) => {
                                            if (operationOfRenovationGlobal.uuid === action.payload.operationUUID)
                                                return {
                                                    ...operationOfRenovationGlobal,
                                                    products: operationOfRenovationGlobal.products.filter(({ product }) => product.type !== productTypes.OTHER_PRODUCT),
                                                };
                                            return operationOfRenovationGlobal;
                                        }),
                                    },
                                };
                            }
                            return operation;
                        }),
                    },
                };
            }
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.filter(({ product }) => product.type !== productTypes.OTHER_PRODUCT),
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case UPDATE_OPERATION_INSTALLATION_DATE:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                installationDate: action.payload.installationDate,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_OPERATION_TECHNICIAN:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                technician: action.payload.technician,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_QUOTATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    ...action.payload.updates,
                },
            };

        case SAVE_QUOTATION_SUCCESS:
        case SAVE_QUOTATION_AS_DRAFT_SUCCESS:
            return {
                ...state,
                quotation: applyQuotationChangesAfterSave(state, action),
            };

        case GET_QUOTATIONS_ANALYTICS_SUCCESS: {
            return {
                ...state,
                analytics: {
                    quotations: action.payload.analytics,
                },
            };
        }

        case SEND_QUOTATION_BY_EMAIL_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case SEND_QUOTATION_BY_EMAIL_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case ADD_MAIN_PRODUCT_VARIATION:
        case UPDATE_MAIN_PRODUCT_VARIATION:
            return {
                ...state,
                quotation: applyMainProductVariationsChanges(state, action),
            };

        case DELETE_MAIN_PRODUCT_VARIATION:
            return {
                ...state,
                quotation: {
                    ...applyMainProductVariationsChanges(state, action),
                    ...resetPricingProperties(),
                },
            };

        case RESET_QUOTATION:
            return {
                ...state,
                quotation: {},
            };

        case REFUSE_QUOTATION_SUCCESS: {
            return {
                ...state,
                quotations: state.quotations.filter(({ uuid }) => uuid !== action.payload.quotation.uuid),
            };
        }
        case DELETE_QUOTATION_SUCCESS:
            return {
                ...state,
                quotations: state.quotations.filter(({ uuid }) => uuid !== action.payload.quotation.uuid),
            };

        case UPDATE_QUOTATION_PRODUCT_SUCCESS:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        return {
                            ...operation,
                            products: operation.products.map((product) => {
                                if (product.product.uuid === action.payload.product.uuid) {
                                    return {
                                        ...product,
                                        product: action.payload.product,
                                    };
                                }

                                return product;
                            }),
                        };
                    }),
                },
            };

        case RESET_QUOTATION_CONTRACT_SUCCESS:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation, index) => {
                        operation.energySavingCertificatePremium = action.payload.quotation.operations[index].energySavingCertificatePremium;
                        return operation;
                    }),
                    totalNetPriceToPay: action.payload.quotation.totalNetPriceToPay,
                    energySavingCertificatePremium: action.payload.quotation.energySavingCertificatePremium,
                    contract: action.payload.quotation.contract,
                    renovationPremium: action.payload.quotation.renovationPremium,
                    isPricingUpdated: action.payload.quotation.isPricingUpdated,
                },
            };

        case CLEAR_QUOTATION: {
            return {
                ...state,
                quotation: {},
            };
        }

        case SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS: {
            const index = state.quotations.findIndex((quotation) => quotation.uuid === action.payload.quotation.uuid);
            const newArray = [...state.quotations];

            newArray[index].status = quotationStatuses.SENT;

            return {
                ...state,
                quotations: newArray,
            };
        }

        case RESTORE_QUOTATION:
            return {
                ...state,
                quotation: { ...state.quotation },
            };

        case UPDATE_QUOTATION_RENOVATION_GLOBAL_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };
        case UPDATE_QUOTATION_FINANCING_REMAINING_COSTS:
            return {
                ...state,
                quotation: { ...state.quotation, financingType: financingTypes.PERSONAL, credit: {}, financingRemainingCosts: { ...state.quotation.financingRemainingCosts, ...action.payload } },
            };

        case CREATE_QUOTATION_ADVANCE_PAYMENTS_SUCCESS:
        case UPDATE_QUOTATION_ADVANCE_PAYMENT_SUCCESS:
        case DELETE_QUOTATION_ADVANCE_PAYMENT_SUCCESS:
            return {
                ...state,
                quotation: { ...state.quotation, advancePayments: [...action.payload.quotation.advancePayments] },
            };

        case UPDATE_OPERATION_OPERATING_TYPE:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                [action.payload.name]: action.payload.value,
                            };
                        }
                        return operation;
                    }),
                },
            };

        case UPDATE_RENO_GLOBAL_MAIN_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.renovationGlobalOperationUUID) {
                            return {
                                ...operation,
                                renovationGlobal: {
                                    ...operation.renovationGlobal,
                                    operations: operation.renovationGlobal.operations.map((renovationGlobalOperation) => {
                                        if (renovationGlobalOperation.uuid === action.payload.operationUUID) {
                                            return {
                                                ...renovationGlobalOperation,
                                                ...action.payload.operationUpdates,
                                                products: updateOperationMainProductOfARenovationGlobalOperation(renovationGlobalOperation, action.payload.updated),
                                            };
                                        }
                                        return renovationGlobalOperation;
                                    }),
                                },
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_QUOTATION_RENOVATION_GLOBAL_FAILURE:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                },
            };
        case UPDATE_QUOTATION_TYPE: {
            const quotation = state.quotation;
            quotation.quotationType = action.payload.quotationType;
            return {
                ...state,
                quotation,
            };
        }
        default:
            return state;
    }
}

function updateOperationMainProductOfARenovationGlobalOperation(operation, updates) {
    if (!operation?.products || operation.products.length === 0) {
        const updatedMainProduct = { ...updates };
        if (['quantity', 'price'].every((property) => Object.keys(updatedMainProduct).includes(property))) {
            updatedMainProduct.totalPrice = updatedMainProduct.quantity * updatedMainProduct.price;
        }
        const updatedProducts = [...operation.products];
        updatedProducts.push(updatedMainProduct);
        return [updatedMainProduct];
    }
    return updateOperationMainProduct(operation, updates);
}

function updateOperationMainProduct({ products = [] } = {}, updates, productUUID) {
    const additionnalCondition = productUUID ? (product) => product.uuid === productUUID : () => true;

    return products.map((product) => {
        if (product.product.type === productTypes.MAIN_PRODUCT && additionnalCondition(product)) {
            const updatedMainProduct = {
                ...product,
                ...updates,
            };
            if (['quantity', 'price'].every((property) => Object.keys(updatedMainProduct).includes(property))) {
                updatedMainProduct.totalPrice = updatedMainProduct.quantity * updatedMainProduct.price;
            }

            return updatedMainProduct;
        }

        return product;
    });
}

function resetPricingProperties({ renovationPremium } = {}) {
    const additionalChanges = {};
    if (isNonEmptyObject(renovationPremium)) additionalChanges.renovationPremium = {};

    return {
        commercialDiscount: 0,
        energySavingCertificatePremium: 0,
        customerEnergySavingCertificatePremium: 0,
        totalPriceToPay: 0,
        totalNetPriceToPay: 0,
        pricesPerVatRate: [],
        ...additionalChanges,
    };
}

function applyMainProductVariationsChanges(state, action) {
    const { type, payload } = action;
    const { operationUUID, variationUUID, variation, updates } = payload;

    return {
        ...state.quotation,
        operations: state.quotation.operations.map((operation) => {
            let variationsTotalLength = 0;
            if (operation.uuid === operationUUID) {
                const products = operation.products.map((product) => {
                    if (product.product.type === productTypes.MAIN_PRODUCT) {
                        const strategies = {
                            [ADD_MAIN_PRODUCT_VARIATION]: addMainProductVariation,
                            [UPDATE_MAIN_PRODUCT_VARIATION]: updateMainProductVariation,
                            [DELETE_MAIN_PRODUCT_VARIATION]: deleteMainProductVariation,
                        };
                        const selectedStrategy = strategies[type];
                        const variations = selectedStrategy ? selectedStrategy({ variations: product.declinations, variationUUID, variation, updates }) : [];
                        variationsTotalLength = entitiesUtils.roundToTwoDecimal(variations.reduce((accumulator, { length = 0 }) => accumulator + length, 0));
                        const quantity = roundNumberToTwoDecimal(variationsTotalLength);
                        const totalPrice = roundNumberToTwoDecimal(quantity * product.price);
                        const blockValidity = productFormHelper.validateMainProduct({ ...product, quantity, totalPrice, declinations: variations });

                        return { ...product, declinations: variations, quantity, totalPrice, blockValidity };
                    }

                    return product;
                });

                return { ...operation, products, unitValue: roundNumberToTwoDecimal(variationsTotalLength) };
            }

            return operation;
        }),
    };
}

function addMainProductVariation({ variations = [], variation } = {}) {
    return [...variations, variation];
}

function updateMainProductVariation({ variations = [], variationUUID, updates } = {}) {
    return variations.map((variation) => {
        if (variation.uuid === variationUUID) return { ...variation, ...updates };
        return variation;
    });
}

function deleteMainProductVariation({ variations = [], variationUUID }) {
    return variations.filter((variation) => variation.uuid !== variationUUID);
}

function applyQuotationChangesAfterSave(state, action) {
    const { quotation } = action.payload;

    return {
        ...quotation,
        operations: quotation.operations.map((operation) => ({
            ...operation,
            products: operation.products.map((product) => {
                if (!productFormHelper.isCalorifugeProduct(_.get(product, 'product.category'))) return product;

                const declinations = mergeLocalWithRemoteProductDeclinations(state, product);
                const blockValidity = productFormHelper.validateMainProduct({ ...product, declinations });

                return {
                    ...product,
                    blockValidity,
                    declinations,
                };
            }),
        })),
    };
}

function mergeLocalWithRemoteProductDeclinations({ quotation }, product) {
    const { declinations } = product;
    const products = extractProducts(quotation.operations);
    const matchingProduct = products.find(({ uuid }) => product.uuid === uuid);
    if (matchingProduct) declinations.push(...matchingProduct.declinations);

    return removeRedundantDeclinations(declinations);
}

function extractProducts(operations = []) {
    return operations.reduce((accumulator, { products }) => {
        accumulator.push(...products);
        return accumulator;
    }, []);
}

function removeRedundantDeclinations(declinations = []) {
    return declinations.filter((declination, index) => declinations.findIndex(({ uuid }) => declination.uuid === uuid) === index);
}

function extractMatchingAdditionalRelatedOperation(additionalRelatedOperations, operation) {
    return additionalRelatedOperations.find(({ operationUUID }) => operationUUID === operation.uuid);
}
