import { useCallback } from 'react';
import L from 'leaflet';
import * as GeoSearch from 'leaflet-geosearch';
import 'leaflet-simple-map-screenshoter';
import { pluginDefaultOptions } from './pluginDefaultOptions';

L.drawLocal = {
    ...L.drawLocal,
    draw: {
        ...L.drawLocal.draw,
        toolbar: {
            ...L.drawLocal.draw.toolbar,
            actions: {
                title: 'Annuler',
                text: 'Annuler',
            },
            finish: {
                title: 'Terminer',
                text: 'Terminer',
            },
            undo: {
                title: 'Supprimer le dernier point',
                text: 'Supprimer le dernier point',
            },
            cancel: {
                title: 'Annuler',
                text: 'Annuler',
            },
        },
        handlers: {
            ...L.drawLocal.draw.handlers,
            polygon: {
                tooltip: {
                    start: 'Cliquer pour dessiner la forme',
                    cont: '',
                    end: 'Cliquer sur le premier point pour terminer',
                },
            },
            rectangle: {
                tooltip: {
                    start: 'Cliquer et maintenir pour dessiner le rectangle',
                },
            },
        },
    },
};
export const useLeafletControl = ({ map }) => {
    const onTakeScreenShot = useCallback(
        () => async () => {
            const simpleMapScreenshoter = L.simpleMapScreenshoter(pluginDefaultOptions).addTo(map.current);
            let format = 'blob'; // 'image' - return base64, 'canvas' - return canvas
            let overridedPluginOptions = {
                mimeType: 'image/jpeg',
            };
            return simpleMapScreenshoter.takeScreen(format, overridedPluginOptions);
        },
        [map]
    );

    const createSearchBar = useCallback(
        (onUpdateLocation) => {
            const search = new GeoSearch.GeoSearchControl({
                provider: new GeoSearch.OpenStreetMapProvider({
                    params: {
                        'accept-language': 'fr', // render results in Dutch
                        countrycodes: 'fr', // limit search results to the Netherlands
                        addressdetails: 1,
                    },
                }),
                searchLabel: 'Rechercher une adresse',
            });
            map.current.addControl(search);
            map.current.on('geosearch/showlocation', (e) => {
                const rawLocation = e.location.raw;
                if (onUpdateLocation) onUpdateLocation(rawLocation);
            });
        },
        [map]
    );

    const createToolBox = useCallback(
        (config, onDraw) => {
            const editableLayers = new L.FeatureGroup();
            map.current.addLayer(editableLayers);
            const options = {
                position: 'topleft',
                draw: {
                    polyline: false,
                    circle: false,
                    marker: false,
                    circlemarker: false,
                },
                ...config,
            };

            const drawControl = new L.Control.Draw(options);
            map.current.addControl(drawControl);
            map.current.on(L.Draw.Event.DRAWSTART, (e) => {
                editableLayers.clearLayers();
            });
            map.current.on(L.Draw.Event.CREATED, (e) => onDraw(e, editableLayers));
        },
        [map]
    );

    return {
        createToolBox,
        createSearchBar,
        onTakeScreenShot,
    };
};
