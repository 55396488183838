import applicationConfigurationService from '../services/application-configuration.service';
import { GET_ACTIVE_APPLICATION_CONFIGURATION_REQUEST, GET_ACTIVE_APPLICATION_CONFIGURATION_SUCCESS, GET_ACTIVE_APPLICATION_CONFIGURATION_FAILURE } from '../types/application-configuration.types';
import { parseError } from '../../api';
import applicationConfigurationHelper from '../../utils/application-configuration.helper';
import history from '../../utils/history';

function getActiveApplicationConfiguration() {
    return (dispatch) => {
        dispatch({ type: GET_ACTIVE_APPLICATION_CONFIGURATION_REQUEST });

        applicationConfigurationService
            .getActiveApplicationConfiguration()
            .then(({ data: applicationConfiguration }) => {
                dispatch({ type: GET_ACTIVE_APPLICATION_CONFIGURATION_SUCCESS, payload: { applicationConfiguration } });
                if (applicationConfigurationHelper.isApplicationInMaintenanceMode(applicationConfiguration)) {
                    history.push('/maintenance');
                }
            })
            .catch((err) => {
                const errorMessage = parseError(err);
                dispatch({ type: GET_ACTIVE_APPLICATION_CONFIGURATION_FAILURE, payload: { errorMessage } });
            });
    };
}

export default { getActiveApplicationConfiguration };
