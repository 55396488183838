import { endpointV2, authHeaders, getAuthHeadersWithJSONContentType } from '../../api';
import storage from '../../utils/storage';

const getBaseUrl = () => {
    return `/organizations/${storage.getOrganizationUUID()}/invoices`;
};

function getUpdateInvoiceUrl(invoiceUUID) {
    return getBaseUrl().concat(`/${invoiceUUID}`);
}

const createInvoice = (datas) => endpointV2.post(getBaseUrl(), datas, { headers: authHeaders() });

const getInvoice = (uuidInvoice) => endpointV2.get(`${getBaseUrl()}/${uuidInvoice}`, { headers: authHeaders() });

const saveInvoice = (uuidInvoice, updates) => endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/save`, updates, { headers: authHeaders() });

const getInvoices = (query) => endpointV2.get(getBaseUrl(), { headers: authHeaders(), params: query });

const getInvoicesCount = (query) => endpointV2.get(`${getBaseUrl()}/count`, { headers: authHeaders(), params: query });

const deleteInvoice = (uuidInvoice) => endpointV2.delete(`${getBaseUrl()}/${uuidInvoice}/delete`, { headers: authHeaders() });

function cancelInvoice(uuidInvoice, params = {}) {
    return endpointV2.put(`${getUpdateInvoiceUrl(uuidInvoice)}/cancel`, params, { headers: getAuthHeadersWithJSONContentType() });
}

const updateInvoiceStatusToPaid = (uuidInvoice, updates) => endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/pay-invoice`, updates, { headers: authHeaders() });

const sendInvoiceByEmail = (invoiceUUID, datas) => endpointV2.put(`${getBaseUrl()}/${invoiceUUID}/send`, datas, { headers: authHeaders() });

const getInvoicesAnalytics = () => endpointV2.get(`/organizations/${storage.getOrganizationUUID()}/analytical-data/invoices`, { headers: authHeaders() });

function generateMovingCertificate(invoiceUUID, data) {
    return endpointV2.put(`${getUpdateInvoiceUrl(invoiceUUID)}/generate-moving-certificate`, data, { headers: authHeaders() });
}

function updateInvoice(uuidInvoice, updates) {
    return endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/update`, updates, { headers: authHeaders() });
}

async function updateAProductInARenoglobalOperation(uuidInvoice, updates) {
    return endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/update-a-product-in-a-renovation-global-operation`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateATechnicianInARenoglobalOperation(uuidInvoice, updates) {
    return endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/update-an-operation-technician-in-a-renovation-global-operation`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateOperationInvoiceTechnicalVisitationDateInARenovationGlobal(uuidInvoice, updates) {
    return endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/update-an-operation-visitation-date-in-a-renovation-global-operation`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

function rectifyInvoice(uuidInvoice) {
    return endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/rectify`, {}, { headers: authHeaders() });
}

function acquitInvoice(uuidInvoice) {
    return endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/acquit-invoice`, {}, { headers: authHeaders() });
}

async function updateACategoryInARenoglobalOperation(uuidInvoice, updates) {
    return endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/update-a-category-in-a-renovation-global-operation`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

export default {
    createInvoice,
    getInvoice,
    saveInvoice,
    getInvoices,
    deleteInvoice,
    cancelInvoice,
    updateInvoiceStatusToPaid,
    sendInvoiceByEmail,
    getInvoicesAnalytics,
    getInvoicesCount,
    updateInvoice,
    generateMovingCertificate,
    updateAProductInARenoglobalOperation,
    updateATechnicianInARenoglobalOperation,
    updateOperationInvoiceTechnicalVisitationDateInARenovationGlobal,
    rectifyInvoice,
    acquitInvoice,
    updateACategoryInARenoglobalOperation
};
