import { dossierCEEDocumentTypes } from '../../enums';

import React from 'react';
import { formatUploadDocument } from './formatUploadDocument';
import { SidebarDocumentButtonForCadastralPlot } from './components/SidebarDefaultDocumentButton';

export const formatCadastralPlotAsDocument = (dossierCEE) => {
    const document = formatUploadDocument(dossierCEE, dossierCEEDocumentTypes.CADASTRAL_PLOT);
    return {
        ...document,
        message: 'Cliquez ici pour générer la parcelle cadastrale',
        renderButton: ({ document, selectedDocument, onSelectDocument, index }) => (
            <SidebarDocumentButtonForCadastralPlot dossierCEE={dossierCEE} document={document} selectedDocument={selectedDocument} onSelectDocument={onSelectDocument} index={index} />
        ),
    };
};
