const allOperationOfRenovationGlobal = ['BAR_TH_164', 'BAR_TH_175', 'BAR_TH_174'];
export const getOperationOfRenovationGlobalFromOperations = (entity = {}) => {
    return entity?.operations?.find((operation) => {
        if (!operation?.operationSheetName) return false;
        const [rawOperationSheetName] = operation?.operationSheetName?.split('V_');
        return allOperationOfRenovationGlobal.includes(rawOperationSheetName);
    });
};

export const isOperationOfRenovationGlobal = ({ operationSheetName }) => {
    if (!operationSheetName) return false;
    const [rawOperationSheetName] = operationSheetName.split('V_');
    return allOperationOfRenovationGlobal.includes(rawOperationSheetName);
};

export const getOperationOfRenovationGlobalIndex = ({ operations }) => {
    return operations?.findIndex((operation) => {
        return isOperationOfRenovationGlobal(operation);
    });
};

export const getOperationOfRenovationGlobalFromUUID = ({ operations, operationOfRenovationGlobalUUID }) => {
    return operations?.findIndex((operation) => {
        return operation.uuid === operationOfRenovationGlobalUUID;
    });
};

export const typeOfRenovationGlobal = {
    GLOBALE: 'GLOBALE',
    AMPLEUR: 'AMPLEUR',
    NONE: 'NONE',
};

export const whichTypeOfRenovationGlobal = ({ operationSheetName }) => {
    if (!operationSheetName) return 'NONE';
    const [rawOperationSheetName] = operationSheetName.split('V_');
    if (rawOperationSheetName === 'BAR_TH_164') return typeOfRenovationGlobal.GLOBALE;
    if (['BAR_TH_175', 'BAR_TH_174'].includes(rawOperationSheetName)) return typeOfRenovationGlobal.AMPLEUR;
    return typeOfRenovationGlobal.NONE;
};

export const isOperationAnOperationDampleur = ({ operationSheetName }) => {
    return whichTypeOfRenovationGlobal({ operationSheetName }) === typeOfRenovationGlobal.AMPLEUR;
};

export const doesTheQuotationContainAnOperationDampleur = (quotation) => {
    const operation = getOperationOfRenovationGlobalFromOperations(quotation);
    if (!operation) return false;
    return isOperationAnOperationDampleur(operation);
};
