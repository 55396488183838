import React from 'react';
import PropTypes from 'prop-types';
import { Field, Button } from '@renolib/renolib-ui-kit';
import Form from '../../../../helpers/Form';
import DatePicker from '../../../../helpers/DatePicker';

import Modal from '../../../../helpers/Modal';
import invoiceOptions from '../../../../../utils/select_options/invoice';

const ConfirmPaymentModal = ({ show, onHide, onCreate, errors, onChange, minDate }) => {
    return (
        <Modal
            title='Confirmer le paiement de la facture'
            show={show}
            onHide={onHide}
            size='small'
            actionView={
                <Button lightMode='1' errors={errors.form} onClick={onCreate} disabled={false}>
                    Confirmer la date de paiement
                </Button>
            }>
            <Modal.Body>
                <Form style={{ width: '80%' }}>
                    <Form.Group className='filled-date-picker-container'>
                        <div>
                            <DatePicker label='SELECTIONNER LA DATE DE PAIEMENT' onChange={onChange} name='paymentDate' minDate={minDate} />
                        </div>
                    </Form.Group>

                    <Form.Group>
                        <div>
                            <Field
                                fluid
                                type='select'
                                label='SELECTIONNER LE TYPE DE PAIEMENT'
                                name='paymentType'
                                options={invoiceOptions.paymentTypeOptions}
                                onChange={onChange}
                                error={errors.paymentType}
                            />
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

ConfirmPaymentModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
};

export default ConfirmPaymentModal;
