export const ACCOUNT_VALIDATION_EMAIL_SENT = 'Un nouveau lien de vérification vous a été renvoyé';

export const ONBOARDING_DIALOGS_ACTIONS_UPDATED = 'Votre choix a bien été enregistré.';

export const ACCESS_RIGHTS_UPDATED = 'Les accès de vos utilisateurs ont bien été mis à jour.';

export const ACCESS_RIGHTS_LIMITED = 'En activant cette option vous limitez la visibilité de vos collaborateurs. Seuls les dossiers traités par leurs soins seront visibles.';

export const ACCOUNT_NOT_DELETABLE = "Seul le compte pricipal de l'organisation peut supprimer des utilisateurs.";

export const ACCOUNT_DELETED = "L'utilisateur a bien été supprimé.";

export const ORGANIZATION_SWITCH_WARNING =
    'Attention ! Vous êtes sur le point de changer de compte. Avant de continuer, assurez-vous de sauvegarder toutes les modifications que vous avez apportées. Veuillez noter que ce changement affectera tous les onglets ouverts dans votre navigateur.<br />Merci pour votre coopération !';

export const ACCOUNT_UPDATED = 'Le compte a bien été mis à jour';
