import { shouldRetrieveAllOccupants } from '../../../../utils/dossier-cee-helper';

export function getOccupantsOptions({ dossierCEE }) {
    const { quotation } = dossierCEE;
    const {
        householdIncomeBonification: { housings },
    } = quotation.address;
    if (housings.length === 0) return [];
    const { occupants } = housings[0];
    return (
        occupants
            //.filter((occupant) => !occupant.taxNoticeFileDownloadUrl)
            .filter((occupant) => (shouldRetrieveAllOccupants(quotation) ? occupant.situation : occupant.situation !== 'CLASSIC'))
            .map((occupant) => {
                return { label: `${occupant.firstName} ${occupant.lastName}`, value: occupant.uuid };
            })
    );
}
