import styled from 'styled-components';
import PropTypes from 'prop-types';

const Form = styled.form`
    margin: ${({ marginY }) => (marginY === 'none' ? 0 : marginY || '1.5rem')} auto;

    &.form-hidden {
        width: 0;
        height: 0;
        opacity: 0;
    }
`;

const FormGroup = styled.div`
    margin-bottom: ${({ marginBottom }) => marginBottom || '1rem'};

    &.no-label {
        > label {
            opacity: 0;
            font-size: 0;
        }
    }

    > * {
        :first-child {
            margin-right: ${({ spacing = 0 }) => `${spacing}rem`};
        }
    }
`;

Form.Row = styled.div`
    display: grid;
    align-items: center;
    margin-bottom: 1rem;
    grid-template-columns: ${(props) => `repeat(${props.children.length}, 1fr)`};

    > ${FormGroup} {
        margin-bottom: 0rem;
        margin-right: 2rem;
    }
`;

Form.FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
        :first-child {
            flex: 1;
        }
    }
`;

Form.Label = styled.label`
    display: inline-block;
    margin-bottom: 0.25rem;
    color: var(--color-input-label);
    font-size: 0.75rem;
`;

Form.Label.propTypes = {
    size: PropTypes.string,
};

Form.Label.defaultProps = {
    size: 'default',
};

Form.CenteredRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

Form.Group = FormGroup;

Form.Space = styled.div`
    height: 1rem;
`;
Form.Divider = styled.div`
    height: 1px;
    border: 1px solid #d6dce9;
    opacity: 0.4;
    margin-bottom: 1rem;
`;

Form.Footer = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

Form.SubTitle = styled.span`
    display: inline-block;
    margin-bottom: 0.25rem;
    font-weight: 700;
    text-transform: uppercase;
`;

Form.Alert = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 0.75rem;
    font-weight: 700;
    align: center;
`;

export default Form;
