export const ORGANIZATION_INSURANCE_CREATED = "Votre contrat d'assurance a bien été enregistré. Il est en cours de vérification.";
export const ORGANIZATION_INSURANCE_DELETED = "Votre contrat d'assurance a bien été supprimé.";

export const ACCOUNT_NOT_VERIFIED = 'Vous devez confimer votre adresse email.';
export const ALLOWED_USERS_COUNT_REACHED = "Vous avez atteint le nombre maximum d'utilisateurs pour votre forfait.";

export const INVITATION_DELETED = "L'invitation a bien été supprimée.";

export const DISCONNECTION = 'Se déconnecter';

export const ORGANIZATION_INFORMATION_REFRESHED = 'Vos informations ont bien été mises à jour.';

export const ORGANIZATION_WANTS_PREMIUM_OPTION = "L'organisation {name} | {siren} souhaite être rappelé pour l'option premium.";

export const PREMIUM_OPTION = 'Cliquer ici pour ouvrir le chat et parler à votre chargé de compte';

export const MUST_BE_ADMIN = 'Vous devez être administrateur pour accéder à cette fonctionnalité';
