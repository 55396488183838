import React, { Fragment } from 'react';
import useModal from '../../../hooks/useModal';
import uploadDocumentHelper from '../../../../utils/upload-document-helper';
import CreateUploadDocumentModal from './CreateUploadDocumentModal';
import { useUploadDocuments } from '../../../hooks/useUploadDocuments';

export const CreateUploadDocumentModalRenderProps = ({ render, defaultFormState = undefined, isUploadOtherDocument, disabledFieldsSelection, operationSheets = [], uploadDocumentActions }) => {
    const { validateUploadDocumentForm, checkIfDocumentExist, updateState, reset, state, handleChange, errors, message } = useUploadDocuments({
        onDocumentCreated: onDocumentCreated,
        defaultFormState,
        uploadDocumentActions,
    });
    const { handleShow, handleHide, show } = useModal();

    function handleFileChange({ file }) {
        handleShow();
        const { fileName, fileType } = uploadDocumentHelper.extractFileNameAndFileType(file);
        updateState({ ...defaultFormState, fileName, fileType, file });
    }

    function handleHideCreateUploadDocumentModal() {
        reset(defaultFormState);
        handleHide();
    }

    function onDocumentCreated() {
        handleHideCreateUploadDocumentModal();
    }

    return (
        <Fragment>
            {render && render({ handleFileChange })}
            {show && (
                <CreateUploadDocumentModal
                    show={show}
                    defaultFormState={defaultFormState}
                    operationSheets={operationSheets}
                    isUploadOtherDocument={isUploadOtherDocument}
                    disabledFieldsSelection={disabledFieldsSelection}
                    formState={state}
                    checkIfDocumentExist={checkIfDocumentExist}
                    formErrors={errors}
                    updateFormState={updateState}
                    message={message}
                    onHide={handleHideCreateUploadDocumentModal}
                    onChange={handleChange}
                    onSave={validateUploadDocumentForm}
                />
            )}
        </Fragment>
    );
};
