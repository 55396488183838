import { isNonEmptyArray, isNullish, isNullishOrEmpty, isValidValue } from '..';

function findProperty(array, propertyName) {
    return array.find(({ label }) => label === propertyName);
}

function includesProperty(array, propertyName) {
    return !!findProperty(array, propertyName);
}

function assignDefaultValuesInTarget(target, descriptor) {
    descriptor.forEach(({ name, defaultValue }) => {
        if (isValidValue(defaultValue) && !includesProperty(target, name)) {
            target.push({ label: name, value: defaultValue });
        }
    });
}

function isValueValid(value) {
    let valid = true;
    if (typeof value !== 'boolean' && !value) valid = false;

    return valid;
}

function validatePropertyAsRequiredInTarget(target, { required, name }) {
    let validPropertyAsRequired = true;
    if (required && (!includesProperty(target, name) || !isValueValid(findProperty(target, name).value))) validPropertyAsRequired = false;

    return validPropertyAsRequired;
}

function validatePropertyAsRequiredValueInTarget(target, { requiredValue, name }) {
    let validPropertyAsRequired = true;
    if (requiredValue !== undefined && findProperty(target, name).value !== requiredValue) validPropertyAsRequired = false;

    return validPropertyAsRequired;
}

function isPropertyRequired(requiredIfPropertyName, requiredIfPropertyValue, descriptorData) {
    if (isNullish(requiredIfPropertyName)) return false;

    const providedValue = descriptorData[requiredIfPropertyName];

    return isNonEmptyArray(requiredIfPropertyValue) ? requiredIfPropertyValue.includes(providedValue) : requiredIfPropertyValue === providedValue;
}

function validatePropertyAsRequiredIfValueInTarget(target, { requiredIfPropertyName, requiredIfPropertyValue, name }, descriptorData) {
    let validPropertyAsRequiredIf = true;
    if (isPropertyRequired(requiredIfPropertyName, requiredIfPropertyValue, descriptorData) && (!findProperty(target, name) || !isValueValid(findProperty(target, name).value))) {
        validPropertyAsRequiredIf = false;
    }

    return validPropertyAsRequiredIf;
}

function validatePropertiesInTarget(target, descriptor, descriptorData) {
    let validTarget = true;

    descriptor.forEach((propertyDescriptor) => {
        if (Object.prototype.hasOwnProperty.call(propertyDescriptor, 'validate')) {
            validTarget = propertyDescriptor.validate(target, propertyDescriptor, descriptorData);
        } else {
            let validProperty = validatePropertyAsRequiredInTarget(target, propertyDescriptor, descriptorData);
            validProperty = validProperty && validatePropertyAsRequiredValueInTarget(target, propertyDescriptor, descriptorData);
            validProperty = validProperty && validatePropertyAsRequiredIfValueInTarget(target, propertyDescriptor, descriptorData);
            validProperty = validProperty && validatePropertyAsMaxmiumValueInTarget(target, propertyDescriptor, descriptorData);
            validTarget = validTarget && validProperty;
        }
    });

    return validTarget;
}

function validatePropertyAsMaxmiumValueInTarget(target, { maximum, name, inclusive = true } = {}) {
    if (isNullish(maximum)) return true;

    const property = findProperty(target, name);
    if (isNullishOrEmpty(property) || typeof property.value !== 'number') return true;

    return inclusive ? property.value <= maximum : property < maximum;
}

export default function validateArrayTarget(target, descriptor, descriptorData) {
    const newTarget = [...target];
    assignDefaultValuesInTarget(newTarget, descriptor);

    const validTarget = validatePropertiesInTarget(newTarget, descriptor, descriptorData);

    return { newTarget, validTarget };
}
