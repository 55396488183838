import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@renolib/renolib-ui-kit';
import { Worker } from '@phuocng/react-pdf-viewer';
import { Viewer } from '@react-pdf-viewer/core';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { PDF_VIEWER_TEST_ID } from '../../tests/utils/tests-ids';
import useFileViewer from '../hooks/useFileViewer';
import { StyledRenderLayoutPDFViewer } from './RenderLayoutPDFViewer';
import { pdfViewerPropType } from '../../utils/prop-types-schemas';

function UnStyledPDFViewer({ displayLayout, handleTextLayerRender, file, downloadUrl, onOpen, openTitle, withZoomPlugin = false }) {
    const { url } = useFileViewer({ file, downloadUrl });

    const zoomPluginInstance = zoomPlugin({ enableShortcuts: true });
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    function handleDocumentLoad() {}

    return (
        <>
            {url && (
                <>
                    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js'>
                        {url && (
                            <div>
                                {withZoomPlugin && (
                                    <div className={'zoom-bar'}>
                                        <ZoomOutButton />
                                        <ZoomPopover />
                                        <ZoomInButton />
                                    </div>
                                )}
                                <Viewer
                                    fileUrl={url}
                                    layout={displayLayout}
                                    onTextLayerRender={handleTextLayerRender}
                                    onDocumentLoad={handleDocumentLoad}
                                    plugins={withZoomPlugin ? [zoomPluginInstance] : []}
                                />
                            </div>
                        )}
                    </Worker>
                    {onOpen && openTitle && (
                        <div className='action'>
                            <Button icon='visibility' onClick={onOpen}>
                                {openTitle}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default function PDFViewer({ testId = PDF_VIEWER_TEST_ID, full = false, large = false, toolbarHidden = false, openTitle = 'Consulter le document', ...restProps }) {
    return (
        <StyledRenderLayoutPDFViewer
            testId={testId}
            full={full}
            large={large}
            toolbarHidden={toolbarHidden}
            {...restProps}
            render={({ handleTextLayerRender, displayLayout }) => {
                return <UnStyledPDFViewer openTitle={openTitle} displayLayout={displayLayout} handleTextLayerRender={handleTextLayerRender} {...restProps} />;
            }}
        />
    );
}

PDFViewer.propTypes = {
    ...pdfViewerPropType,
    isRequired: PropTypes.bool,
};
