import { endpointV2, authHeaders } from '../../api';
import storage from '../../utils/storage';

const baseUrl = '/operation-sheets';

async function getOperationSheets(params) {
    return endpointV2.get(baseUrl, {
        headers: authHeaders(),
        params: { ...params, organizationUUID: storage.getOrganizationUUID() },
    });
}

async function getOperationSheet(operationSheetId) {
    return endpointV2.get(`${baseUrl}/${operationSheetId}`, {
        headers: authHeaders(),
    });
}

export default {
    getOperationSheets,
    getOperationSheet,
};
