import { isEmailDisposable } from '../email-verification-helper';
import { UNAUTHORIZED_EMAIL } from '../messages';
import EmailValidator from 'email-validator';
import { isValidValue } from '../index';
import { customErrorMessages } from '../error-messages';
import { LABEL_CONSENT, LABEL_CONTRACT_TYPE, LABEL_INPUT_EMAIL, LABEL_INPUT_FIRST_NAME, LABEL_INPUT_LAST_NAME, LABEL_INPUT_PHONE } from '../form_labels';

export default [
    {
        property: 'lastName',
        label: LABEL_INPUT_LAST_NAME,
        type: 'string',
        required: true,
    },
    {
        property: 'firstName',
        label: LABEL_INPUT_FIRST_NAME,
        type: 'string',
        required: true,
    },
    {
        property: 'phone',
        label: LABEL_INPUT_PHONE,
        type: 'string',
        dataType: 'phoneNumber',
        required: true,
    },
    {
        property: 'contractType',
        label: LABEL_CONTRACT_TYPE,
        type: 'string',
        required: true,
    },
    {
        property: 'email',
        label: LABEL_INPUT_EMAIL,
        type: 'string',
        dataType: 'emailAddress',
        required: true,
        validate: async ({ email }, formErrors) => {
            const isDisposableEmail = await isEmailDisposable(email);

            if (!isValidValue(email) || !EmailValidator.validate(email)) {
                formErrors.email = customErrorMessages.missingEmail;
                return false;
            }

            if (isDisposableEmail) formErrors.email = UNAUTHORIZED_EMAIL;
            return !isDisposableEmail;
        },
    },
    {
        property: 'consentAccepted',
        label: LABEL_CONSENT,
        type: 'boolean',
        required: true,
    },
];
