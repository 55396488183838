import { combineReducers } from 'redux';

// UI
import formReducer from './ui/form';
import lastActionReducer from './ui/last-action';
import dialogsReducer from './ui/dialogs';
import onboardingReducer from './ui/onboarding';
import filtersReducer from './ui/filters';

// Core
import addressFinderReducer from './address-finder.reducer';
import authReducer from './auth';
import consumerMediatorReducer from './consumer-mediator.reducer';
import contractReducer from './contracts.reducer';
import customerPMReducer from './customer-pm.reducer';
import customerPPReducer from './customer-pp.reducer';
import customerReducer from './customer.reducer';
import dashboardReducer from './dashboard.reducer';
import dossierCEEReducer from './dossier-cee.reducer';
import fiscalInformationFinderReducer from './fiscal-information-finder.reducer';
import invoiceReducer from './invoice.reducer';
import operationSheetReducer from './operation-sheet.reducer';
import organizationFinderReducer from './organization-finder';
import organizationReducer from './organization.reducer';
import productCatalogueState from './product-catalogue.reducer';
import productReducer from './product.reducer';
import planningReducer from './planning.reducer';
import quotationReducer from './quotation.reducer';
import renovationAddressReducer from './renovation-address.reducer';
import rgeCertificatesFinderReducer from './rge-certificates-finder.reducer';
import subContractorsReducer from './sub-contractors.reducer';
import uploadDocumentReducer from './upload-document.reducer';
import obligatedReducer from './obligated.reducer';
import exportsReducer from './exports.reducer';
import cancelledDocumentReducer from './cancelledDocument.reducer';
import delegateeInvoicesReducer from './delegatee-invoices.reducer';
import mailsHistoryReducer from './mails-history.reducer';
import applicationConfigurationReducer from './application-configuration.reducer';
import energyRenovationServiceReducer from './energy-renovation-service.reducer';
import energyRenovationWorkReducer from './energy-renovation-work.reducer';
import adminReducer from './admin.reducer';
import signatureReducer from './signature.reducer';
import financingReducer from './financing.reducer';

export default combineReducers({
    addressFinder: addressFinderReducer,
    auth: authReducer,
    consumerMediatorsState: consumerMediatorReducer,
    contracts: contractReducer,
    customersPMState: customerPMReducer,
    customersPPState: customerPPReducer,
    customersState: customerReducer,
    dashboardState: dashboardReducer,
    dossiersCEEState: dossierCEEReducer,
    fiscalInformationFinder: fiscalInformationFinderReducer,
    invoicesState: invoiceReducer,
    operationSheetsState: operationSheetReducer,
    organization: organizationReducer,
    organizationFinder: organizationFinderReducer,
    productsCatalogueState: productCatalogueState,
    productsState: productReducer,
    planning: planningReducer,
    quotationsState: quotationReducer,
    renovationAddressesState: renovationAddressReducer,
    obligatedsState: obligatedReducer,
    rgeCertificatesFinder: rgeCertificatesFinderReducer,
    subContractors: subContractorsReducer,
    ui: combineReducers({
        form: formReducer,
        lastAction: lastActionReducer,
        dialogs: dialogsReducer,
        onboarding: onboardingReducer,
        filters: filtersReducer,
    }),
    uploadDocumentsState: uploadDocumentReducer,
    exportsState: exportsReducer,
    cancelledDocumentState: cancelledDocumentReducer,
    delegateeInvoicesState: delegateeInvoicesReducer,
    emailsSent: mailsHistoryReducer,
    applicationConfigurationState: applicationConfigurationReducer,
    energyRenovationServicesState: energyRenovationServiceReducer,
    energyRenovationWorksState: energyRenovationWorkReducer,
    adminState: adminReducer,
    signatureState: signatureReducer,
    financingState: financingReducer,
});
