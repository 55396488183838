export const GENERATE_SIGNING_URL_REQUEST = 'GENERATE_SIGNING_URL_REQUEST';
export const GENERATE_SIGNING_URL_SUCCESS = 'GENERATE_SIGNING_URL_SUCCESS';
export const GENERATE_SIGNING_URL_FAILURE = 'GENERATE_SIGNING_URL_FAILURE';

export const SEND_DOCUMENT_TO_SIGN_REQUEST = 'SEND_DOCUMENT_TO_SIGN_REQUEST';
export const SEND_DOCUMENT_TO_SIGN_SUCCESS = 'SEND_DOCUMENT_TO_SIGN_SUCCESS';
export const SEND_DOCUMENT_TO_SIGN_FAILURE = 'SEND_DOCUMENT_TO_SIGN_FAILURE';

export const DELETE_SIGNED_DOCUMENT_REQUEST = 'DELETE_SIGNED_DOCUMENT_REQUEST';
export const DELETE_SIGNED_DOCUMENT_SUCCESS = 'DELETE_SIGNED_DOCUMENT_SUCCESS';
export const DELETE_SIGNED_DOCUMENT_FAILURE = 'DELETE_SIGNED_DOCUMENT_FAILURE';
