import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { getOperationOfRenovationGlobalIndex } from '../../renovation-global-helper';
import { getDocumentTitle, getDownloadUrl } from '../utils/utils';

export const formatRenovationGlobalCofracAfterWork = (dossierCEE) => {
    const { invoice } = dossierCEE;

    if (!invoice)
        return {
            uuid: uuidV1(),
            title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_AFTER_WORK),
            subTitle: '',
            available: false,
            fileDownloadUrl: undefined,
            defaultDocument: undefined,
            documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_AFTER_WORK,
            fileType: dossierCEESidebarDocumentFileTypes.PDF,
            message: 'À ajouter après la finalisation de la facture',
            position: 9,
        };
    const renovationGlobalIndex = getOperationOfRenovationGlobalIndex(invoice);
    const { documents } = invoice.operations[renovationGlobalIndex].renovationGlobal;

    const documentUrl = getDownloadUrl(documents.cofracAfterWork);

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_AFTER_WORK),
        subTitle: !documentUrl ? 'À ajouter après la finalisation de la facture' : '',
        available: !!documentUrl,
        fileDownloadUrl: documentUrl,
        defaultDocument: invoice,
        documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_AFTER_WORK,
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: !documentUrl ? 'À ajouter après la finalisation de la facture' : '',
        position: 9,
    };
};
