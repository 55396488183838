import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { dossierCEEDocumentTypesOnlyForFrontDistinction, uploadDocumentTypes } from '../../../../utils/enums';
import { CreateUploadDocumentModalRenderProps } from '../../upload_documents/modals/CreateUploadDocumentModalRenderProps';
import DropZone from '../../../helpers/DropZone';
import { Button } from '@renolib/renolib-ui-kit';
import { useSelector } from 'react-redux';
import { StyledDossierCEEBlockAddButton } from './StyledDossierCEEBlockAddButton';

export const useInitUploadDocumentData = ({ documentType, currentAttestionIndex, quotation, dossierCEE }) => {
    const [defaultFormState, setDefaultFormState] = useState();

    const getAttestationConfiguration = useCallback(
        (currentAttestionIndex) => {
            const currentAttestation = quotation.attestations.filter(({ type }) => type === documentType)[currentAttestionIndex];
            if (!currentAttestation) return {};
            return {
                attestationOperationName: currentAttestation.operationName,
                operationUUID: currentAttestation.uuid,
            };
        },
        [documentType, quotation.attestations]
    );

    const updateDefaultFormState = useCallback(
        (currentAttestionIndex) => {
            const strategies = {
                [`${uploadDocumentTypes.ATTESTATION_CHANGE_OF_PRODUCT}`]: (currentAttestionIndex) => getAttestationConfiguration(currentAttestionIndex),
                [`${uploadDocumentTypes.ATTESTATION_CHANGE_OF_SUBCONTRACTOR}`]: (currentAttestionIndex) => getAttestationConfiguration(currentAttestionIndex),
                [`${uploadDocumentTypes.ATTESTATION_PUBLIC_LIGHTNING}`]: (currentAttestionIndex) => getAttestationConfiguration(currentAttestionIndex),
            };

            const defaultState = {
                documentType: documentType,
                dossierCEE: dossierCEE,
                dossierCEEId: `${dossierCEE._id}`,
                quotation: quotation,
                quotationId: `${quotation._id}`,
            };
            if (strategies[documentType]) {
                Object.assign(defaultState, strategies[documentType](currentAttestionIndex));
            }
            setDefaultFormState(defaultState);
        },
        [documentType, dossierCEE, quotation, setDefaultFormState, getAttestationConfiguration]
    );

    useEffect(() => {
        updateDefaultFormState(currentAttestionIndex);
    }, [currentAttestionIndex, updateDefaultFormState]);
    return { defaultFormState };
};

export const CreateUploadDocumentWithAttestationsInitiatedData = ({ currentAttestionIndex, documentType, dossierCEE, uploadDocumentActions }) => {
    const { quotation } = dossierCEE;
    const { defaultFormState } = useInitUploadDocumentData({ quotation, currentAttestionIndex, documentType, dossierCEE });
    return (
        <CreateUploadDocumentModalRenderProps
            defaultFormState={defaultFormState}
            disabledFieldsSelection={true}
            uploadDocumentActions={uploadDocumentActions}
            render={({ handleFileChange }) => {
                const isCurrentAttestationSigned = quotation.attestations.filter(({ type }) => type === documentType)[currentAttestionIndex].status === 'SIGNED';
                if (isCurrentAttestationSigned) return <Fragment></Fragment>;
                return (
                    <div data-name={'rendered-component'} style={{ width: '100%', marginRight: '5px' }}>
                        <DropZone onChange={handleFileChange}>
                            <Button icon='add'>{'Ajouter votre attestation'}</Button>
                        </DropZone>
                    </div>
                );
            }}
        />
    );
};

export const CreateUploadDocumentWithInitiatedData = ({ documentType, dossierCEE, renderCreateModalButton, uploadDocumentActions }) => {
    const { quotation } = dossierCEE;
    const [defaultFormState, setDefaultFormState] = useState();
    const { operationSheets } = useSelector((state) => state.operationSheetsState);

    const updateDefaultFormState = useCallback(() => {
        const defaultState = {
            documentType: documentType === dossierCEEDocumentTypesOnlyForFrontDistinction.ADD_OTHER_DOCUMENT_BUTTON ? undefined : documentType,
            dossierCEE: dossierCEE,
            dossierCEEId: `${dossierCEE._id}`,
            quotation: quotation,
            quotationId: `${quotation._id}`,
        };
        setDefaultFormState(defaultState);
    }, [documentType, dossierCEE, quotation, setDefaultFormState]);

    useEffect(() => {
        updateDefaultFormState();
    }, [updateDefaultFormState]);

    return (
        <CreateUploadDocumentModalRenderProps
            defaultFormState={defaultFormState}
            operationSheets={operationSheets}
            isUploadOtherDocument={documentType === dossierCEEDocumentTypesOnlyForFrontDistinction.ADD_OTHER_DOCUMENT_BUTTON}
            disabledFieldsSelection={true}
            uploadDocumentActions={uploadDocumentActions}
            render={({ handleFileChange }) => {
                if (renderCreateModalButton)
                    return (
                        <div data-name={'rendered-component'}>
                            <DropZone onChange={handleFileChange}>{renderCreateModalButton()}</DropZone>
                        </div>
                    );
                return (
                    <StyledDossierCEEBlockAddButton>
                        <DropZone onChange={handleFileChange}>
                            <Button icon='add' size={'small'} />
                        </DropZone>
                    </StyledDossierCEEBlockAddButton>
                );
            }}
        />
    );
};
