import { useState } from 'react';
import idGenerator from '../../utils/id-generator';
import { isDevelopmentMode } from '../../utils';

export const usePdfToolbarVisibility = (toolbarHidden) => {
    const [toolbarVisible, setToolbarVisible] = useState(true);

    const handleTextLayerRender = () => {
        if (toolbarHidden) return;

        try {
            // get all toolbar items and get the item responsible of the popup menu opening
            const toolbarItems = document.getElementsByClassName('viewer-toolbar-item');
            const showMenuToolbarItem = Array.from(toolbarItems)[12];

            // get the first button element inside that item
            const showMenuToolbarItemInnerButtons = showMenuToolbarItem.getElementsByTagName('button');
            const showMenuButton = Array.from(showMenuToolbarItemInnerButtons)[0];

            // convert that button into a clickable button element and perform click to open the popup menu (since it's troublesome to click with query selector result)
            const showMenuButtonId = idGenerator.generateUniqueId();
            showMenuButton.setAttribute('id', showMenuButtonId);
            const showMenuButtonElement = document.getElementById(showMenuButtonId);
            showMenuButtonElement.focus();
            showMenuButtonElement.click();

            // get all menu items on the popup menu and get the item responsible of hand control option
            const popupMenuItems = document.querySelectorAll('.viewer-menu-item-label');
            const activateHandControlMenuItem = Array.from(popupMenuItems)[5];

            // convert the hand control menu item into a clickable element and perform click
            const activateHandControlMenuItemId = idGenerator.generateUniqueId();
            activateHandControlMenuItem.setAttribute('id', activateHandControlMenuItemId);
            document.getElementById(activateHandControlMenuItemId).click();
        } catch (error) {
            setToolbarVisible(false);
            if (isDevelopmentMode()) {
                console.log('Enabled to activate hand control on pdf viewer', error);
                // TODO: send report to server
            }
        }
    };

    return {
        toolbarVisible,
        handleTextLayerRender,
    };
};
