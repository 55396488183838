export const NO_DELEGATEE_INVOICES = "Aucune facture obligé n'a été créée.";
export const NO_DELEGATEE_INVOICES_FINALIZED = "Aucune facture obligé n'a été finalisée.";
export const NO_DELEGATEE_INVOICES_SENT = "Aucune facture obligé n'a été envoyée.";

export const NO_BILLING_NOTE_PROVIDED = 'Aucun appel à facturation renseigné';

export const CONFIRM_DELEGATEE_INVOICE_DELETION = 'Confirmez vous la suppression de cette facture ?';
export const CONFIRM_DELEGATEE_INVOICE_RECTIFICATION = 'Confirmez vous la rectification de cette facture ?';
export const CONFIRM_DELEGATEE_INVOICE_CANCELLATION = 'Confirmez vous lʼannulation de cette facture ?';

export const DELEGATEE_INVOICE_MUST_BE_COMPLETED = 'Veuillez compléter votre facture pour effectuer cette action';

export const NO_MORE_INVOICEABLE_OPERATION = "Il n'y a pas d'autres dossiers CEE envoyés à votre Obligé restant à facturer.";
