import { LABEL_REFUSAL_REASON } from '../messages';

export default [
    {
        property: 'comment',
        label: LABEL_REFUSAL_REASON,
        type: 'string',
        required: true,
    },
];
