import { LABEL_EPAISSEUR, LABEL_RESISTANCE, LABEL_ISOLANT_REFLECTIVE } from '../../product-constants';

export default [
    {
        property: LABEL_EPAISSEUR,
        label: 'Epaisseur',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_RESISTANCE,
        label: 'Résistance thermique',
        type: 'number',
        required: true,
    },
    {
        property: LABEL_ISOLANT_REFLECTIVE,
        label: LABEL_ISOLANT_REFLECTIVE,
        type: 'string',
        required: true,
    },
];
