import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { MANDATORY_DOSSIER_CEE_DOCUMENT } from '../../messages';
import { getSitePhotosRenovationFileDownloadUrls, isSitePhotoBeforeRenovationDocument, isSitePhotoMandatoryDocument } from '../utils/utils';

export const formatSitePhotosBeforeRenovationAsDocument = (dossierCEE) => {
    const { uploadDocuments = [], mandatoryDocuments = [] } = dossierCEE;
    const sitePhotoBeforeRenovationDocuments = uploadDocuments.filter(isSitePhotoBeforeRenovationDocument);
    const sitePhotoBeforeRenovationFileDownloadUrls = getSitePhotosRenovationFileDownloadUrls(sitePhotoBeforeRenovationDocuments);

    const formattedSitePhotoBeforeRenovation = {
        uuid: uuidV1(),
        title: `Photos du site avant travaux`.concat(sitePhotoBeforeRenovationFileDownloadUrls.length > 0 ? ` (${sitePhotoBeforeRenovationFileDownloadUrls.length})` : ''),
        fileDownloadUrls: sitePhotoBeforeRenovationFileDownloadUrls,
        fileType: dossierCEESidebarDocumentFileTypes.IMAGES,
        documentType: dossierCEEDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION,
        message: MANDATORY_DOSSIER_CEE_DOCUMENT,
        position: 10,
        available: true,
        valid: true,
    };

    if (isSitePhotoMandatoryDocument(mandatoryDocuments)) {
        if (sitePhotoBeforeRenovationFileDownloadUrls.length === 0) {
            formattedSitePhotoBeforeRenovation.available = false;
            formattedSitePhotoBeforeRenovation.valid = false;
        }

        return formattedSitePhotoBeforeRenovation;
    }

    return sitePhotoBeforeRenovationFileDownloadUrls.length > 0 && formattedSitePhotoBeforeRenovation;
};
