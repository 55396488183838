import styled from 'styled-components';
import colors from '../../config/colors';

export const UtilitiesCSS = styled.div`
    .flex-row-align-center {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .text-elipsis {
        text-overflow: ellipsis;
    }
    .flex-row {
        display: flex;
        flex-direction: row;
    }
    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .debug-border {
        border: 1px solid red;
    }

    .debug-background-color {
        background-color: red;
    }

    .w-100 {
        width: 100%;
    }
    .w-80 {
        width: 80%;
    }
    .w-60 {
        width: 60%;
    }
    .space-between {
        justify-content: space-between;
    }

    .justify-content-center {
        justify-content: center;
    }
    .justify-content-end {
        justify-content: end;
    }

    .align-items-baseline {
        align-items: baseline;
    }

    .align-items-end {
        align-items: end;
    }
    .text-align-end {
        text-align: end;
    }

    .text-align-justify {
        text-align: justify;
    }
    .align-items-center {
        display: flex;
        align-items: center;
    }
    .space-around {
        justify-content: space-around;
    }

    .bg-light-gray {
        background-color: rgba(128, 128, 128, 0.1);
    }
    .gray-bg {
        background-color: #bcbcbc;
    }

    .strong-gray-bg {
        background-color: #f8f8f8;
    }
    .bg-white {
        background-color: #fff;
    }

    .color-primary {
        color: ${colors.primary};
    }

    .color-error {
        color: #b7090f;
    }
    .color-error-light {
        color: #dd2c00;
    }
    .color-disabled {
        color: rgba(128, 128, 128, 1);
    }
    .color-gray {
        color: ${colors.gray};
    }
    .color-blue-jeans {
        color: ${colors.blue_jeans};
    }
    .color-sea-green {
        color: ${colors.sea_green};
    }

    .color-emerald-green {
        color: ${colors.emerald_green};
    }

    .background-color-emerald-green-opacity {
        background-color: rgba(19, 182, 70, 0.05);
    }
    .background-color-blue-jeans {
        background-color: ${colors.blue_jeans};
    }
    .color-deep-safron {
        color: ${colors.deepSaffron};
    }

    .color-status-red {
        color: ${colors.statusRed};
    }
    .background-color-deep-safron-opacity {
        background-color: rgba(255, 150, 53, 0.05);
    }

    .color-fire-opal {
        color: ${colors.fireOpal};
    }

    .background-color-fire-opal-opacity {
        background-color: rgba(229, 80, 80, 0.05);
    }

    .background-color-disabled {
        background-color: rgba(128, 128, 128, 0.15);
    }
    .color-gray {
        color: ${colors.gray};
    }

    .color-gold {
        color: ${colors.gold};
    }

    .pointer {
        cursor: pointer;
    }
    .false_link {
        color: #66a4ff;
        text-decoration: underline;
        cursor: pointer;
    }
    .text-orange {
        color: ${colors.new_orange};
    }

    .fs-8 {
        font-size: 8px;
    }

    .fs-10 {
        font-size: 10px;
    }

    .fs-11 {
        font-size: 11px;
    }

    .fs-12 {
        font-size: 12px;
    }

    .fs-13 {
        font-size: 13px;
    }

    .fs-14 {
        font-size: 14px;
    }

    .fs-15 {
        font-size: 15px;
    }
    .fs-16 {
        font-size: 16px;
    }
    .fs-17 {
        font-size: 17px;
    }
    .fs-18 {
        font-size: 18px;
    }
    .fs-19 {
        font-size: 19px;
    }
    .fs-20 {
        font-size: 20px;
    }

    .fs-22 {
        font-size: 22px;
    }

    .fs-24 {
        font-size: 24px;
    }

    .fs-26 {
        font-size: 26px;
    }

    .fs-30 {
        font-size: 30px;
    }
    .fw-400 {
        font-weight: 400;
    }
    .fw-500 {
        font-weight: 500;
    }

    .fw-600 {
        font-weight: 600;
    }

    .fw-700 {
        font-weight: 700;
    }

    .p-3 {
        padding: 3rem;
    }

    .p-2 {
        padding: 2rem;
    }

    .p-1 {
        padding: 1rem;
    }

    .pl-1 {
        padding-left: 1rem;
    }

    .pr-1 {
        padding-right: 1rem;
    }

    .pt-0-5 {
        padding-top: 0.5rem;
    }

    .pb-0-5 {
        padding-bottom: 0.5rem;
    }
    .ml-1 {
        margin-left: 1rem;
    }

    .ml-2 {
        margin-left: 2rem;
    }

    .mr-2 {
        margin-right: 2rem;
    }

    .ws-nowrap {
        white-space: nowrap;
    }

    .mb-1 {
        margin-bottom: 1rem;
    }
    .mb-2 {
        margin-bottom: 2rem;
    }
    .pointer {
        cursor: pointer;
    }

    .cursor-initital {
        cursor: initial;
    }

    .border-radius-10 {
        border-radius: 10px;
    }

    .border-color-primary {
        border: 2px solid ${colors.primary};
    }
    .border-2 {
        border-width: 2px;
    }

    .border-radius-1 {
        border-radius: 5px;
    }

    .border-radius-2 {
        border-radius: 10px;
    }

    .border-radius-3 {
        border-radius: 15px;
    }
    .disabled_actions {
        .material-icons {
            color: ${colors.gray};
        }
    }

    .dark_blue_box_shadow {
        box-shadow: rgba(29, 52, 149, 0.2) 0px 0px 12px;
    }

    .mpr-only-analytic-card > .ant-card-body {
        padding: 12px;
    }

    .light_blue_color {
        color: #66a4ff40;
    }

    .light_jeans_background-color {
        background-color: rgba(29, 52, 149, 0.05);
    }

    .table-select {
        min-width: 200px;
        width: 100%;
    }

    .table-select > .ant-select-selector {
        background-color: #f6f8fa;
        border-radius: 10px;
        box-shadow: 0px 0px 2px 0px rgba(63, 63, 63, 0.25) inset;
        border-color: rgba(63, 63, 63, 0.25) !important;
        width: 100%;
    }

    .select-white {
        background-color: #fff;
    }

    .select-white > div > div > input {
        background-color: #fff !important;
    }

    .select-tag {
        border-radius: 6px;
        box-shadow: 0px 0px 3.3743844032287598px 0px rgba(29, 52, 149, 0.15);
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .bold {
        font-weight: bold;
    }

    .rotate-90 {
        transform: rotate(90deg);
    }
    .rotate-180 {
        transform: rotate(180deg);
    }
    .rotate-minus-90 {
        transform: rotate(-90deg);
    }

    .nowrap {
        text-wrap: nowrap;
    }
    .white-space-break-spaces {
        white-space: break-spaces;
    }

    .alert-encours {
        .ant-alert-action {
            display: flex;
            width: 100%;
            justify-content: end;
        }
    }

    .main_container {
        min-height: 100vh;
        margin: 0 auto;
        padding: 3rem 2rem;
    }

    .PhoneInputInput:focus {
        outline: none;
        border-color: #1d3495;
        box-shadow: 0 0 0 1px rgba(178, 209, 255, 0.49);
    }

    .PhoneInputInput {
        display: block;
        width: 100%;
        padding: 0.525rem 0.85rem;
        border: 1px solid #e4e4e4;
        border-radius: 6px;
        color: #000000;
        -webkit-transition: all 200ms ease-out;
        transition: all 200ms ease-out;
        font-size: 12px;
        min-height: 37px;
        background: #ffffff;
    }

    .PhoneInputCountryIcon,
    .PhoneInputCountryIconImg {
        height: 2rem;
        width: 2rem;
        background-color: #f5f6fa;
        box-shadow: none !important;
        border: none !important;
    }
    .referral-button {
        min-height: 10px;
        padding: 3px 4px;
        margin-right: 8px;
        font-size: 13px;
    }
`;
