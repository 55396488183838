import { getDocumentTitle, getInvoiceMessage, getInvoiceSubTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';

export const extractAdvancePaymentsInvoice = (dossierCEE) => {
    const { advancePaymentsInvoice } = dossierCEE;

    return advancePaymentsInvoice.map((advancePaymentInvoice, index) => ({
        uuid: advancePaymentInvoice.uuid,
        title: getDocumentTitle(dossierCEEDocumentTypes.ADVANCE_PAYMENT_INVOICE),
        documentType: dossierCEEDocumentTypes.ADVANCE_PAYMENT_INVOICE,
        subTitle: getInvoiceSubTitle(advancePaymentInvoice),
        defaultDocument: advancePaymentInvoice,
        message: getInvoiceMessage(advancePaymentInvoice),
        available: isDocumentDownloadLinkAvailable(advancePaymentInvoice),
        fileDownloadUrl: advancePaymentInvoice.fileDownloadUrl,
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        position: 2 + index,
    }));
};
