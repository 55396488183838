import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { getDocumentTitle, getDownloadUrl } from '../utils/utils';

export const formatRenovationGlobalMaPrimeRenovWorkingStatement = (dossierCEE) => {
    const { quotation } = dossierCEE;
    const documentUrl = getDownloadUrl(quotation?.maPrimeRenovWorkingStatement);

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT),
        subTitle: '',
        available: !!documentUrl,
        documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT,
        fileDownloadUrl: documentUrl,
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: quotation?.maPrimeRenovWorkingStatement?.status === 'UNSIGNED' ? 'À signer par le client' : '',
        position: 9,
    };
};
