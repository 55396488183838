import contractService from '../../store/services/contracts.service';
import storage from '../../utils/storage';
import { useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import contractHelper from '../../utils/contract-helper';
import companies from '../../data/companies';

export const useJimo = () => {
    const { organization } = useSelector((state) => state.organization);
    const { account } = useSelector((state) => state.auth);

    const getContracts = useCallback(async () => {
        const result = await contractService.getContracts(storage.getOrganizationUUID(), {});
        return result.data.docs;
    }, []);

    const initJimo = useCallback(async () => {
        const contracts = await getContracts();

        if (organization && account && contracts) {
            window.jimo.push(['set', 'user:email', [account.email]]);
            window.jimo.push([
                'set',
                'user:attributes',
                [
                    {
                        organization_name: organization.name,
                        mprMandate: organization.defaultPermissions?.maPrimeRenov?.financial_and_administrative_representative ? 'Mixte' : 'Administrative',
                        isVito: contractHelper.hasContractCorrespondingToTheObligated(companies.VITOGAZ, contracts).toString(),
                        isIdex: contractHelper.hasContractCorrespondingToTheObligated(companies.IDEX, contracts).toString(),
                    },
                ],
            ]);
            window.jimo.push(['set', 'user:name', [account.fullName]]);
        }
    }, [organization, account, getContracts]);

    useEffect(() => {
        const { userAgent } = navigator;

        if (process.env.NODE_ENV === 'development' || userAgent.includes('Edge') || userAgent.includes('Edg')) return;
        initJimo();
    }, [initJimo]);
};
