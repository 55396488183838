import { endpointV2, authHeaders } from '../../api';
import storage from '../../utils/storage';

const getBaseUrl = () => {
    return `/organizations/${storage.getOrganizationUUID()}/products`;
};

const getProducts = () => endpointV2.get(getBaseUrl(), { headers: authHeaders() });

const getProduct = (uuidProduct) => endpointV2.get(`${getBaseUrl()}/${uuidProduct}`, { headers: authHeaders() });

const getProductQuantityUsedInInvoices = (uuidProduct) => endpointV2.get(`${getBaseUrl()}/${uuidProduct}/product-quantity`, { headers: authHeaders() });

const createProduct = (product) => endpointV2.post(getBaseUrl(), product, { headers: authHeaders() });

const updateProduct = (uuidProduct, updates, options) => endpointV2.put(`${getBaseUrl()}/${uuidProduct}`, updates, { headers: authHeaders(), params: options });

const deleteProduct = (uuidProduct) => endpointV2.delete(`${getBaseUrl()}/${uuidProduct}`, { headers: authHeaders() });

const getProductEligibility = (uuidProduct) => endpointV2.get(`${getBaseUrl()}/${uuidProduct}/product-eligibility`, { headers: authHeaders() });

const getNumberOfOperationsByProductInInvoices = (uuidProduct) => endpointV2.get(`${getBaseUrl()}/${uuidProduct}/number-of-operations`, { headers: authHeaders() });

const duplicateProduct = (uuidProduct) => endpointV2.post(`${getBaseUrl()}/duplicate/${uuidProduct}`, {}, { headers: authHeaders() });

async function archiveProduct(uuid, update) {
    return endpointV2.put(`${getBaseUrl()}/${uuid}/archive-product`, update, {
        headers: authHeaders(),
    });
}

export default {
    getProducts,
    getProduct,
    getProductQuantityUsedInInvoices,
    createProduct,
    updateProduct,
    deleteProduct,
    getProductEligibility,
    getNumberOfOperationsByProductInInvoices,
    duplicateProduct,
    archiveProduct,
};
