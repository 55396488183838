import applicationConfigurationActions from '../actions/application-configuration.actions';
import { GET_ACTIVE_APPLICATION_CONFIGURATION_REQUEST } from '../types/application-configuration.types';

export default ({ dispatch }) =>
    (next) =>
    (action) => {
        if (canTriggerGetActiveApplicationConfiguration(action)) {
            dispatch(applicationConfigurationActions.getActiveApplicationConfiguration());
        }

        return next(action);
    };

function canTriggerGetActiveApplicationConfiguration({ type }) {
    return type !== GET_ACTIVE_APPLICATION_CONFIGURATION_REQUEST && /.+_REQUEST$/.test(type) && !/ANALYTICS/.test(type);
}
