import axios from 'axios';

const formatQuery = (params) => {
    return Object.keys(params).reduce((acc, curr, index) => {
        const element = index === 0 ? '?' : '&';
        if (curr) acc += `${element}${curr}=${params[curr]}`;
        return acc;
    }, '');
};

export const getInseeCode = ({ city, postalCode }) => {
    return axios.get(
        `https://public.opendatasoft.com/api/records/1.0/search/?dataset=correspondance-code-insee-code-postal&q=${city} ${postalCode}&facet=insee_com&facet=nom_dept&facet=nom_region&facet=statut`
    );
};
export const getCommune = (codeInsee) => {
    return axios.get(`https://apicarto.ign.fr/api/cadastre/commune?code_insee=${codeInsee}`);
};

export const getParcelle = async (params, geometry) => {
    const query = formatQuery(params);
    try {
        return await axios.get(`https://apicarto.ign.fr/api/cadastre/parcelle${query}&geom=${JSON.stringify(geometry)}&_limit=700`);
    } catch (e) {
        return await axios.get(`https://apicarto.ign.fr/api/cadastre/parcelle${query}&_limit=700`);
    }
};

export const getFeuille = ({ codeInsee }) => {
    const isParis = codeInsee?.substr(0, 2) === '75';
    let params = `code_insee=${codeInsee}`;
    if (isParis) {
        const codeArr = codeInsee?.substr(2, 3);
        params = `code_arr=${codeArr}`;
    }
    return axios.get(`https://apicarto.ign.fr/api/cadastre/feuille?${params}&_limit=500`);
};
/*
async function getQuotation(quotationUUID) {
    return endpointV2.get(`${getBaseUrl()}/${quotationUUID}`, {
        headers: authHeaders(),
    });
}
 */
