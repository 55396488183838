import { quantifiableFieldsDescriptor } from './common_descriptor_generator';

export default [
    {
        property: 'serviceName',
        label: 'Nom du service',
        type: 'string',
        required: true,
    },
    ...quantifiableFieldsDescriptor({ min: 1 }),
];
