import { endpointV2, authHeaders, getAuthHeadersWithJSONContentType } from '../../api';
import storage from '../../utils/storage';
import { roundNumberToTwoDecimal } from '../../utils';
import { CREDIT_FINANCING_ROUTE } from '../../api/routes';

function getBaseUrl() {
    return `/organizations/${storage.getOrganizationUUID()}/quotations`;
}

async function createQuotation() {
    return endpointV2.post(getBaseUrl(), null, {
        headers: authHeaders(),
    });
}

async function getQuotationsCount(query) {
    return endpointV2.get(`${getBaseUrl()}/count`, {
        headers: authHeaders(),
        params: {
            ...query,
        },
    });
}

async function getQuotations(query) {
    return endpointV2.get(getBaseUrl(), {
        headers: authHeaders(),
        params: {
            ...query,
        },
    });
}

async function getQuotation(quotationUUID) {
    return endpointV2.get(`${getBaseUrl()}/${quotationUUID}`, {
        headers: authHeaders(),
    });
}

async function patchQuotation(quotationUUID, operation, path, value) {
    return endpointV2.patch(
        `${getBaseUrl()}/${quotationUUID}`,
        { op: operation, path, value },
        {
            headers: getAuthHeadersWithJSONContentType(),
        }
    );
}

async function saveQuotation(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/save`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function saveQuotationAsDraft(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/save-as-draft`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function refuseQuotation(quotationUUID, refusalReason) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/refuse`, { refusalReason }, { headers: getAuthHeadersWithJSONContentType() });
}

async function deleteQuotation(quotationUUID, accountUUID) {
    return endpointV2.delete(`${getBaseUrl()}/${quotationUUID}`, { headers: authHeaders(), params: { uuidAccount: accountUUID } });
}

async function getQuotationsAnalytics() {
    return endpointV2.get(`/organizations/${storage.getOrganizationUUID()}/analytical-data/quotations`, { headers: authHeaders() });
}

async function checkQuotationInformationRedundancy(quotation) {
    return endpointV2.post(`${getBaseUrl()}/information-redundancy-check`, quotation, { headers: getAuthHeadersWithJSONContentType() });
}

async function sendQuotationByEmail(quotationUUID, datas) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/send`, datas, { headers: getAuthHeadersWithJSONContentType() });
}

async function resetQuotationContract(quotationUUID) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/reset-contract`, {}, { headers: getAuthHeadersWithJSONContentType() });
}

async function setQuotationSentToCustomer(quotationUUID) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/set-quotation-to-sent-customer`, {}, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateFinancingOfRemainingCosts(quotationUUID, update) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/update-financing-of-remaining-costs`, update, { headers: getAuthHeadersWithJSONContentType() });
}

async function duplicateQuotation(quotationUUID) {
    return endpointV2.post(`${getBaseUrl()}/duplicate/${quotationUUID}`, null, {
        headers: authHeaders(),
    });
}

async function updateQuotationAudit(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/update-audit`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateAProductInARenoglobalOperation(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/update-a-product-in-a-renovation-global-operation`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateAnOperationInstallationDateInARenoglobalOperation(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/update-an-operation-installation-date-in-a-renovation-global-operation`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateATechnicianInARenoglobalOperation(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/update-an-operation-technician-in-a-renovation-global-operation`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateAnOperationInARenoglobalOperation(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/update-an-operation-in-a-renovation-global-operation`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateAssistanceContractAmo(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/update-assistant-contract-amo`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function createQuotationAdvancePayments(quotationUUID, advancePayments) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/create-advance-payments`, advancePayments, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateQuotationAdvancePayment(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/update-advance-payments`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function deleteQuotationAdvancePayment(quotationUUID, advancePaymentUUID) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/delete-advance-payments`, {}, { headers: getAuthHeadersWithJSONContentType(), params: { advancePaymentUUID } });
}

async function sendBeneficiaryApplicationAccessEmail(quotationUUID, data) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/send-beneficiary-application-access-email`, data, { headers: getAuthHeadersWithJSONContentType() });
}

async function updateACategoryInARenoglobalOperation(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/update-a-category-in-a-renovation-global-operation`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function sendTimestampedPhotosDeeplinkEmail(quotationUUID, data) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/send-timestamped-photos-deeplink-email`, data, { headers: getAuthHeadersWithJSONContentType() });
}

async function getCreditFinancing(quotation) {
    return endpointV2.post(
        CREDIT_FINANCING_ROUTE,
        {
            ID: process.env.REACT_APP_BUDGETLYSS_ID,
            MONTANT: roundNumberToTwoDecimal(quotation.totalPriceToPay),
            AIDES: roundNumberToTwoDecimal(quotation.totalPriceToPay - quotation.totalNetPriceToPay),
        },
        { headers: getAuthHeadersWithJSONContentType() }
    );
}

export default {
    createQuotation,
    getQuotationsCount,
    getQuotations,
    getQuotation,
    patchQuotation,
    saveQuotation,
    saveQuotationAsDraft,
    deleteQuotation,
    getQuotationsAnalytics,
    checkQuotationInformationRedundancy,
    sendQuotationByEmail,
    refuseQuotation,
    resetQuotationContract,
    setQuotationSentToCustomer,
    updateFinancingOfRemainingCosts,
    duplicateQuotation,
    updateQuotationAudit,
    updateAProductInARenoglobalOperation,
    updateAnOperationInstallationDateInARenoglobalOperation,
    updateATechnicianInARenoglobalOperation,
    updateAnOperationInARenoglobalOperation,
    updateAssistanceContractAmo,
    createQuotationAdvancePayments,
    updateQuotationAdvancePayment,
    deleteQuotationAdvancePayment,
    sendBeneficiaryApplicationAccessEmail,
    updateACategoryInARenoglobalOperation,
    sendTimestampedPhotosDeeplinkEmail,
    getCreditFinancing,
};
