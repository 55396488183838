import { v1 as uuidV1 } from 'uuid';
import { getDocumentDownloadLink, getDocumentTitle, getInvoiceMessage, getInvoiceSubTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';

export const extractAdvancePaymentInvoice = (dossierCEE) => {
    const { advancePaymentInvoice } = dossierCEE;

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.ADVANCE_PAYMENT_INVOICE),
        documentType: dossierCEEDocumentTypes.ADVANCE_PAYMENT_INVOICE,
        subTitle: getInvoiceSubTitle(advancePaymentInvoice),
        available: isDocumentDownloadLinkAvailable(advancePaymentInvoice),
        fileDownloadUrl: getDocumentDownloadLink(advancePaymentInvoice),
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: getInvoiceMessage(advancePaymentInvoice),
        position: 3,
    };
};
