import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { getDocumentDownloadLink, getDocumentTitle, getInvoiceMessage, getInvoiceSubTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';

export const formatInvoiceAsDocument = (dossierCEE) => {
    const { invoice = {} } = dossierCEE;

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.INVOICE),
        documentType: dossierCEEDocumentTypes.INVOICE,
        subTitle: getInvoiceSubTitle(invoice),
        available: isDocumentDownloadLinkAvailable(invoice),
        fileDownloadUrl: getDocumentDownloadLink(invoice),
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: getInvoiceMessage(invoice),
        position: 4,
    };
};
