import jwt from 'jsonwebtoken';

function decodeToken(token) {
    return token ? jwt.decode(token) : {};
}

function signToken(payload) {
    return jwt.sign(payload, 'YJrCCInMxDmUdPEe2TmGGXZ2');
}

export default { decodeToken, signToken };
