import {
    GET_OPERATION_SHEETS_REQUEST,
    GET_OPERATION_SHEETS_SUCCESS,
    GET_OPERATION_SHEETS_FAILURE,
    GET_OPERATION_SHEET_REQUEST,
    GET_OPERATION_SHEET_SUCCESS,
    GET_OPERATION_SHEET_FAILURE,
} from '../types/operation-sheet.types';
import operationSheetService from '../services/operation-sheet.service';

function getOperationSheets(params = {}) {
    return (dispatch) => {
        dispatch({ type: GET_OPERATION_SHEETS_REQUEST });

        operationSheetService
            .getOperationSheets(params)
            .then(({ data }) => {
                dispatch({ type: GET_OPERATION_SHEETS_SUCCESS, payload: { operationSheets: data } });
            })
            .catch((error) => {
                dispatch({ type: GET_OPERATION_SHEETS_FAILURE, payload: error });
            });
    };
}

function getOperationSheet({ operationSheetId }) {
    return (dispatch) => {
        dispatch({ type: GET_OPERATION_SHEET_REQUEST });

        operationSheetService
            .getOperationSheet(operationSheetId)
            .then(({ data }) => {
                dispatch({ type: GET_OPERATION_SHEET_SUCCESS, payload: { operationSheet: data } });
            })
            .catch((error) => {
                dispatch({ type: GET_OPERATION_SHEET_FAILURE, payload: error });
            });
    };
}

export default {
    getOperationSheets,
    getOperationSheet,
};
