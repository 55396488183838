import styled from 'styled-components';

export const StyledDossierCEEBlockAddButton = styled.div`
    position: absolute;
    bottom: 0;
    right: -10px;
    button {
        border-radius: 50%;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 20px;
        main > span.material-icons {
            margin-right: 0;
        }
    }
`;
