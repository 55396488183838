import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button } from '@renolib/renolib-ui-kit';

import uploadDocumentHelper from '../../utils/upload-document-helper';

const UnstyledUploadMultipleFiles = ({ className, formState, onChange, updateFormState }) => {
    const fileInputRef = useRef();
    const removeButtonRef = useRef(false);

    function handleChangeFile(files) {
        const filteredFiles = Array.from(files).filter((file) => !formState.files.find((item) => item.file.name === file.name));

        const filesData = Array.from(filteredFiles).map((file) => {
            const { fileName, fileType } = uploadDocumentHelper.extractFileNameAndFileType(file);
            return { fileName, fileType, file };
        });

        updateFormState({ files: [...formState.files, ...filesData] });
    }

    function handleClick() {
        fileInputRef.current.click();
    }

    function removeFile({ name }) {
        removeButtonRef.current = true;
        const files = [...formState.files];
        const updatedFiles = files.filter((item) => item.file.name !== name);

        updateFormState({ files: [...updatedFiles], file: updatedFiles[0].file });
    }

    useEffect(() => {
        if (!updateFormState) return;
        if (!formState?.files) updateFormState({ files: [{ fileName: formState.fileName, fileType: formState.fileType, file: formState.file }] });
    }, [updateFormState, formState]);

    return (
        <div className={className}>
            <div>
                <ul>
                    {formState?.files &&
                        Array.from(formState.files).map((item) => {
                            return (
                                <li key={item?.file?.name} onClick={() => onChange({ name: 'file', value: item.file })}>
                                    {formState.files.length > 1 && (
                                        <span
                                            className='close'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeFile({ name: item?.file?.name });
                                            }}>
                                            X
                                        </span>
                                    )}
                                    <span className='name'>{item.fileName}</span>
                                </li>
                            );
                        })}
                </ul>
            </div>
            <input ref={fileInputRef} type='file' onChange={(e) => handleChangeFile(e.target.files)} multiple />
            <Button variant='outline' icon='upload' iconStyle='outline' type='button' onClick={handleClick}>
                Ajouter une photo
            </Button>
        </div>
    );
};

const StyledUploadMultipleFiles = styled(UnstyledUploadMultipleFiles)`
    > div {
        overflow: auto;
        padding-top: 10px;
        > ul {
            display: flex;
            gap: 10px;
            > li {
                position: relative;
                padding: 6px 10px;
                border: 1px solid #1d3495;
                border-radius: 4px;
                width: max-content;
                white-space: nowrap;
                cursor: pointer;
                > .close {
                    color: red;
                    position: absolute;
                    top: -7px;
                    right: -5px;
                    font-size: 15px !important;
                }
                > .name {
                    color: #1d3495;
                }
            }
        }
    }
    input[type='file'] {
        display: none;
    }
`;

function UploadMultipleFiles(props) {
    return <StyledUploadMultipleFiles {...props} />;
}

export default UploadMultipleFiles;
