export const CUSTOMER_CREATED = 'Votre client a bien été enregistré';

export const NO_CUSTOMERS = 'Aucun compte client n’a été ajouté.';
export const NO_CUSTOMERS_PP = 'Aucun compte client particulier n’a été ajouté.';
export const NO_CUSTOMERS_PM = 'Aucun compte client professionnel n’a été ajouté.';
export const CUSTOMER_UPDATED = 'Votre fiche client a bien été mise à jour';

export const TITLE_ADD_COMMENT = 'Ajouter un commentaire';
export const BUTTON_ADD_COMMENT = 'Ajouter un commentaire';

export const NO_COMMENTS = "Vous n'avez pas encore enregistré de commentaires.";
export const DISPLAY_COMMENTS = 'Retrouvez les commentaires que vous avez précédemment enregistrés.';
export const COMMENTS_HISTORY = 'Historique des commentaires';
export const CONFIRM_COMMENT = 'Votre commentaire a bien été enregistré.';
export const DELETE_COMMENT = 'Votre commentaire a bien été supprimé.';

export const MAILS_HISTORY = 'Historique de vos mails';
export const NO_MAILS_SENT = 'Aucun email envoyé pour le moment';
export const DISPLAY_MAILS = "Consulter en détails les mails envoyés depuis l'application RÉNOLIB.";
