import {
    LABEL_REGULATION_SYSTEM_CENTRAL_SYSTEM_INCLUDES_API,
    LABEL_REGULATION_SYSTEM_CLASS,
    LABEL_REGULATION_SYSTEM_EQUIPMENT_CAN_HELP_DEROGATE_FROM_INSTRUCTIONS,
    LABEL_REGULATION_SYSTEM_INCLUDES_CENTRAL_SYSTEM,
    LABEL_REGULATION_SYSTEM_INCLUDES_PROBE,
    LABEL_REGULATION_SYSTEM_INCLUDES_REGULATOR,
    LABEL_REGULATION_SYSTEM_RUNS_OFFLINE,
    LABEL_REGULATION_SYSTEM_SETTINGS_ARE_LOCALLY_UPDATABLE,
    LABEL_REGULATION_SYSTEM_SIGNAL_ORIGIN,
} from '../../operation-constants';

export default [
    {
        property: LABEL_REGULATION_SYSTEM_CLASS,
        label: LABEL_REGULATION_SYSTEM_CLASS,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_REGULATION_SYSTEM_SIGNAL_ORIGIN,
        label: LABEL_REGULATION_SYSTEM_SIGNAL_ORIGIN,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_REGULATION_SYSTEM_INCLUDES_CENTRAL_SYSTEM,
        label: LABEL_REGULATION_SYSTEM_INCLUDES_CENTRAL_SYSTEM,
        type: 'boolean',
        required: true,
    },
    {
        property: LABEL_REGULATION_SYSTEM_INCLUDES_PROBE,
        label: LABEL_REGULATION_SYSTEM_INCLUDES_PROBE,
        type: 'boolean',
        required: true,
    },
    {
        property: LABEL_REGULATION_SYSTEM_INCLUDES_REGULATOR,
        label: LABEL_REGULATION_SYSTEM_INCLUDES_REGULATOR,
        type: 'boolean',
        required: true,
    },
    {
        property: LABEL_REGULATION_SYSTEM_SETTINGS_ARE_LOCALLY_UPDATABLE,
        label: LABEL_REGULATION_SYSTEM_SETTINGS_ARE_LOCALLY_UPDATABLE,
        type: 'boolean',
        required: true,
    },
    {
        property: LABEL_REGULATION_SYSTEM_EQUIPMENT_CAN_HELP_DEROGATE_FROM_INSTRUCTIONS,
        label: LABEL_REGULATION_SYSTEM_EQUIPMENT_CAN_HELP_DEROGATE_FROM_INSTRUCTIONS,
        type: 'boolean',
        required: true,
    },
    {
        property: LABEL_REGULATION_SYSTEM_RUNS_OFFLINE,
        label: LABEL_REGULATION_SYSTEM_RUNS_OFFLINE,
        type: 'boolean',
        required: true,
    },
    {
        property: LABEL_REGULATION_SYSTEM_CENTRAL_SYSTEM_INCLUDES_API,
        label: LABEL_REGULATION_SYSTEM_CENTRAL_SYSTEM_INCLUDES_API,
        type: 'boolean',
        required: true,
    },
];
