import { THERMAL_COEFFICIENT_UD } from '../../form_labels';

export default [
    {
        property: THERMAL_COEFFICIENT_UD,
        label: THERMAL_COEFFICIENT_UD,
        type: 'number',
        required: true,
    },
];
