import { v1 as uuidV1 } from 'uuid';
import { getDocumentDownloadLink, isDocumentDownloadLinkAvailable } from '../utils/utils';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';

export const formatMovingCertificateDocument = (dossierCEE) => {
    const { invoice } = dossierCEE;
    const { movingCertificate } = invoice;

    return {
        uuid: uuidV1(),
        title: 'Attestation de déménagement',
        available: isDocumentDownloadLinkAvailable(movingCertificate),
        fileDownloadUrl: getDocumentDownloadLink(movingCertificate),
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: 'Finalisée',
        position: 12,
        documentType: dossierCEEDocumentTypes.MOVING_CERTIFICATE,
    };
};
