import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes, dossierCEEStatuses, invoiceStatuses, otherDocumentTypes, primeDocumentTypes, uploadDocumentTypes } from '../../enums';
import {
    AUDIT_AFTER_RENOVATION,
    AUDIT_BEFORE_RENOVATION,
    COMMISSIONING_REPORT,
    DOSSIER_CEE_ADVANCE_PAYMENT_INVOICE_LABEL,
    DOSSIER_CEE_CADASTRAL_PLOT_LABEL,
    DOSSIER_CEE_CADRE_CONTRIBUTION_LABEL,
    DOSSIER_CEE_CLAIM_ASSIGNMENT,
    DOSSIER_CEE_DOMICILIATION_CERTIFICATE_LABEL,
    DOSSIER_CEE_GREEN_FLAME_LABEL_SEVEN,
    DOSSIER_CEE_INVOICE_LABEL,
    DOSSIER_CEE_OTHER_DOCUMENT_LABEL,
    DOSSIER_CEE_POST_RENOVATION_REPORT_LABEL,
    DOSSIER_CEE_QUOTATION_LABEL,
    DOSSIER_CEE_SITE_PHOTO_LABEL,
    DOSSIER_CEE_SITE_PHOTO_LABEL_AFTER_RENOVATION,
    DOSSIER_CEE_SITE_PHOTO_LABEL_BEFORE_RENOVATION,
    DOSSIER_CEE_SIZING_NOTE_LABEL,
    DOSSIER_CEE_SOLAR_CERTIFICATION_LABEL,
    DOSSIER_CEE_SOLAR_FACTOR_CALCULATION_NOTE_LABEL,
    DOSSIER_CEE_SWORN_STATEMENT_LABEL,
    DOSSIER_CEE_TECHNICAL_SHEET,
    DPE_OR_AUDIT_ENERGETIC,
    INVALID_DOSSIER_CEE_INVOICE,
    INVALID_DOSSIER_CEE_INVOICE_TO_FINALIZE,
    MAPRIME_RENOV_CERTIFICATE,
    POST_RENOVATION_CONTROL_REPORT,
    RENOVATION_AMPLEUR_ATTESTATION_INVOICE,
    RENOVATION_AMPLEUR_ATTESTATION_QUOTATION,
    RENOVATION_GLOBAL_ADVISED_OPERATION_LIST,
    RENOVATION_GLOBAL_AMO_REFUSE,
    RENOVATION_GLOBAL_AUDIT,
    RENOVATION_GLOBAL_AUDIT_QUOTATION_TRANSLATION,
    RENOVATION_GLOBAL_COFRAC_AFTER_WORK,
    RENOVATION_GLOBAL_COFRAC_BEFORE_WORK,
    RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT,
    RENOVATION_GLOBAL_TECHNICIANS_LIST,
    SUMMARY_STATEMENT,
    TECHNICAL_SHEET,
    VALID_DOSSIER_CEE_INVOICE,
    VALID_DOSSIER_CEE_INVOICE_TO_SEND,
    TIME_STAMPED_PHOTO,
    DOSSIER_CEE_VAT_CERTIFICATE,
    DOSSIER_CEE_SIGNATURE_CERTIFICATE,
} from '../../messages';
import { isNonEmptyObject, isValidValue } from '../../index';
import { LABEL_INSTALLED_EQUIPMENT_COUNT, LABEL_REGULATION_SYSTEM_INSTALLED_ON_AIR_AIR_SYSTEM } from '../../operation-constants';

export const getDocumentTitle = (documentType) => {
    const documentTypeLabels = {
        [dossierCEEDocumentTypes.QUOTATION]: DOSSIER_CEE_QUOTATION_LABEL,
        [dossierCEEDocumentTypes.CADRE_CONTRIBUTION]: DOSSIER_CEE_CADRE_CONTRIBUTION_LABEL,
        [dossierCEEDocumentTypes.INVOICE]: DOSSIER_CEE_INVOICE_LABEL,
        [dossierCEEDocumentTypes.POST_RENOVATION_REPORT]: DOSSIER_CEE_POST_RENOVATION_REPORT_LABEL,
        [dossierCEEDocumentTypes.SIZING_NOTE]: DOSSIER_CEE_SIZING_NOTE_LABEL,
        [dossierCEEDocumentTypes.SWORN_STATEMENT]: DOSSIER_CEE_SWORN_STATEMENT_LABEL,
        [dossierCEEDocumentTypes.CADASTRAL_PLOT]: DOSSIER_CEE_CADASTRAL_PLOT_LABEL,
        [dossierCEEDocumentTypes.SITE_PHOTO]: DOSSIER_CEE_SITE_PHOTO_LABEL,
        [dossierCEEDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION]: DOSSIER_CEE_SITE_PHOTO_LABEL_BEFORE_RENOVATION,
        [dossierCEEDocumentTypes.SITE_PHOTO_AFTER_RENOVATION]: DOSSIER_CEE_SITE_PHOTO_LABEL_AFTER_RENOVATION,
        [dossierCEEDocumentTypes.SOLAR_FACTOR_CALCULATION_NOTE]: DOSSIER_CEE_SOLAR_FACTOR_CALCULATION_NOTE_LABEL,
        [dossierCEEDocumentTypes.OTHER]: DOSSIER_CEE_OTHER_DOCUMENT_LABEL,
        [dossierCEEDocumentTypes.SOLAR_CERTIFICATION]: DOSSIER_CEE_SOLAR_CERTIFICATION_LABEL,
        [dossierCEEDocumentTypes.DOMICILIATION_CERTIFICATE]: DOSSIER_CEE_DOMICILIATION_CERTIFICATE_LABEL,
        [dossierCEEDocumentTypes.GREEN_FLAME_LABEL_SEVEN]: DOSSIER_CEE_GREEN_FLAME_LABEL_SEVEN,
        [dossierCEEDocumentTypes.TECHNICAL_SHEET]: DOSSIER_CEE_TECHNICAL_SHEET,
        [dossierCEEDocumentTypes.ADVANCE_PAYMENT_INVOICE]: DOSSIER_CEE_ADVANCE_PAYMENT_INVOICE_LABEL,
        [dossierCEEDocumentTypes.CLAIM_ASSIGNMENT]: DOSSIER_CEE_CLAIM_ASSIGNMENT,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_AUDIT]: RENOVATION_GLOBAL_AUDIT,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE]: RENOVATION_GLOBAL_AMO_REFUSE,
        [dossierCEEDocumentTypes.COMMISSIONING_REPORT]: COMMISSIONING_REPORT,
        [dossierCEEDocumentTypes.AUDIT_BEFORE_RENOVATION]: AUDIT_BEFORE_RENOVATION,
        [dossierCEEDocumentTypes.AUDIT_AFTER_RENOVATION]: AUDIT_AFTER_RENOVATION,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_AUDIT]: RENOVATION_GLOBAL_AUDIT,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE]: RENOVATION_GLOBAL_AMO_REFUSE,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_AFTER_WORK]: RENOVATION_GLOBAL_COFRAC_AFTER_WORK,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_BEFORE_WORK]: RENOVATION_GLOBAL_COFRAC_BEFORE_WORK,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST]: RENOVATION_GLOBAL_ADVISED_OPERATION_LIST,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_TECHNICIANS]: RENOVATION_GLOBAL_TECHNICIANS_LIST,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT]: RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT,
        [dossierCEEDocumentTypes.RENOVATION_GLOBAL_AUDIT_QUOTATION]: RENOVATION_GLOBAL_AUDIT_QUOTATION_TRANSLATION,
        [dossierCEEDocumentTypes.TECHNICAL_SHEET]: TECHNICAL_SHEET,
        [primeDocumentTypes.MAPRIME_RENOV_CERTIFICATE]: MAPRIME_RENOV_CERTIFICATE,
        [primeDocumentTypes.RENOVATION_AMPLEUR_ATTESTATION_INVOICE]: RENOVATION_AMPLEUR_ATTESTATION_INVOICE,
        [primeDocumentTypes.RENOVATION_AMPLEUR_ATTESTATION_QUOTATION]: RENOVATION_AMPLEUR_ATTESTATION_QUOTATION,
        [primeDocumentTypes.SUMMARY_STATEMENT]: SUMMARY_STATEMENT,
        [primeDocumentTypes.POST_RENOVATION_CONTROL_REPORT]: POST_RENOVATION_CONTROL_REPORT,
        [primeDocumentTypes.DPE_OR_ENERGETIC_AUDIT]: DPE_OR_AUDIT_ENERGETIC,
        [primeDocumentTypes.TIME_STAMPED_PHOTO]: TIME_STAMPED_PHOTO,
        [primeDocumentTypes.VAT_CERTIFICATE]: DOSSIER_CEE_VAT_CERTIFICATE,
        [primeDocumentTypes.DS_SIGNATURE_CERTIFICATE]: DOSSIER_CEE_SIGNATURE_CERTIFICATE,
    };

    return documentTypeLabels[documentType];
};

export const isDocumentDownloadLinkAvailable = (document) => {
    return isNonEmptyObject(document) && isValidValue(document.fileDownloadUrl);
};

export const getDocumentDownloadLink = (document) => {
    return document?.fileDownloadUrl || '';
};

export const getDocumentFileTypeFromDownloadUrl = (document) => {
    return getDocumentDownloadLink(document).includes('.pdf') ? dossierCEESidebarDocumentFileTypes.PDF : dossierCEESidebarDocumentFileTypes.IMAGE;
};

export const getInvoiceSubTitle = (invoice) => {
    if (invoice.status === invoiceStatuses.DRAFT) return INVALID_DOSSIER_CEE_INVOICE_TO_FINALIZE;
    return invoice.status === invoiceStatuses.FINALIZED ? VALID_DOSSIER_CEE_INVOICE_TO_SEND : VALID_DOSSIER_CEE_INVOICE;
};

export const getInvoiceMessage = (invoice) => {
    return invoice.fileDownloadUrl && invoice.status === invoiceStatuses.DRAFT ? INVALID_DOSSIER_CEE_INVOICE_TO_FINALIZE : INVALID_DOSSIER_CEE_INVOICE;
};

export const isOtherDocumentType = ({ documentType }) => {
    return documentType === dossierCEEDocumentTypes.OTHER;
};

export const isSitePhotoDocument = ({ documentType, fileType }) => {
    return documentType === dossierCEEDocumentTypes.SITE_PHOTO && fileType === dossierCEESidebarDocumentFileTypes.IMAGE;
};

export const isSitePhotoBeforeRenovationDocument = ({ documentType, fileType }) => {
    return documentType === uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION && fileType === dossierCEESidebarDocumentFileTypes.IMAGE;
};

export const isSitePhotoAfterRenovationDocument = ({ documentType, fileType }) => {
    return documentType === uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION && fileType === dossierCEESidebarDocumentFileTypes.IMAGE;
};

function isDocumentAProductDocument(document) {
    return [otherDocumentTypes.SOLAR_CERTIFICATION].includes(document.documentType);
}

function isDocumentIncludedAsMandatoryInDossierCEE(dossierCEE, document) {
    return dossierCEE.mandatoryDocuments.findIndex(({ type }) => type === document.documentType) !== -1;
}

export const getSitePhotosRenovationFileDownloadUrls = (uploadDocuments = []) => {
    if (!uploadDocuments.length) return [];

    return uploadDocuments.length > 1 ? uploadDocuments.map(({ sitePhotos }) => sitePhotos).flat() : uploadDocuments[0].sitePhotos;
};

export const isSitePhotoMandatoryDocument = (mandatoryDocuments) => {
    const allSitePhotos = [dossierCEEDocumentTypes.SITE_PHOTO, dossierCEEDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION, dossierCEEDocumentTypes.SITE_PHOTO_AFTER_RENOVATION];
    const mandatoryDocumentFound = mandatoryDocuments.filter(({ type }) => allSitePhotos.includes(type));

    return mandatoryDocumentFound?.length > 0;
};

export const isDocumentDownloadLinkOrUnsignedFileDownloadUrlAvailable = (document) => {
    return isNonEmptyObject(document) && (isValidValue(document.unsignedFileDownloadUrl) || isValidValue(document.fileDownloadUrl));
};

export const getDocumentDownloadLinkAvailableOrUnsignedFileDownloadUrl = (document) => {
    return document?.fileDownloadUrl || document?.unsignedFileDownloadUrl || '';
};

export const getDocumentFileTypeFromDownloadUrlOrUnsignedFileDownloadUrl = (document) => {
    return getDocumentDownloadLinkAvailableOrUnsignedFileDownloadUrl(document).includes('.pdf') ? dossierCEESidebarDocumentFileTypes.PDF : dossierCEESidebarDocumentFileTypes.IMAGE;
};

export const formatSitePhotosBeforeRenovationLabel = (count = 1) => {
    return DOSSIER_CEE_SITE_PHOTO_LABEL_BEFORE_RENOVATION.concat(` (${count})`);
};

export const isDocumentDeletable = (dossierCEE) => {
    return [dossierCEEStatuses.PROCESSING, dossierCEEStatuses.COMPLETED].includes(dossierCEE.status);
};

export const getDownloadUrl = (doc) => doc?.fileDownloadUrl || doc?.unsignedFileDownloadUrl;

export const isDocumentShownUsingAnotherMethod = (dossierCEE, document) => {
    return (
        isSitePhotoDocument(document) ||
        isSitePhotoBeforeRenovationDocument(document) ||
        isSitePhotoAfterRenovationDocument(document) ||
        isDocumentIncludedAsMandatoryInDossierCEE(dossierCEE, document) ||
        isOtherDocumentType(document) ||
        isDocumentAProductDocument(document)
    );
};

export function formatFormLabel(label) {
    return (
        {
            [LABEL_REGULATION_SYSTEM_INSTALLED_ON_AIR_AIR_SYSTEM]: LABEL_REGULATION_SYSTEM_INSTALLED_ON_AIR_AIR_SYSTEM.concat(' (ex: pompe à chaleur air/air)'),
            [LABEL_INSTALLED_EQUIPMENT_COUNT]: LABEL_INSTALLED_EQUIPMENT_COUNT.concat(
                ' (robinet thermostatique, tête électronique ou actionneur installé sur chaque émetteur de chaleur dans le cas de radiateurs électriques)'
            ),
        }[label] || label
    );
}
