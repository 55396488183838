import { authHeaders, endpointV2, getAuthHeadersWithJSONContentType } from '../../api';
import storage from '../../utils/storage';

function getBaseUrl() {
    return `/organizations/${storage.getOrganizationUUID()}/renovation-addresses`;
}

async function createRenovationAddress(customerId, renovationAddressData, customerActivity) {
    return endpointV2.post(
        getBaseUrl(),
        { renovationAddressData, customerActivity },
        {
            headers: getAuthHeadersWithJSONContentType(),
            params: {
                customerId,
            },
        }
    );
}

async function updateRenovationAddress(renovationAddressUUID, renovationAddressUpdateData) {
    return endpointV2.put(`${getBaseUrl()}/${renovationAddressUUID}`, renovationAddressUpdateData, {
        headers: getAuthHeadersWithJSONContentType(),
    });
}

async function updateRenovationAddressDPE(renovationAddressUUID, renovationAddressUpdateData) {
    return endpointV2.put(`${getBaseUrl()}/${renovationAddressUUID}/update-dpe`, renovationAddressUpdateData, {
        headers: getAuthHeadersWithJSONContentType(),
    });
}
async function deleteRenovationAddress(renovationAddressUUID, accountUUID) {
    return endpointV2.delete(`${getBaseUrl()}/${renovationAddressUUID}`, {
        headers: authHeaders(),
        params: {
            uuidAccount: accountUUID,
        },
    });
}

async function patchRenovationAddress(renovationAddressUUID, path, operation, value) {
    return endpointV2.patch(
        `${getBaseUrl()}/${renovationAddressUUID}`,
        { path, op: operation, value },
        {
            headers: getAuthHeadersWithJSONContentType(),
        }
    );
}

async function getQPVs(postalCode) {
    return endpointV2.get(`${getBaseUrl()}/qpvs`, {
        headers: authHeaders(),
        params: { postalCode },
    });
}

async function getCustomerRenovationAddresses(customerId, customerType) {
    return endpointV2.get(getBaseUrl(), {
        headers: authHeaders(),
        params: { customerId, customerType },
    });
}

export default {
    createRenovationAddress,
    updateRenovationAddress,
    deleteRenovationAddress,
    patchRenovationAddress,
    getQPVs,
    getCustomerRenovationAddresses,
    updateRenovationAddressDPE,
};
