import { GET_ENERGY_RENOVATION_SERVICES_SUCCESS } from '../types/energy-renovation-service.types';

function initState() {
    return {
        energyRenovationServices: [],
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case GET_ENERGY_RENOVATION_SERVICES_SUCCESS:
            return {
                ...state,
                energyRenovationServices: action.payload.energyRenovationServices,
            };

        default:
            return state;
    }
}
