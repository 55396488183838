import { GET_ALL_DASHBOARD_ANALYTICS_SUCCESS, GET_UNIQUE_DASHBOARD_ANALYTIC_SUCCESS } from '../types/dashboard.types';

const defaultState = {
    approvedQuotationsAnalytics: {
        currentWeek: 0,
        currentMonth: 0,
        currentYear: 0,
    },
    sentQuotationsAnalytics: {
        currentWeek: 0,
        currentMonth: 0,
        currentYear: 0,
    },
    invoicedOperationsAnalytics: {
        currentWeek: 0,
        currentMonth: 0,
        currentYear: 0,
    },
    paidOperationsAnalytics: {
        currentWeek: 0,
        currentMonth: 0,
        currentYear: 0,
    },
    customerPMsCount: 0,
    customerPPsCount: 0,
};
function initState() {
    return {
        analytics: defaultState,
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case GET_ALL_DASHBOARD_ANALYTICS_SUCCESS: {
            return {
                ...state,
                analytics: action.payload.analytics,
            };
        }
        case GET_UNIQUE_DASHBOARD_ANALYTIC_SUCCESS: {
            const { analyticType, analytic } = action.payload;
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    [analyticType]: analytic,
                },
            };
        }
        default:
            return state;
    }
}
