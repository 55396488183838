import { getOperationOfRenovationGlobalIndex } from '../../renovation-global-helper';
import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { getDocumentTitle } from '../utils/utils';

export const formatRenovationGlobalAuditAsDocument = (dossierCEE) => {
    const { quotation } = dossierCEE;
    const renovationGlobalIndex = getOperationOfRenovationGlobalIndex(quotation);
    const { audit } = quotation.operations[renovationGlobalIndex].renovationGlobal;

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_AUDIT),
        subTitle: '',
        documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_AUDIT,
        available: !!audit?.auditDownloadUrl,
        fileDownloadUrl: audit?.auditDownloadUrl,
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: undefined,
        valid: !!audit?.auditDownloadUrl,
        position: 3,
    };
};
