import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from '@renolib/renolib-ui-kit';

import history from '../../utils/history';
import dimensions from '../../config/dimensions';
import colors from '../../config/colors';

import ContainerSpace from './ContainerSpace';
import useOnboarding from '../hooks/useOnboarding';

function UnStyledContainer({ className, children, backButtonTitle, backUrl, renderEndButton }) {
    function handleBackButtonClicked() {
        if (backUrl?.includes('https://') || backUrl?.includes('http://')) {
            window.location.href = `${backUrl}`;
            return;
        }
        if (backUrl) {
            history.push(backUrl);
            return;
        }

        history.goBack();
    }

    const { visible } = useOnboarding();

    return (
        <div className={className}>
            {backButtonTitle && (
                <header className={`${visible ? 'visible' : ''}`}>
                    <div onClick={handleBackButtonClicked}>
                        <Icon name='keyboard_backspace' size='sm' color='#757A8C' />
                        <span>{backButtonTitle}</span>
                    </div>
                    {renderEndButton && renderEndButton()}
                </header>
            )}
            <main>{children}</main>
        </div>
    );
}

const StyledContainer = styled(UnStyledContainer)`
    padding: ${getPadding};
    position: relative;

    > header {
        position: ${getPosition};
        top: ${getTop};
        left: ${getLeft};
        right: ${getRight};
        height: ${dimensions.container.header.height};
        display: flex;
        align-items: center;
        justify-content: ${getJustifyContent};
        background-color: ${colors.container};
        margin-left: ${getMarginLeft};
        padding-left: ${getPaddingLeft};
        margin-right: ${getMarginRight};
        z-index: 2;

        &.visible {
            top: calc(${getTop} + ${dimensions.onboardingBar.height});
        }

        > div {
            display: flex;
            align-items: center;
            color: #757a8c;
            transition: all 200ms ease-in-out;
            cursor: pointer;

            :hover {
                color: rgba(181, 185, 202, 0.7);
            }

            > span {
                font-size: 11px;
                font-weight: 500;
                margin-left: 0.5rem;
            }
        }
    }
`;

export default function Container({ variant = 'default', ...restProps }) {
    return <StyledContainer variant={variant} {...restProps} />;
}
Container.Space = ContainerSpace;
Container.propTypes = {
    variant: PropTypes.oneOf(['default', 'fixed']),
    backButtonTitle: PropTypes.string,
    backUrl: PropTypes.string,
};

function getJustifyContent({ alignment }) {
    if (!alignment) return;
    return { around: 'space-around', between: 'space-between' }[alignment];
}
function getPadding({ variant, padding }) {
    return { default: '2.5rem', fixed: '0 2.5rem 2.5rem 2.5rem', big: '0rem 15rem 0rem' }[padding || variant];
}

function getPosition({ variant }) {
    return { default: 'absolute', fixed: 'sticky' }[variant];
}

function getTop({ variant }) {
    return { default: 0, fixed: dimensions.navbar.height }[variant];
}

function getLeft({ variant }) {
    return { default: '2.5rem', fixed: 0 }[variant];
}

function getRight({ variant }) {
    return { default: 0, fixed: 0 }[variant];
}

function getMarginLeft({ variant }) {
    return { default: 'initial', fixed: '-10px' }[variant];
}

function getPaddingLeft({ variant }) {
    return { default: 'initial', fixed: '10px' }[variant];
}

function getMarginRight({ variant }) {
    return { default: 'initial', fixed: '-10px' }[variant];
}
