import { useCallback, useEffect, useState } from 'react';
import L from 'leaflet';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { useParcelleCadastre } from './useParcelles';

export const useLeafletParcelles = ({ codeInsee, coordinates, map }) => {
    const { parcelles, feuilles, onSelectFeuille } = useParcelleCadastre(codeInsee);
    // eslint-disable-next-line no-unused-vars
    const [layers, setLayers] = useState();

    const displayCadastreNumber = (feature, layer) => {
        layer.bindTooltip(`${feature.properties.numero}`, { permanent: true, sticky: false, offset: [0, 0], className: 'map-cadastralNumber' });
    };

    const createGeoJSON = useCallback(
        (geometry, style, onClickFeature, onEach) => {
            const properties = geometry.properties;
            return L.geoJSON(geometry, {
                style,
                onEachFeature: (feature, layer) => {
                    const point = [coordinates.longitude, coordinates.latitude];
                    if (onClickFeature) {
                        const isInside = booleanPointInPolygon(point, feature);
                        if (isInside) {
                            onClickFeature(properties, geometry.geometry);
                        }
                    }
                    if (onEach) onEach(feature, layer);
                    if (onClickFeature) {
                        layer.on({
                            click: (e) => onClickFeature(properties, geometry.geometry),
                        });
                    }
                },
            });
        },
        [coordinates]
    );

    useEffect(() => {
        if (!map?.current || !parcelles) return;

        const myStyle = {
            color: '#ff7800',
            weight: 5,
            opacity: 0.65,
        };

        const allGeoJSON = parcelles.map((parcelle) => createGeoJSON(parcelle, myStyle, undefined, displayCadastreNumber));
        setLayers((previousLayers) => {
            if (!previousLayers) {
                const group = L.layerGroup(allGeoJSON);
                group.addTo(map.current);
                return group;
            }

            previousLayers.eachLayer(function (layer) {
                previousLayers.removeLayer(layer);
            });
            const group = L.layerGroup(allGeoJSON);
            group.addTo(map.current);
            return group;
        });
    }, [map, parcelles, createGeoJSON]);

    useEffect(() => {
        if (!map?.current || !feuilles) return;

        const myStyle = {
            color: '#283f63',
            weight: 5,
            opacity: 0.65,
        };

        feuilles.forEach((feuille) => {
            const p = createGeoJSON(feuille, myStyle, onSelectFeuille);
            p.addTo(map.current);
        });
    }, [map, feuilles, onSelectFeuille, createGeoJSON]);

    return { parcelles };
};
