import { isNonEmptyObject } from '../../index';
import { v1 as uuidV1 } from 'uuid';
import { getDocumentDownloadLink, getDocumentFileTypeFromDownloadUrl, getDocumentTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';
import { dossierCEEDocumentTypes } from '../../enums';
import { DOSSIER_CEE_AH_EDF_MESSAGE, INVALID_DOSSIER_CEE_SWORN_STATEMENT } from '../../messages';

function getSwornStatementDocumentConfiguration({ contract, swornStatement, allOperationsAreNotCompliant, dossierCEE }) {
    // move this to setDocumentComponent
    const config = {
        552081317: {
            UNSIGNED: {
                uuid: uuidV1(),
                title: getDocumentTitle(dossierCEEDocumentTypes.SWORN_STATEMENT),
                available: true,
                documentType: dossierCEEDocumentTypes.SWORN_STATEMENT,
                fileDownloadUrl: swornStatement.status === 'SIGNED' ? getDocumentDownloadLink(swornStatement) : '',
                message: DOSSIER_CEE_AH_EDF_MESSAGE,
                position: 7,
                render: 'EDFRender',
            },
        },
    };

    const swornStatementConfigSiren = config[`${contract?.delegateeSiren}`];
    const swornStatementConfig = swornStatementConfigSiren ? swornStatementConfigSiren[swornStatement.status] : undefined;
    if (swornStatementConfig) return swornStatementConfig;

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.SWORN_STATEMENT),
        available: isDocumentDownloadLinkAvailable(swornStatement) && !allOperationsAreNotCompliant,
        fileDownloadUrl: getDocumentDownloadLink(swornStatement),
        documentType: dossierCEEDocumentTypes.SWORN_STATEMENT,
        fileType: getDocumentFileTypeFromDownloadUrl(swornStatement),
        message: INVALID_DOSSIER_CEE_SWORN_STATEMENT,
        position: 7,
    };
}

export const formatSwornStatementAsDocument = (dossierCEE, { allOperationsAreNotCompliant }) => {
    const { quotation, invoice } = dossierCEE;

    const { swornStatement } = isNonEmptyObject(invoice) ? invoice : quotation;

    return getSwornStatementDocumentConfiguration({ contract: quotation?.contract, swornStatement, allOperationsAreNotCompliant, dossierCEE });
};
