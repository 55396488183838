import { v1 as uuidV1 } from 'uuid';
import { getDocumentDownloadLink, getDocumentTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';
import { MANDATORY_DOSSIER_CEE_DOCUMENT } from '../../messages';

export const formatUploadDocument = (dossierCEE, documentType) => {
    const { uploadDocuments = [] } = dossierCEE;
    const uploadDocument = uploadDocuments.find((uploadDocument) => uploadDocument.documentType === documentType) || {};

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(documentType),
        available: isDocumentDownloadLinkAvailable(uploadDocument),
        fileDownloadUrl: getDocumentDownloadLink(uploadDocument),
        fileType: uploadDocument?.fileType,
        message: MANDATORY_DOSSIER_CEE_DOCUMENT,
        documentType: documentType,
        position: 6,
    };
};
