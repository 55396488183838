import {
    CREATE_RENOVATION_ADDRESS_REQUEST,
    CREATE_RENOVATION_ADDRESS_SUCCESS,
    CREATE_RENOVATION_ADDRESS_FAILURE,
    UPDATE_RENOVATION_ADDRESS_REQUEST,
    UPDATE_RENOVATION_ADDRESS_SUCCESS,
    UPDATE_RENOVATION_ADDRESS_FAILURE,
    DELETE_RENOVATION_ADDRESS_REQUEST,
    DELETE_RENOVATION_ADDRESS_SUCCESS,
    DELETE_RENOVATION_ADDRESS_FAILURE,
    PATCH_RENOVATION_ADDRESS_REQUEST,
    PATCH_RENOVATION_ADDRESS_SUCCESS,
    PATCH_RENOVATION_ADDRESS_FAILURE,
    GET_QPVS_REQUEST,
    GET_QPVS_SUCCESS,
    GET_QPVS_FAILURE,
    GET_CUSTOMER_RENOVATION_ADDRESSES_REQUEST,
    GET_CUSTOMER_RENOVATION_ADDRESSES_SUCCESS,
    GET_CUSTOMER_RENOVATION_ADDRESSES_FAILURE,
    CLEAR_CUSTOMER_RENOVATION_ADDRESSES,
} from '../types/renovation-address.types';
import renovationAddressService from '../services/renovation-address.service';
import { parseError } from '../../api';
import formActions from './ui/form';
import dialogsActions from './ui/dialogs';
import customerPPActions from './customer-pp.actions';
import customerPMActions from './customer-pm.actions';
import { customerTypes } from '../../utils/enums';
import { RENOVATION_ADDRESS_CREATED, RENOVATION_ADDRESS_DELETED, RENOVATION_ADDRESS_UPDATED } from '../../utils/messages';

function refreshCustomerPP(dispatch, customerPPUUID) {
    dispatch(customerPPActions.getCustomerPP({ customerPPUUID }));
}

function refreshCustomerPM(dispatch, customerPMUUID) {
    dispatch(customerPMActions.getCustomerPM({ customerPMUUID }));
}

const refreshCustomerCallbacks = {
    [customerTypes.CUSTOMER_PP]: refreshCustomerPP,
    [customerTypes.CUSTOMER_PM]: refreshCustomerPM,
};

function handleNotifiableError(error, dispatch, actionType) {
    const errorMessage = parseError(error);
    dispatch(formActions.stopRequesting());
    dispatch({ type: actionType });
    dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
}

function createRenovationAddress({ customerId, customerUUID, customerType, customerActivity, renovationAddressData, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: CREATE_RENOVATION_ADDRESS_REQUEST });
        dispatch(formActions.startRequesting());

        return renovationAddressService
            .createRenovationAddress(customerId, { customerType, ...renovationAddressData }, customerActivity)
            .then(({ data }) => {
                onSuccess();
                dispatch({ type: CREATE_RENOVATION_ADDRESS_SUCCESS, payload: { renovationAddress: data } });
                refreshCustomerCallbacks[customerType](dispatch, customerUUID);
                dispatch(dialogsActions.showSuccessDialog({ message: RENOVATION_ADDRESS_CREATED }));
                dispatch(formActions.stopRequesting());
                return data;
            })
            .catch((error) => {
                handleNotifiableError(error, dispatch, CREATE_RENOVATION_ADDRESS_FAILURE);
                return error;
            });
    };
}

function updateRenovationAddress({ customerType, customerUUID, renovationAddressUUID, renovationAddressUpdateData, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: UPDATE_RENOVATION_ADDRESS_REQUEST });
        dispatch(formActions.startRequesting());

        renovationAddressService
            .updateRenovationAddress(renovationAddressUUID, renovationAddressUpdateData)
            .then(({ data }) => {
                if (onSuccess) onSuccess();
                dispatch({ type: UPDATE_RENOVATION_ADDRESS_SUCCESS, payload: { renovationAddress: data } });
                refreshCustomerCallbacks[customerType](dispatch, customerUUID);
                dispatch(dialogsActions.showSuccessDialog({ message: RENOVATION_ADDRESS_UPDATED }));
                dispatch(formActions.stopRequesting());
            })
            .catch((error) => {
                handleNotifiableError(error, dispatch, UPDATE_RENOVATION_ADDRESS_FAILURE);
            });
    };
}

function deleteRenovationAddress({ customerType, customerUUID, renovationAddressUUID }) {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_RENOVATION_ADDRESS_REQUEST });
        dispatch(formActions.startRequesting());
        const accountUUID = getState().auth.account.uuid;

        renovationAddressService
            .deleteRenovationAddress(renovationAddressUUID, accountUUID)
            .then(({ data }) => {
                dispatch({ type: DELETE_RENOVATION_ADDRESS_SUCCESS, payload: { renovationAddress: data } });
                refreshCustomerCallbacks[customerType](dispatch, customerUUID);
                dispatch(dialogsActions.showSuccessDialog({ message: RENOVATION_ADDRESS_DELETED }));
                dispatch(formActions.stopRequesting());
            })
            .catch((error) => {
                handleNotifiableError(error, dispatch, DELETE_RENOVATION_ADDRESS_FAILURE);
            });
    };
}

function patchRenovationAddress({ customerType, customerUUID, renovationAddressUUID, operation, path, value, successMessage }) {
    return (dispatch) => {
        dispatch({ type: PATCH_RENOVATION_ADDRESS_REQUEST });
        dispatch(formActions.startRequesting());

        renovationAddressService
            .patchRenovationAddress(renovationAddressUUID, path, operation, value)
            .then(({ data }) => {
                dispatch({ type: PATCH_RENOVATION_ADDRESS_SUCCESS, payload: { renovationAddress: data } });
                refreshCustomerCallbacks[customerType](dispatch, customerUUID);
                dispatch(dialogsActions.showSuccessDialog({ message: successMessage }));
                dispatch(formActions.stopRequesting());
            })
            .catch((error) => {
                handleNotifiableError(error, dispatch, PATCH_RENOVATION_ADDRESS_FAILURE);
            });
    };
}

function getQPVs({ postalCode }) {
    return (dispatch) => {
        dispatch({ type: GET_QPVS_REQUEST });

        renovationAddressService
            .getQPVs(postalCode)
            .then(({ data }) => {
                dispatch({ type: GET_QPVS_SUCCESS, payload: { qpvs: data } });
            })
            .catch((error) => {
                dispatch({ type: GET_QPVS_FAILURE, payload: error });
            });
    };
}

function getCustomerRenovationAddresses({ customerId, customerType }) {
    return (dispatch) => {
        dispatch({ type: GET_CUSTOMER_RENOVATION_ADDRESSES_REQUEST });

        renovationAddressService
            .getCustomerRenovationAddresses(customerId, customerType)
            .then(({ data }) => {
                dispatch({ type: GET_CUSTOMER_RENOVATION_ADDRESSES_SUCCESS, payload: { renovationAddresses: data } });
            })
            .catch((error) => {
                dispatch({ type: GET_CUSTOMER_RENOVATION_ADDRESSES_FAILURE, payload: error });
            });
    };
}

function clearCustomerRenovationAddresses() {
    return { type: CLEAR_CUSTOMER_RENOVATION_ADDRESSES };
}

export default {
    createRenovationAddress,
    updateRenovationAddress,
    deleteRenovationAddress,
    patchRenovationAddress,
    getQPVs,
    getCustomerRenovationAddresses,
    clearCustomerRenovationAddresses,
};
