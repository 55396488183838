import {
    LABEL_INDEX_COLOR_RENDERING,
    LABEL_LIGHT_IS_LED,
    LABEL_LIGHT_EFFICIENCY,
    LABEL_LUMEN,
    LABEL_LUMINARY_MODELE,
    LABEL_LUMINARY_TYPE,
    LABEL_OPTICAL_ASSEMBLY,
    LABEL_POWER_OF_THE_SYSTEM_W,
    LABEL_ULOR,
    LABEL_ULR,
    LABEL_REPLACE_LIGHT_SOURCE,
} from '../../product-constants';

export default [
    {
        property: LABEL_LUMINARY_MODELE,
        label: LABEL_LUMINARY_MODELE,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_LUMINARY_TYPE,
        label: LABEL_LUMINARY_TYPE,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_REPLACE_LIGHT_SOURCE,
        label: LABEL_REPLACE_LIGHT_SOURCE,
        type: 'radio',
        required: true,
    },
    {
        property: LABEL_LIGHT_IS_LED,
        label: LABEL_LIGHT_IS_LED,
        type: 'radio',
        required: true,
    },
    {
        property: LABEL_OPTICAL_ASSEMBLY,
        label: LABEL_OPTICAL_ASSEMBLY,
        type: 'number',
        required: true,
    },
    {
        property: LABEL_LUMEN,
        label: LABEL_LUMEN,
        type: 'number',
        required: true,
    },
    {
        property: LABEL_POWER_OF_THE_SYSTEM_W,
        label: LABEL_POWER_OF_THE_SYSTEM_W,
        type: 'number',
        required: true,
    },
    {
        property: LABEL_LIGHT_EFFICIENCY,
        label: LABEL_LIGHT_EFFICIENCY,
        type: 'number',
        required: true,
    },
    {
        property: LABEL_INDEX_COLOR_RENDERING,
        label: LABEL_INDEX_COLOR_RENDERING,
        type: 'number',
        required: false,
    },
    {
        property: LABEL_ULR,
        label: LABEL_ULR,
        type: 'number',
        requiredIf(formState) {
            return formState[LABEL_LIGHT_IS_LED];
        },
    },
    {
        property: LABEL_ULOR,
        label: LABEL_ULOR,
        type: 'number',
        requiredIf(formState) {
            return !formState[LABEL_LIGHT_IS_LED];
        },
    },
];
