import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v1 as uuidV1 } from 'uuid';
import { joinClassNames } from '../../utils';
import { DROPZONE_TEST_ID } from '../../tests/utils/tests-ids';
import useUploadDocument from '../hooks/useUploadDocument';
import { FileInput } from './FileInput';
import Loading from './Loading';

function UnStyledDropZone({ className, testId, id, name, maxFileSize, onChange, children, defaultAuthorizedFileTypes, includeFileTypes = false }) {
    const [dragging, setDragging] = useState(false);
    const [loading, setLoading] = useState(false);

    const authorizedFileTypes = defaultAuthorizedFileTypes ? defaultAuthorizedFileTypes : ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'image/heif', 'image/heic'];
    const defaultId = useMemo(() => id, [id]);

    const { isMaximumFileSizeExceeded, onFileSizeExceed, inputFileRef, isAnHeicFile, convertHeicToJpeg } = useUploadDocument({ maxFileSize });

    function handleDragEnter(evt) {
        evt.preventDefault();
        setDragging(true);
    }

    function handleDragOver(evt) {
        evt.preventDefault();
        setDragging(true);
    }

    function handleDragLeave(evt) {
        evt.preventDefault();
        setDragging(false);
    }

    function handleDrop(evt) {
        evt.preventDefault();
        setDragging(false);

        const { files } = evt.dataTransfer;
        processFiles(files);
    }

    function processFiles(files) {
        if (files && files.length > 0) {
            const file = files[0];

            if (isMaximumFileSizeExceeded(file)) {
                onFileSizeExceed();
                return;
            }
            if (!file.type && isAnHeicFile(file)) {
                setLoading(true);
                convertHeicToJpeg(file).then((convertedFile) => {
                    setLoading(false);
                    onChange({ name, file: convertedFile });
                });
            } else if (authorizedFileTypes.includes(file.type)) {
                onChange({ name, file });
                document.getElementById(defaultId).value = '';
            }
        }
    }

    function getAcceptedFormatsString() {
        return authorizedFileTypes.map((fileType) => fileType.split('/')[1]).join(', ');
    }

    return (
        <div
            data-testid={testId}
            className={joinClassNames(className, getClassName(dragging))}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>
            {includeFileTypes && <span style={{ color: '#e5733b' }}>{`Format(s) accepté(s) : ${getAcceptedFormatsString()}`}</span>}
            {children}
            <FileInput ref={inputFileRef} defaultId={defaultId} processFiles={processFiles} authorizedFileTypes={authorizedFileTypes.join(', ')} />
            <Loading show={loading} />
        </div>
    );
}

function getClassName(dragging) {
    return dragging ? 'active' : 'inactive';
}

const StyledDropZone = styled(UnStyledDropZone)`
    width: 100%;
    position: relative;
    z-index: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &.active {
        background: #fafafa;
    }
`;

export default function DropZone({ testId = DROPZONE_TEST_ID, id = uuidV1(), maxFileSize = 10, ...restProps }) {
    return <StyledDropZone testId={testId} id={id} maxFileSize={maxFileSize} {...restProps} />;
}
DropZone.propTypes = {
    testId: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    maxFileSize: PropTypes.number,
    includeFileTypes: PropTypes.bool,
    onChange: PropTypes.func,
};
