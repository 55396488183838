import { isNullishOrEmpty } from '../../index';
import { v1 as uuidV1 } from 'uuid';
import { dossierCEESidebarDocumentFileTypes, uploadDocumentTypes, uploadDocumentTypesTranslations } from '../../enums';
import { getDocumentDownloadLink, isDocumentDownloadLinkAvailable } from '../utils/utils';
import React from 'react';
import { CreateUploadDocumentWithAttestationsInitiatedData } from '../../../components/core/dossier_cee/helpers/MandatoryAttestationDocument';

function formatAttestationMandatoryChangeOfProduct(dossierCEE, mandatoryAttestations, documentType) {
    const { quotation } = dossierCEE;

    const askedAttestations = quotation.attestations.filter(({ type }) => documentType === type).filter(({ uuid }) => mandatoryAttestations.map(({ operationUUID }) => operationUUID).includes(uuid));
    return {
        uuid: `${dossierCEE.uuid}-changeOfProducts-${documentType}`,
        title: uploadDocumentTypesTranslations[documentType],
        available: true,
        documentType: documentType,
        valid: askedAttestations.every(({ status }) => status === 'SIGNED'),
        displayAlertColor: askedAttestations.some(({ status }) => status === 'UNSIGNED'),
        fileDownloadUrls: askedAttestations.map(({ fileDownloadUrl, unsignedFileDownloadUrl }) => fileDownloadUrl || unsignedFileDownloadUrl),
        fileType: dossierCEESidebarDocumentFileTypes.PDFS,
        position: 9,
        renderComponent: (currentPage) => () => {
            return <CreateUploadDocumentWithAttestationsInitiatedData dossierCEE={dossierCEE} currentAttestionIndex={currentPage - 1} documentType={documentType} />;
        },
    };
}

export const formatAttestationMandatory = (dossierCEE, mandatoryAttestation) => {
    const { quotation } = dossierCEE;
    const { attestations = [] } = quotation;

    if (isNullishOrEmpty(attestations)) return {};

    const attestation = attestations.find(({ uuid, type }) => uuid === mandatoryAttestation.operationUUID && type === mandatoryAttestation.type);

    return {
        uuid: uuidV1(),
        title: uploadDocumentTypesTranslations[attestation.type],
        available: isDocumentDownloadLinkAvailable(attestation),
        fileDownloadUrl: getDocumentDownloadLink(attestation),
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: 'À signer',
        position: 13,
    };
};

export const extractMandatoryAttestation = (dossierCEE) => {
    const { mandatoryAttestations = [] } = dossierCEE;

    if (isNullishOrEmpty(mandatoryAttestations)) return [];

    const { changeOfProductAttestations, changeOfSubContractorAttestations } = mandatoryAttestations.reduce(
        (acc, mandatoryAttestation) => {
            if (mandatoryAttestation.type === uploadDocumentTypes.ATTESTATION_CHANGE_OF_SUBCONTRACTOR) acc.changeOfSubContractorAttestations.push(mandatoryAttestation);
            else if (mandatoryAttestation.type === uploadDocumentTypes.ATTESTATION_CHANGE_OF_PRODUCT) acc.changeOfProductAttestations.push(mandatoryAttestation);
            else acc.others.push(mandatoryAttestation);
            return acc;
        },
        { changeOfProductAttestations: [], changeOfSubContractorAttestations: [], others: [] }
    );

    const formattedMandatoryAttestations = [];
    if (changeOfProductAttestations.length > 0) {
        formattedMandatoryAttestations.push(formatAttestationMandatoryChangeOfProduct(dossierCEE, changeOfProductAttestations, uploadDocumentTypes.ATTESTATION_CHANGE_OF_PRODUCT));
    }
    if (changeOfSubContractorAttestations.length > 0) {
        formattedMandatoryAttestations.push(formatAttestationMandatoryChangeOfProduct(dossierCEE, changeOfSubContractorAttestations, uploadDocumentTypes.ATTESTATION_CHANGE_OF_SUBCONTRACTOR));
    }
    return formattedMandatoryAttestations;
};
