import { getOperationOfRenovationGlobalIndex } from '../../renovation-global-helper';
import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { getDocumentTitle, getDownloadUrl } from '../utils/utils';

export const formatRenovationGlobalAmo = (dossierCEE) => {
    const { quotation } = dossierCEE;
    const renovationGlobalIndex = getOperationOfRenovationGlobalIndex(quotation);
    const { documents } = quotation.operations[renovationGlobalIndex].renovationGlobal;

    const documentUrl = getDownloadUrl(documents?.assistanceContractAMO?.contractAmo);
    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE),
        subTitle: '',
        documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_ASSISTANCE_CONTRACT_AMO_REFUSE,
        available: !!documentUrl,
        fileDownloadUrl: documentUrl,
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: documents?.assistanceContractAMO?.contractAmo?.status === 'UNSIGNED' ? 'À signer par le client' : '',
        position: 9,
    };
};
