import { INVALID_EMAIL, MISSING_EMAIL, UNAUTHORIZED_EMAIL } from '../messages';
import { isEmailDisposable } from '../email-verification-helper';
import EmailValidator from 'email-validator';
import { isValidValue } from '../index';

export async function validateEmailFormField({ email }, formErrors) {
    if (!isValidValue(email)) {
        formErrors.email = MISSING_EMAIL;
        return false;
    }
    if (!EmailValidator.validate(email)) {
        formErrors.email = INVALID_EMAIL;
        return false;
    }
    const isDisposableEmail = await isEmailDisposable(email);
    if (isDisposableEmail) formErrors.email = UNAUTHORIZED_EMAIL;

    return !isDisposableEmail;
}
