import { isNonEmptyArray, isNullishOrEmpty } from '../../utils';
import { operationTypes } from '../../utils/enums';
import productHelper from '../../utils/product-helper';
import { isOperationOfRenovationGlobal } from '../../utils/renovation-global-helper';

function areAllOperationsAndProductsValid({ operations } = {}) {
    return (
        isNonEmptyArray(operations) &&
        areAllOperationsValid(operations) &&
        areAllOperationsMainProductsValid(operations) &&
        areAllOperationsOtherProductsValid(operations) &&
        areAllOperationsNonEnergyRenovationMainProductsValid(operations)
    );
}

function areAllOperationsValid(operations = []) {
    return operations?.every((operation) => {
        if (isOperationOfRenovationGlobal(operation)) {
            return areAllOperationsValid(operation.renovationGlobal.operations);
        }
        return operation.blockValidity.valid;
    });
}

function areAllOperationsMainProductsValid(operations = []) {
    return operations?.every(({ operationSheetName, renovationGlobal, products = [] }) => {
        if (isOperationOfRenovationGlobal({ operationSheetName })) {
            return areAllOperationsMainProductsValid(renovationGlobal.operations);
        }
        const productsValid = productHelper.filterMainProducts(products).reduce((accumulator, { blockValidity }) => {
            return accumulator && blockValidity.valid;
        }, true);

        return productsValid;
    });
}

function areAllOperationsOtherProductsValid(operations = []) {
    if (!operations || operations.length === 0) return false;
    return operations?.every(({ operationSheetName, renovationGlobal, products = [] }) => {
        if (isOperationOfRenovationGlobal({ operationSheetName })) {
            return areAllOperationsOtherProductsValid(renovationGlobal?.operations);
        }
        const productsValid = productHelper.filterOtherProducts(products).reduce((accumulator, { blockValidity }) => {
            return accumulator && blockValidity.valid;
        }, true);

        return productsValid;
    });
}

function areAllOperationsNonEnergyRenovationMainProductsValid(operations = []) {
    if (isNullishOrEmpty(operations)) return false;

    return operations.every(({ type, products = [] }) => {
        if (type !== operationTypes.NON_ENERGY_RENOVATION_OPERATION) return true;

        const productsValid = productHelper.filterNonEnergyRenovationOperationMainProducts(products).reduce((accumulator, { blockValidity }) => {
            return accumulator && blockValidity.valid;
        }, true);

        return productsValid;
    });
}

export default { areAllOperationsAndProductsValid };
