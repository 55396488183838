import { paymentTypesTranslations } from '../enums';
import { isNullishOrEmpty, isValidValue } from '..';

import { customerTypes } from '../enums';

import entitiesUtils from '../entities-utils';
import invoiceHelper from '../invoice-helper';
import quotationHelper from '../quotation-helper';

function formatQuotationsOptions(quotations) {
    return quotations.map((quotation) => ({
        value: {
            idQuotation: quotation._id,
            canCreateAdvancePaymentInvoice: quotation.canCreateAdvancePaymentInvoice,
            renovationPremium: quotation.renovationPremium,
            advancePayments: quotation.advancePayments,
            totalNetPriceToPay: quotation.totalNetPriceToPay,
        },
        label: `${defineCustomerLabel(quotation)} - ${defineIdentificationLabel(quotation)} - ${defineOperationLabel(quotation)}`,
    }));
}

function defineCustomerLabel(quotation) {
    if (!customerDataExists(quotation)) {
        return '';
    }
    return customerDataHasAName(quotation) ? quotation.customer.customerData.name : `${quotation.customer.customerData.fullName}`;
}

function customerDataExists(quotation) {
    return !!quotation && !!quotation.customer && !!quotation.customer.customerData;
}

function customerDataHasAName(quotation) {
    return !!quotation.customer.customerData.name;
}

function defineIdentificationLabel(quotation) {
    return `n° ${quotation.quotationNumber}`;
}

function defineOperationLabel(quotation) {
    if (isNullishOrEmpty(quotation.operations)) return '';
    if (quotationHelper.isEnergyRenovationServiceDocument(quotation)) return quotation.operations[0].serviceName;
    if (quotationHelper.isEnergyRenovationWorkDocument(quotation)) return quotation.operations[0].formattedOperationSheetName;

    return quotationDataExists(quotation) ? quotation.operations[0].formattedOperationSheetName : '';
}

function quotationDataExists(quotation) {
    return quotation.operations.length > 0 && !!quotation.operations[0].formattedOperationSheetName;
}

function formatInvoicesOptions(invoices = []) {
    return invoices.map((invoice) => ({
        value: { uuid: invoice.uuid, status: invoice.status },
        label: `${getInvoiceNumberLabel(invoice)} - ${getCustomerLabel(invoice)} - ${entitiesUtils.formatDate(invoice.invoiceIssueDate)} - ${getOperationLabel(
            invoice
        )} - Montant TTC : ${entitiesUtils.formatPrice(invoice.totalPrice)} - Montant prime CEE : ${entitiesUtils.formatPrice(invoice.energySavingCertificatePremium)} - ${invoiceHelper.getStatusLabel(
            invoice.status
        )}`,
    }));
}

function getCustomerLabel({ customer }) {
    return isValidValue(customer.customerType) === customerTypes.CUSTOMER_PM ? customer.customerData.name : customer.customerData.fullName;
}

function getInvoiceNumberLabel(invoice) {
    return isValidValue(invoice.invoiceNumber) ? invoice.invoiceNumber : 'Brouillon';
}

function getOperationLabel(invoice) {
    return isValidValue(invoice.operations[0]) ? invoice.operations[0].operationSheetName : 'Aucune opération';
}

const paymentTypeOptions = Object.keys(paymentTypesTranslations).map((key) => ({
    value: key,
    label: paymentTypesTranslations[key],
}));

export default { formatQuotationsOptions, paymentTypeOptions, formatInvoicesOptions };
