import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isNonEmptyArray, isNonEmptyObject, isNullishOrEmpty } from '../../utils';
import accountHelper from '../../utils/account-helper';
import { applicationPages } from '../../utils/enums';
import _ from 'lodash';

export const useRole = (routes, { initAccess } = { initAccess: false }) => {
    const account = useSelector((state) => state.auth.account);
    const { organization } = useSelector((state) => state.organization);
    const [canAccessPath, setCanAccessPath] = useState(initAccess);
    const location = useLocation();

    const hasAccess = useCallback(
        (pageName) => {
            if (isNullishOrEmpty(account)) return false;

            if (isNonEmptyArray(account.organizationsAccess)) {
                return accountHelper.extractAccountRolePages(account, organization).includes(pageName);
            }

            return [applicationPages.DASHBOARD, applicationPages.ORGANIZATION].includes(pageName);
        },
        [account, organization]
    );

    const hasActions = (action) => {
        const actions = accountHelper.extractAccountRoleActions(account, organization);

        return isNonEmptyObject(actions) ? _.get(actions, action) : false;
    };

    const checkAccess = useCallback(
        (currentRoute) => {
            if (isNullishOrEmpty(currentRoute.name)) {
                setCanAccessPath(true);
            } else {
                setCanAccessPath(hasAccess(currentRoute.name));
            }
        },
        [hasAccess]
    );

    const checkAccountRole = useCallback(
        (roleName) => {
            return accountHelper.extractAccountRole(account, organization).name === roleName;
        },
        [account, organization]
    );

    useEffect(() => {
        if (isNullishOrEmpty(account) || isNullishOrEmpty(organization) || isNullishOrEmpty(location)) return;

        const currentRoute = routes.find(({ path }) => path === location.pathname);
        if (isNullishOrEmpty(currentRoute)) return;

        checkAccess(currentRoute);
    }, [location, account, organization, routes, checkAccess]);

    return { hasAccess, canAccessPath, hasActions, checkAccountRole };
};
