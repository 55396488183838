import { GET_DOCUMENT_CHECK_CONFIGURATION_SUCCESS } from '../types/admin.types';

function initState() {
    return {
        documentCheckConfiguration: {},
    };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case GET_DOCUMENT_CHECK_CONFIGURATION_SUCCESS:
            return {
                ...state,
                documentCheckConfiguration: payload.documentCheckConfiguration,
            };

        default:
            return state;
    }
}
