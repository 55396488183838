import { operationInstallationTypes } from '../enums';
import { isNonEmptyString } from '../index';

export default [
    {
        property: 'title',
        label: 'Nom',
        type: 'string',
        required: true,
    },
    {
        property: 'unit',
        label: 'Unité',
        type: 'string',
        required: true,
    },
    {
        property: 'price',
        label: 'Prix',
        type: 'number',
        required: true,
    },
    {
        property: 'vatRate',
        label: 'TVA',
        type: 'number',
        required: true,
    },
    {
        property: 'installationType',
        label: "Type d'installation",
        type: 'string',
        required: true,
    },
    {
        property: 'textArea',
        label: 'Description',
        type: 'string',
        validate: (formState, formErrors) => {
            if (formState.installationType === operationInstallationTypes.INDUCED_WORKS && !isNonEmptyString(formState.textArea)) {
                formErrors.textArea = 'Description obligatoire';
                return false;
            }
            return true;
        },
    },
];
