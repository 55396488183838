export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const SWITCH_ORGANIZATION_SUCCESS = 'SWITCH_ORGANIZATION_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE';

export const SEND_VALIDATION_LINK_REQUEST = 'SEND_VALIDATION_LINK_REQUEST';
export const SEND_VALIDATION_LINK_SUCCESS = 'SEND_VALIDATION_LINK_SUCCESS';
export const SEND_VALIDATION_LINK_FAILURE = 'SEND_VALIDATION_LINK_FAILURE';

export const CONFIRM_VALIDATION_TOKEN_REQUEST = 'CONFIRM_VALIDATION_TOKEN_REQUEST';
export const CONFIRM_VALIDATION_TOKEN_SUCCESS = 'CONFIRM_VALIDATION_TOKEN_SUCCESS';
export const CONFIRM_VALIDATION_TOKEN_FAILURE = 'CONFIRM_VALIDATION_TOKEN_FAILURE';

export const SEND_INVITATION_LINKS_REQUEST = 'SEND_INVITATION_LINKS_REQUEST';
export const SEND_INVITATION_LINKS_SUCCESS = 'SEND_INVITATION_LINKS_SUCCESS';
export const SEND_INVITATION_LINKS_FAILURE = 'SEND_INVITATION_LINKS_FAILURE';

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';

export const UPDATE_ACCOUNT_PASSWORD_REQUEST = 'UPDATE_ACCOUNT_PASSWORD_REQUEST';
export const UPDATE_ACCOUNT_PASSWORD_SUCCESS = 'UPDATE_ACCOUNT_PASSWORD_SUCCESS';
export const UPDATE_ACCOUNT_PASSWORD_FAILURE = 'UPDATE_ACCOUNT_PASSWORD_FAILURE';

export const SEND_RESET_PASSWORD_LINK_REQUEST = 'SEND_RESET_PASSWORD_LINK_REQUEST';
export const SEND_RESET_PASSWORD_LINK_SUCCESS = 'SEND_RESET_PASSWORD_LINK_SUCCESS';
export const SEND_RESET_PASSWORD_LINK_FAILURE = 'SEND_RESET_PASSWORD_LINK_FAILURE';

export const SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';

export const UPDATE_ONBOARDING_DIALOGS_ACTIONS_REQUEST = 'UPDATE_ONBOARDING_DIALOGS_ACTIONS_REQUEST';
export const UPDATE_ONBOARDING_DIALOGS_ACTIONS_SUCCESS = 'UPDATE_ONBOARDING_DIALOGS_ACTIONS_SUCCESS';
export const UPDATE_ONBOARDING_DIALOGS_ACTIONS_FAILURE = 'UPDATE_ONBOARDING_DIALOGS_ACTIONS_FAILURE';

export const UPDATE_ACCESS_RIGHTS_REQUEST = 'UPDATE_ACCESS_RIGHTS_REQUEST';
export const UPDATE_ACCESS_RIGHTS_SUCCESS = 'UPDATE_ACCESS_RIGHTS_SUCCESS';
export const UPDATE_ACCESS_RIGHTS_FAILURE = 'UPDATE_ACCESS_RIGHTS_FAILURE';

export const UPDATE_ACCOUNT_ROLE_REQUEST = 'UPDATE_ACCOUNT_ROLE_REQUEST';
export const UPDATE_ACCOUNT_ROLE_SUCCESS = 'UPDATE_ACCOUNT_ROLE_SUCCESS';
export const UPDATE_ACCOUNT_ROLE_FAILURE = 'UPDATE_ACCOUNT_ROLE_FAILURE';
