import { v1 as uuidV1 } from 'uuid';
import { getDocumentTitle } from '../utils/utils';

export const extractProductFilesDocuments = (dossierCEE) => {
    return (
        dossierCEE.productFiles?.map(({ documentType, fileType, fileDownloadUrl }) => ({
            uuid: uuidV1(),
            title: getDocumentTitle(documentType),
            documentType,
            fileDownloadUrl,
            fileType,
            isDeletable: false,
            position: 4,
        })) || []
    );
};
