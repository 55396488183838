import { useState, useEffect } from 'react';

export default function () {
    const [container] = useState(document.createElement('div'));

    useEffect(() => {
        document.body.appendChild(container);

        return () => {
            document.body.removeChild(container);
        };
    }, [container]);

    return { container };
}
