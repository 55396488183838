function saveZipFile(data, filename) {
    const linkTag = document.createElement('a');
    const blob = new Blob([data], { type: 'application/zip' });
    linkTag.href = URL.createObjectURL(blob);
    linkTag.download = filename;

    document.body.appendChild(linkTag);
    linkTag.click();
    linkTag.parentNode.removeChild(linkTag);
}

export default { saveZipFile };
