export const CREATE_QUOTATION_REQUEST = 'CREATE_QUOTATION_REQUEST';
export const CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS';
export const CREATE_QUOTATION_FAILURE = 'CREATE_QUOTATION_FAILURE';

export const GET_QUOTATIONS_REQUEST = 'GET_QUOTATIONS_REQUEST';
export const GET_QUOTATIONS_SUCCESS = 'GET_QUOTATIONS_SUCCESS';
export const GET_QUOTATIONS_FAILURE = 'GET_QUOTATIONS_FAILURE';

export const GET_QUOTATION_REQUEST = 'GET_QUOTATION_REQUEST';
export const GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS';
export const GET_QUOTATION_FAILURE = 'GET_QUOTATION_FAILURE';

export const PATCH_QUOTATION_REQUEST = 'PATCH_QUOTATION_REQUEST';
export const PATCH_QUOTATION_SUCCESS = 'PATCH_QUOTATION_SUCCESS';
export const PATCH_QUOTATION_FAILURE = 'PATCH_QUOTATION_FAILURE';

export const UPDATE_WASTE_MANAGEMENT = 'UPDATE_WASTE_MANAGEMENT';

export const ADD_OPERATION = 'ADD_OPERATION';
export const UPDATE_OPERATION = 'UPDATE_OPERATION';
export const UPDATE_OPERATION_TYPE = 'UPDATE_OPERATION_TYPE';
export const UPDATE_OPERATION_RENOVATION_GLOBAL = 'UPDATE_OPERATION_RENOVATION_GLOBAL';
export const RESET_OPERATION = 'RESET_OPERATION';
export const DELETE_OPERATION = 'DELETE_OPERATION';

export const UPDATE_QUOTATION_OPERATIONS = 'UPDATE_QUOTATION_OPERATIONS';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_MAIN_PRODUCT = 'UPDATE_MAIN_PRODUCT';
export const UPDATE_RENO_GLOBAL_MAIN_PRODUCT = 'UPDATE_RENO_GLOBAL_MAIN_PRODUCT';
export const ADD_MAIN_PRODUCT_VARIATION = 'ADD_MAIN_PRODUCT_VARIATION';
export const UPDATE_MAIN_PRODUCT_VARIATION = 'UPDATE_MAIN_PRODUCT_VARIATION';
export const DELETE_MAIN_PRODUCT_VARIATION = 'DELETE_MAIN_PRODUCT_VARIATION';
export const UPDATE_OTHER_PRODUCT = 'UPDATE_OTHER_PRODUCT';
export const UPDATE_LAST_OTHER_PRODUCT = 'UPDATE_LAST_OTHER_PRODUCT';
export const DELETE_OTHER_PRODUCT = 'DELETE_OTHER_PRODUCT';
export const DELETE_MAIN_PRODUCT = 'DELETE_MAIN_PRODUCT';
export const DELETE_ALL_OTHER_PRODUCTS = 'DELETE_ALL_OTHER_PRODUCTS';
export const UPDATE_QUOTATION_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT = 'UPDATE_QUOTATION_NON_ENERGY_RENOVATION_OPERATION_MAIN_PRODUCT';

export const UPDATE_OPERATION_INSTALLATION_DATE = 'UPDATE_OPERATION_INSTALLATION_DATE';
export const UPDATE_OPERATION_TECHNICIAN = 'UPDATE_OPERATION_TECHNICIAN';

export const SAVE_QUOTATION_REQUEST = 'SAVE_QUOTATION_REQUEST';
export const SAVE_QUOTATION_SUCCESS = 'SAVE_QUOTATION_SUCCESS';
export const SAVE_QUOTATION_FAILURE = 'SAVE_QUOTATION_FAILURE';

export const SAVE_QUOTATION_AS_DRAFT_REQUEST = 'SAVE_QUOTATION_AS_DRAFT_REQUEST';
export const SAVE_QUOTATION_AS_DRAFT_SUCCESS = 'SAVE_QUOTATION_AS_DRAFT_SUCCESS';
export const SAVE_QUOTATION_AS_DRAFT_FAILURE = 'SAVE_QUOTATION_AS_DRAFT_FAILURE';

export const UPDATE_QUOTATION = 'UPDATE_QUOTATION';

export const DELETE_QUOTATION_REQUEST = 'DELETE_QUOTATION_REQUEST';
export const DELETE_QUOTATION_SUCCESS = 'DELETE_QUOTATION_SUCCESS';
export const DELETE_QUOTATION_FAILURE = 'DELETE_QUOTATION_FAILURE';

export const GET_QUOTATIONS_ANALYTICS_REQUEST = 'GET_QUOTATIONS_ANALYTICS_REQUEST';
export const GET_QUOTATIONS_ANALYTICS_SUCCESS = 'GET_QUOTATIONS_ANALYTICS_SUCCESS';
export const GET_QUOTATIONS_ANALYTICS_FAILURE = 'GET_QUOTATIONS_ANALYTICS_FAILURE';

export const CHECK_QUOTATION_INFORMATION_REDUNDANCY_REQUEST = 'CHECK_QUOTATION_INFORMATION_REDUNDANCY_REQUEST';
export const CHECK_QUOTATION_INFORMATION_REDUNDANCY_SUCCESS = 'CHECK_QUOTATION_INFORMATION_REDUNDANCY_SUCCESS';
export const CHECK_QUOTATION_INFORMATION_REDUNDANCY_FAILURE = 'CHECK_QUOTATION_INFORMATION_REDUNDANCY_FAILURE';

export const SEND_QUOTATION_BY_EMAIL_REQUEST = 'SEND_QUOTATION_BY_EMAIL_REQUEST';
export const SEND_QUOTATION_BY_EMAIL_SUCCESS = 'SEND_QUOTATION_BY_EMAIL_SUCCESS';
export const SEND_QUOTATION_BY_EMAIL_FAILURE = 'SEND_QUOTATION_BY_EMAIL_FAILURE';

export const UPDATE_QUOTATION_PRODUCT_REQUEST = 'UPDATE_QUOTATION_PRODUCT_REQUEST';
export const UPDATE_QUOTATION_PRODUCT_SUCCESS = 'UPDATE_QUOTATION_PRODUCT_SUCCESS';
export const UPDATE_QUOTATION_PRODUCT_FAILURE = 'UPDATE_QUOTATION_PRODUCT_FAILURE';

export const RESET_QUOTATION = 'RESET_QUOTATION';

export const CLEAR_QUOTATION = 'CLEAR_QUOTATION';

export const REFUSE_QUOTATION_REQUEST = 'REFUSE_QUOTATION_REQUEST';
export const REFUSE_QUOTATION_SUCCESS = 'REFUSE_QUOTATION_SUCCESS';
export const REFUSE_QUOTATION_FAILURE = 'REFUSE_QUOTATION_FAILURE';

export const RESET_QUOTATION_CONTRACT_REQUEST = 'RESET_QUOTATION_CONTRACT_REQUEST';
export const RESET_QUOTATION_CONTRACT_SUCCESS = 'RESET_QUOTATION_CONTRACT_SUCCESS';
export const RESET_QUOTATION_CONTRACT_FAILURE = 'RESET_QUOTATION_CONTRACT_FAILURE';

export const SET_QUOTATION_SENT_TO_CUSTOMER_REQUEST = 'SET_QUOTATION_SENT_TO_CUSTOMER_REQUEST';
export const SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS = 'SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS';
export const SET_QUOTATION_SENT_TO_CUSTOMER_FAILURE = 'SET_QUOTATION_SENT_TO_CUSTOMER_FAILURE';

export const UPDATE_QUOTATION_FINANCING_REQUEST = 'UPDATE_QUOTATION_FINANCING_REQUEST';
export const UPDATE_QUOTATION_FINANCING_SUCCESS = 'UPDATE_QUOTATION_FINANCING_SUCCESS';
export const UPDATE_QUOTATION_FINANCING_FAILURE = 'UPDATE_QUOTATION_FINANCING_FAILURE';

export const DUPLICATE_QUOTATION_REQUEST = 'DUPLICATE_QUOTATION_REQUEST';
export const DUPLICATE_QUOTATION_SUCCESS = 'DUPLICATE_QUOTATION_SUCCESS';
export const DUPLICATE_QUOTATION_FAILURE = 'DUPLICATE_QUOTATION_FAILURE';

export const RESTORE_QUOTATION = 'RESTORE_QUOTATION';
export const UPDATE_QUOTATION_FINANCING_REMAINING_COSTS = 'UPDATE_QUOTATION_FINANCING_REMAINING_COST';

export const UPDATE_QUOTATION_AUDIT_REQUEST = 'UPDATE_QUOTATION_AUDIT_REQUEST';
export const UPDATE_QUOTATION_AUDIT_SUCCESS = 'UPDATE_QUOTATION_AUDIT_SUCCESS';
export const UPDATE_QUOTATION_AUDIT_FAILURE = 'UPDATE_QUOTATION_AUDIT_FAILURE';

export const UPDATE_QUOTATION_RENOVATION_GLOBAL_REQUEST = 'UPDATE_QUOTATION_RENOVATION_GLOBAL_REQUEST';
export const UPDATE_QUOTATION_RENOVATION_GLOBAL_SUCCESS = 'UPDATE_QUOTATION_RENOVATION_GLOBAL_SUCCESS';
export const UPDATE_QUOTATION_RENOVATION_GLOBAL_FAILURE = 'UPDATE_QUOTATION_RENOVATION_GLOBAL_FAILURE';

export const TRIGGER_SAVE_AS_DRAFT = 'TRIGGER_SAVE_AS_DRAFT';

export const CREATE_QUOTATION_ADVANCE_PAYMENTS_REQUEST = 'CREATE_QUOTATION_ADVANCE_PAYMENTS_REQUEST';
export const CREATE_QUOTATION_ADVANCE_PAYMENTS_SUCCESS = 'CREATE_QUOTATION_ADVANCE_PAYMENTS_SUCCESS';
export const CREATE_QUOTATION_ADVANCE_PAYMENTS_FAILURE = 'CREATE_QUOTATION_ADVANCE_PAYMENTS_FAILURE';

export const UPDATE_QUOTATION_ADVANCE_PAYMENT_REQUEST = 'UPDATE_QUOTATION_ADVANCE_PAYMENT_REQUEST';
export const UPDATE_QUOTATION_ADVANCE_PAYMENT_SUCCESS = 'UPDATE_QUOTATION_ADVANCE_PAYMENT_SUCCESS';
export const UPDATE_QUOTATION_ADVANCE_PAYMENT_FAILURE = 'UPDATE_QUOTATION_ADVANCE_PAYMENT_FAILURE';

export const DELETE_QUOTATION_ADVANCE_PAYMENT_REQUEST = 'DELETE_QUOTATION_ADVANCE_PAYMENT_REQUEST';
export const DELETE_QUOTATION_ADVANCE_PAYMENT_SUCCESS = 'DELETE_QUOTATION_ADVANCE_PAYMENT_SUCCESS';
export const DELETE_QUOTATION_ADVANCE_PAYMENT_FAILURE = 'DELETE_QUOTATION_ADVANCE_PAYMENT_FAILURE';

export const UPDATE_OPERATION_OPERATING_TYPE = 'UPDATE_OPERATION_OPERATING_TYPE';

export const SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_REQUEST = 'SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_REQUEST';
export const SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_SUCCESS = 'SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_SUCCESS';
export const SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_FAILURE = 'SEND_BENEFICIARY_APPLICATION_ACCESS_EMAIL_FAILURE';

export const UPDATE_QUOTATION_TYPE = 'UPDATE_QUOTATION_TYPE';
export const UPDATE_MAR = 'UPDATE_MAR';

export const CHECK_QUOTATION_DOCUMENTS_GENERATION_REQUEST = 'CHECK_QUOTATION_DOCUMENTS_GENERATION_REQUEST';
export const CHECK_QUOTATION_DOCUMENTS_GENERATION_SUCCESS = 'CHECK_QUOTATION_DOCUMENTS_GENERATION_SUCCESS';
export const CHECK_QUOTATION_DOCUMENTS_GENERATION_FAILURE = 'CHECK_QUOTATION_DOCUMENTS_GENERATION_FAILURE';

export const SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_REQUEST = 'SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_REQUEST';
export const SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_SUCCESS = 'SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_SUCCESS';
export const SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_FAILURE = 'SEND_TIMESTAMPED_PHOTOS_DEEPLINK_EMAIL_FAILURE';

export const SET_CREDIT_FINANCING_REQUEST = 'SET_CREDIT_FINANCING_REQUEST';
export const SET_CREDIT_FINANCING_SUCCESS = 'SET_CREDIT_FINANCING_SUCCESS';
export const SET_CREDIT_FINANCING_FAILURE = 'SET_CREDIT_FINANCING_FAILURE';
export const RESET_CREDIT_FINANCING = 'RESET_CREDIT_FINANCING';

export const SET_PERSONAL_FINANCING = 'SET_PERSONAL_FINANCING';
export const RESET_PERSONAL_FINANCING = 'RESET_PERSONAL_FINANCING';

export const CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_REQUEST = 'CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_REQUEST';
export const CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_SUCCESS = 'CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_SUCCESS';
export const CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_FAILURE = 'CALCULATE_ZERO_TOTAL_NET_PRICE_QUOTATION_FAILURE';
