import React from 'react';
import DocumentBlock from '../../../../components/core/dossier_cee/helpers/DocumentBlock';
import { CreateUploadDocumentWithInitiatedData } from '../../../../components/core/dossier_cee/helpers/MandatoryAttestationDocument';
import { dossierCEEPropType, sidebarDocumentPropType } from '../../../prop-types-schemas';
import PropTypes from 'prop-types';
import { StyledDossierCEEBlockAddButton } from '../../../../components/core/dossier_cee/helpers/StyledDossierCEEBlockAddButton';
import { Button } from '@renolib/renolib-ui-kit';
import { Link, useLocation } from 'react-router-dom';

export const SidebarDocumentButtonForCadastralPlot = ({ document, selectedDocument, onSelectDocument, index, dossierCEE }) => {
    const location = useLocation();

    return (
        <div data-name={'rendered-component'} style={{ position: 'relative' }}>
            <DocumentBlock
                key={`${document.type}_${document.uuid}`}
                document={document}
                selected={document.uuid === selectedDocument.uuid}
                onSelectDocument={(selectedDocument) => onSelectDocument(selectedDocument, index)}
            />
            <StyledDossierCEEBlockAddButton>
                <Link to={`${location.pathname}/parcelle-cadastrale`}>
                    <Button icon='add' size={'small'} />
                </Link>
            </StyledDossierCEEBlockAddButton>
        </div>
    );
};

export const ButtonToUploadDocument = ({ document, documentType, dossierCEE, selectedDocument, onSelectDocument, index }) => {
    const location = useLocation();
    return (
        <div data-name={'rendered-component'} style={{ position: 'relative' }}>
            <DocumentBlock
                key={`${document.type}_${document.uuid}`}
                document={document}
                selected={document.uuid === selectedDocument.uuid}
                onSelectDocument={(selectedDocument) => onSelectDocument(selectedDocument, index)}
            />
            <StyledDossierCEEBlockAddButton>
                <Link to={`${location.pathname}/upload-document/${documentType}`}>
                    <Button icon='add' size={'small'} />
                </Link>
            </StyledDossierCEEBlockAddButton>
        </div>
    );
};

export const SidebarDefaultDocumentButton = ({ documentType, document, selectedDocument, onSelectDocument, index, dossierCEE, uploadDocumentActions }) => {
    const { quotation } = dossierCEE;
    return (
        <div data-name={'rendered-component'} style={{ position: 'relative' }}>
            <DocumentBlock
                key={`${document.type}_${document.uuid}`}
                document={document}
                selected={document.uuid === selectedDocument.uuid}
                onSelectDocument={(selectedDocument) => onSelectDocument(selectedDocument, index)}
            />
            <CreateUploadDocumentWithInitiatedData dossierCEE={dossierCEE} quotation={quotation} documentType={documentType} uploadDocumentActions={uploadDocumentActions} />
        </div>
    );
};

SidebarDefaultDocumentButton.propTypes = {
    dossierCEE: dossierCEEPropType,
    document: sidebarDocumentPropType,
    selectedDocument: sidebarDocumentPropType,
    documentType: PropTypes.string,
    onSelectDocument: PropTypes.func,
    index: PropTypes.number,
};
