import uploadDocumentHelper from '../../../../utils/upload-document-helper';
import Form from '../../../helpers/Form';
import { Field } from '@renolib/renolib-ui-kit';
import React from 'react';
import { getOccupantsOptions } from './getOccupantsOptionsFromDossierCEE';

export const TaxNoticeForm = ({ documentType, dossierCEE, formState, formErrors, onChange }) => {
    if (!uploadDocumentHelper.isTaxNotice(documentType)) return <></>;

    const occupants = getOccupantsOptions({ dossierCEE });
    return (
        <Form.Group>
            <Field
                fluid
                type='select'
                label="Veuillez sélectionner l'occupant relatif à l'avis d'imposition"
                placeholder={"Sélectionner l'occupant"}
                name='occupant'
                defaultValue={occupants.find(({ value }) => value === formState?.occupant)?.label}
                onChange={onChange}
                error={formErrors.occupant}
                options={occupants}
                required={true}
            />
        </Form.Group>
    );
};
