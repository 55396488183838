/* Helpers components */
export const MODAL_TEST_ID = 'modal';
export const PDF_VIEWER_TEST_ID = 'pdf-viewer';
export const IMAGE_VIEWER_TEST_ID = 'image-viewer';
export const DROPZONE_TEST_ID = 'dropzone';
export const UPLOAD_INPUT_TEST_ID = 'file-upload';
export const PROGRESS_TEST_ID = 'progress';
export const APP_LOGO_TEST_ID = 'app-logo';

export const CONFIRM_DIALOG_TEST_ID = 'confirm-dialog';
export const FAILURE_DIALOG_TEST_ID = 'failure-dialog';
export const SUCCESS_DIALOG_TEST_ID = 'success-dialog';
export const WARNING_DIALOG_TEST_ID = 'warning-dialog';

export const TOOLTIP_CONTENT_TEST_ID = 'tooltip-content';

export const MAIN_TABS_TEST_ID = 'main-tabs';

export const ADDRESS_FORM_TEST_ID = 'address-form';
export const PERSON_IDENTITY_FORM_TEST_ID = 'person-identity-form';
export const PERSON_CONTACTS_FORM_TEST_ID = 'person-contacts-form';

export const FISCAL_INFORMATION_FINDER_TEST_ID = 'fiscal-information-finder';
export const ADDRESS_FINDER_TEST_ID = 'address-finder';

export const SEARCH_TAX_HOUSEHOLD_FORM_TEST_ID = 'search-tax-household-form';
export const MANUAL_TAX_HOUSEHOLD_FORM_TEST_ID = 'manual-tax-household-form';

export const SELECT_TEST_ID = 'select';

export const RENOVATION_ADDRESS_FORM_TEST_ID = 'renovation-address-form';
export const RENOVATION_ADDRESS_MODAL_TEST_ID = 'renovation-address-modal';
export const RENOVATION_ADDRESSES_BLOCK_TEST_ID = 'renovation-addresses-block';
export const RENOVATION_ADDRESSES_LIST_TEST_ID = 'renovation-addresses-list';

export const NOTIFICATION_TEST_ID = 'notification';

/* Common components */
export const RGE_CERTIFICATES_TEST_ID = 'rge-certificates';
export const RGE_CERTIFICATES_SLIDER_TEST_ID = 'rge-certificates-slider';
export const RGE_CERTIFICATE_FORM_TEST_ID = 'rge-certificate-form';
export const RGE_CERTIFICATE_MODAL_TEST_ID = 'rge-certificate-modal';
export const RGE_CERTIFICATE_MODAL_DROPZONE_TEST_ID = 'rge-certificate-modal-dropzone';

export const OPERATION_INSTALLATION_FINISHES_FORM_TEST_ID = 'operation-installation-finishes-form';
export const OPERATION_INSTALLATION_FINISHES_PREVIEW_TEST_ID = 'operation-installation-finishes-preview';

export const SELECT_MULTIPLE_TEST_ID = 'select-multiple';

export const ONBOARDING_BAR_TEST_ID = 'onboarding-bar';

export const COUP_DE_POUCE_TEST_ID = 'coup-de-pouce';

export const VALIDITY_TEST_ID = 'validity';

/* Auth */
export const CHECKBOX_TERMS_OF_USE_TEST_ID = 'terms-of-use-accepted';

/* Dossier cee */
export const BUTTON_DOWNLOAD_DOSSIER_CEE_TEST_ID = 'download-dossier-cee';
export const DOCUMENT_BLOCK_TEST_ID = 'document-block';
export const DOCUMENTS_VIEWER_TEST_ID = 'document-viewer';
export const DOSSIER_CEE_TAX_NOTICES_REFRESH_TEST_ID = 'dossier-cee-tax-notices-refresh';

/* Quotation */
export const PREMIUM_TEST_ID = 'energy-saving-certificate-premium';
export const QUOTATION_HEADER_TEST_ID = 'quotation-header';
export const ADD_OPERATION_BUTTON_TOOLTIP_TEST_ID = 'add-operation-button-tooltip';
export const HAS_ADMINISTRATIVE_PROBLEM_TAG_TEST_ID = 'HAS_ADMINISTRATIVE_PROBLEM_TAG_TEST_ID';

/* Consumer Mediator */
export const CONSUMER_MEDIATOR_TEST_ID = 'CONSUMER_MEDIATOR_TEST_ID';

/** Customers */
export const CREATE_CUSTOMER_PP_MODAL_TEST_ID = 'create-customer-pp-modal';
export const UPDATE_CUSTOMER_PP_MODAL_TEST_ID = 'update-customer-pp-modal';

export const IMAGE_SLIDER_TEST_ID = 'image-slider';

export const PAGINATION_TEST_ID = 'pagination';

/** Organization */
export const CONSUMER_MEDIATOR_BLOCK_TEST_ID = 'consumer-mediator-block';
export const FULLY_UPDATE_ORGANIZATION_BUTTON_TEST_ID = 'fully-update-organization-button';
export const FULLY_UPDATE_ORGANIZATION_BUTTON_TOOLTIP_TEST_ID = 'fully-update-organization-button-tooltip';
export const ORGANIZATION_DOCUMENTS_TEST_ID = 'organization-documents';
export const INSURANCES_TAB_CONTENT_TEST_ID = 'insurances-tab-content';
export const INSURANCE_MODAL_TEST_ID = 'insurance-modal';

export const ADD_ORGANIZATION_CONTACT = 'add-organization-contact';
export const INVITATION_MODAL_TEST_ID = 'invitation-modal';
export const INVITATION_STATUS_TEST_ID = 'invitation-status';

/** Invoices */
// amountBeforeTax
export const INVOICES_CARD_TABS_TEST_ID = 'invoices-card-tab';

export const INVOICE_MODAL_TEST_ID = 'invoice-modal';

export const CREATE_DELEGATEE_INVOICE_MODAL_TEST_ID = 'create-delegatee-invoice-modal';

/** Contracts */
export const CONTRACT_MODAL_TEST_ID = 'contract-modal';
export const CONTRACT_AMENDMENT_MODAL_TEST_ID = 'contract-amendment-modal';
export const CONTRACT_DELETE_BUTTON_TEST_ID = 'contract-delete';

/** Products */
export const CREATE_PRODUCT_MODAL_TEST_ID = 'create-product-modal';
export const EDIT_PRODUCT_MODAL_TEST_ID = 'edit-product-modal';
export const PRODUCT_EXCLUSION_DETAILS_TEST_ID = 'product-exclusion-details';
export const PRODUCT_VARIATIONS_TEST_ID = 'product-variations';
export const PRODUCT_QUANTIFIABLE_BLOCK_TEST_ID = 'product-quantifiable-block';

/** Planning */
export const PLANNING_BUTTONS_PANEL_ID = 'planning-buttons-panel';
export const PLANNING_CATEGORY_MODAL_ID = 'planning-create-category-modal';
export const PLANNING_CATEGORIES_ID = 'planning-categories';
export const PLANNING_DELETE_CATEGORY_BUTTON_ID = 'planning-delete-category-button';
export const PLANNING_UPDATE_CATEGORY_BUTTON_ID = 'planning-update-category-button';
export const PLANNING_APPOINTMENT_MODAL_ID = 'planning-appointment-modal';
export const PLANNING_APPOINTMENT_READING_MODAL_ID = 'planning-appointment-reading-modal';
export const PLANNING_FILTER_MODAL_ID = 'planning-filter-modal';
export const PLANNING_CALENDAR_DAY_VIEW_ID = 'planning-calendar-day-view';
export const PLANNING_CALENDAR_MONTH_VIEW_ID = 'planning-calendar-month-view';
export const PLANNING_CALENDAR_WEEK_VIEW_ID = 'planning-calendar-week-view';
export const PLANNING_CALENDAR_WEEK_TIME_TABLE_CELL_ID = 'planning-calendar-week-time-table-cell';
export const PLANNING_ADD_OTHER_PERSON_ID = 'planning-add-other-person';
export const PLANNING_ADD_TECHNICIAN_ID = 'planning-add-technician';
export const PLANNING_CONTACT_CARD_ID = 'planning-contact-card';
export const PLANNING_APPOINTMENT_CARD_ID = 'planning-appointment-card';
export const PLANNING_DRAGGED_APPOINTMENT_CARD_ID = 'planning-dragged-appointment-card';
export const PLANNING_APPOINTMENTS_TABLE_ID = 'planning-appointments-table';
export const PLANNING_APPOINTMENT_TABLE_ITEM_ID = 'planning-appointment-table-item';
export const PLANNING_CALENDAR_NEXT_WEEK = 'planning-next-week';

/** Sub Contractors */
export const CREATE_SUBCONTRACTOR_TEST_ID = 'create-sub-contractor';

/** Quotation */
export const CREATE_QUOTATION_RENOVATION_ADDRESS = 'create-quotation-renovation-address';
export const PAC_SURFACE_TEST_ID = 'pac-surface';
export const UPLOAD_SOLAR_FACTOR_CALCULATION_NOTE_TEST_ID = 'solar-factor-calculation-note';
export const UPLOAD_SOLAR_FACTOR_CALCULATION_NOTE_MODAL_TEST_ID = 'solar-factor-calculation-note-modal';

export const UPLOAD_PRODUCT_DOCUMENT_TEST_ID = 'upload-product-document';
export const UPLOAD_PRODUCT_DOCUMENT_MODAL_TEST_ID = 'upload-product-document-modal';

export const MA_PRIME_RENOV_ALERT_TEST_ID = 'ma-prime-renov-alert';
export const SET_OF_ELEMENTS_ROOF_CONSTITUTION_TYPE_FORM_TEST_ID = 'set-of-elements-roof-constitution-type-form';

export const EXPIRED_QUOTATION_MODAL_TEST_ID = 'expired-quotation-modal';
export const ONE_EURO_BUTTON_TEST_ID = 'one-euro-button';
/** Renovation Premium */
export const MA_PRIME_RENOV_ELIGIBLE_OPERATION_TABLE = 'ma-prime-renov-eligible-operation-table';

/** Waste Collection Depot */
export const WASTE_COLLECTION_DEPOT_TEST_ID = 'waste-collection-depot';
export const WASTE_TYPE_TEST_ID = 'waste-type';
export const DELETE_WASTE_TYPE_TEST_ID = 'waste-type-delete';
export const ADD_WASTE_TYPE_TEST_ID = 'waste-type-add';
export const WASTE_QUANTIFIABLE_BLOCK_TEST_ID = 'waste-quantifiable-block';

export const PRODUCT_FILES_TEST_ID = 'product-files-test-id';

/** Upload documents */
export const UPLOAD_DOCUMENT_MODAL_TEST_ID = 'upload-document-modal';

/** Invoice */
export const TABLE_QUANTIFIABLE_ADVANCE_PAYMENT_BLOCK = 'table-quantifiable-advance-payment-block';

export const SAVE_AS_DRAFT_INVOICE_TEST_ID = 'save-as-draft-test-id';
export const FINALIZE_INVOICE_TEST_ID = 'finalize-invoice-test-id';
export const INVOICE_MIXED_MANDATE_WARNING_TEST_ID = 'invoice-mixed-mandata-warning-test-id';

export const PICTURE_MODAL_TEST_ID = 'picture-modal-test-id';

/** Audit */

export const RENOVATION_GLOBAL_AUDIT_TEST_ID = 'audit-renovation-energetique';
export const UPLOAD_CADASTRAL_MODAL_TEST_ID = 'upload-cadastral-modal';
