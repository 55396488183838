export const extractTechniciansStatusReport = (dossierCEE) => {
    if (!dossierCEE.quotation?.technicians || dossierCEE.quotation.technicians.length === 0) return [];

    const fileDownloadUrls = dossierCEE.quotation.technicians.map((entity) => {
        return entity?.organization ? entity.organization.statusReport.fileDownloadUrl : entity.subcontractor.statusReport.fileDownloadUrl;
    });

    return [
        {
            fileType: 'PDFS',
            valid: true,
            available: true,
            documentType: `Avis de situation(s)`,
            fileDownloadUrls,
            position: 10,
            title: `Avis de situation(s)`,
        },
    ];
};
