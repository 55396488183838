export const LABEL_SELECT_TECHNICIAN = 'Sélectionner un technicien';
export const LABEL_SELECT_CONTRACT = 'Sélectionner un contrat';
export const LABEL_ADD_OPERATION = "Ajouter d'autres travaux de rénovation énergétique";
export const LABEL_ADD_NON_ENERGY_RENOVATION_OPERATION = "Ajouter d'autres travaux";
export const LABEL_SEND = 'Envoyer';

export const LABEL_SELECT_QUOTATION_OPERATION = 'Sélectionner une opération';
export const LABEL_SELECT_INSTALLATION_TYPE = "Type d'installation";
export const LABEL_CHECKBOX_OPERATION_TYPE = 'Travaux/Service de rénovation énergétique';

export const LABEL_AUTOCOMPLETE_PRODUCT = 'Rechercher un produit';

export const LABEL_BUTTON_CREATE_PRODUCT = 'Créer un produit';

export const LABEL_BUTTON_QUOTATION_PROVIDE_PRODUCT = 'Renseigner votre produit';
export const LABEL_BUTTON_QUOTATION_EDIT_PRODUCT = 'Modifier votre produit';

export const LABEL_INPUT_TECHNICAL_VISIT_DATE = 'Date de la visite technique préalable';
export const LABEL_CHECKBOX_WITHOUT_TECHNICAL_VISIT_DATE = 'Sans visite technique préalable';

export const LABEL_INPUT_BUILDING_TOTAL_AREA = 'Surface totale du bâtiment (m²)';
export const LABEL_INPUT_WINDOW_TOTAL_AREA = 'Surface de la toiture protégée (m²)';

export const LABEL_INPUT_QUANTITY = 'Quantité';

export const LABEL_SEARCH_PRODUCT = 'Rechercher un produit';
export const LABEL_CREATE_PRODUCT = 'Créer un produit';
export const LABEL_CREATED_PRODUCT = 'Votre produit a bien été enregistré';

export const LABEL_WASTE_MANAGEMENT_TITLE = 'Gestion des déchets conformément au décret n° 2020-1817 du 29 décembre 2020';
export const LABEL_WASTE_TYPE = 'Typologie de déchets :';
export const LABEL_ADD_WASTE_TYPE = 'Ajouter une autre typologie de déchets';
export const LABEL_WASTE_COLLECTION_DEPOT_SOCIAL_REASON = 'Raison sociale du point de collecte';
export const LABEL_TRASH_COLLECTION_DEPOT_LABEL = 'Point(s) de collecte des déchets :';
export const LABEL_WASTE_COLLECTION_DEPOT_CREATE = 'Créer un point de collecte';
export const LABEL_WASTE_COLLECTION_DEPOT_AUTOCOMPLETE = `Recherche d'un point de collecte`;
export const LABEL_WASTE_MODALITIES = `Modalités prévues de gestion et d'enlèvement des déchets (ex: tri réalisé, collectes séparées de déchets, broyage de déchets...) :`;

export const LABEL_SELECT_ENERGETIC_TAG_BEFORE = 'Sélectionner une étiquette énergétique avant travaux';
export const LABEL_SELECT_ENERGETIC_TAG_AFTER = 'Sélectionner une étiquette énergétique après travaux';

export const LABEL_SELECT_OPERATION_STAGE = 'Sélectionner l’étape de travaux';
export const LABEL_SELECT_ENERGETIC_TAG = "Sélectionner l'étiquette énergétique";
export const LABEL_SELECT_HEATING_MODE_BEFORE = 'Sélectionner le mode de chauffage avant travaux';
export const LABEL_SELECT_AUXILIARY_HEATING_MODE = "Sélectionner la mise en place d'un chauffage d'appoint";

export const LABEL_CHECKBOX_INCLUDE_MPR_INFORMATION_PRINTING_OPTION = "Afficher le montant estimatif MaPrimeRénov' sur le PDF du devis pour mon client";
export const LABEL_ENERGY_SIEVE_EXIT = 'Bonus sortie de passoire énergétique';
export const LABEL_LOW_CONSUMPTION = 'Bonus basse consommation';
export const LABEL_LOW_CONSUMPTION_BUILDING = 'Bonus bâtiment basse consommation';

export const LABEL_SEND_BENEFICIARY_APPLICATION_ACCESS = 'Envoyer un accès au dossier au bénéficiaire';
export const LABEL_IN_COPY = "Être en copie de l'email";
export const LABEL_ELECTRONIC_SIGNATURE = 'Signature électronique';

export const ADD_LABEL_STAGE = 'Ajouter une étape de travaux';

export const ADD_OPERATION_TO_AUDIT = 'Ajouter une opération';

export const MAR_TITLE = "Participation d'un MAR pour la rénovation d'ampleur";

export const LABEL_REFRESH = 'Actualiser';

export function LABEL_CREDIT_SIMULATION({ totalNetPriceToPay } = {}) {
    return `Simulation basée sur le reste à charge d'un total de ${totalNetPriceToPay}. (un RAC n'est pas en TTC ni en HT)`;
}
export const LABEL_PERSONAL_CREDIT = 'Prêt personnel';
export function LABEL_CREDIT_MONTHS({ months } = {}) {
    return `Basé sur ${months} mensualités de`;
}
export const LABEL_CREDIT_RATE = 'Taux annuel effectif global (TAEG)';
export const LABEL_CREDIT_DUE_AMOUNT = "Montant total dû par l'emprunteur";
export const LABEL_CREDIT_OPTIONAL_INSURANCE = "Contrat d'assurance facultative";
export const LABEL_CREDIT_INSURANCE_ANNUAL_RATE = "Taux annuel effectif de l'assurance (TAEA)";
export const LABEL_CREDIT_INSURANCE_MONTHLY_AMOUNT = "Montant mensuel de l'assurance";
export const LABEL_CREDIT_INSURANCE_TOTAL_AMOUNT = "Montant total de l'assurance";
export const LABEL_CREDIT_AMOUNT_INCLUDING_INSURANCE = 'Montant total crédit + assurance';
export const LABEL_USE_PERSONAL_FINANCING = 'Utiliser un autre moyen de financement';
