import moment from 'moment';
import _ from 'lodash';
import quotationHelper from './quotation-helper';
import entitiesUtils from './entities-utils';
import {
    invoiceStatusesLabels,
    invoiceStatusesActionButtonLabels,
    invoiceStatuses,
    productCreationOriginTypes,
    applicationPages,
    quotationTechnicianTypes,
    invoiceTypes,
    productStatuses,
    dossierCEEStatuses,
    commercialDiscountType,
} from './enums';
import operationSheetNames from './operation-sheet-names';
import {
    INVOICE_NOT_SENDABLE_AND_DRAFT,
    INVOICE_NOT_SENDABLE_AND_PAYED,
    INVOICE_NOT_SENDABLE_AND_CANCELLED_OR_RECTIFIED,
    INVOICE_NOT_PAYABLE_AND_DRAFT,
    INVOICE_NOT_PAYABLE_AND_CANCELLED_OR_RECTIFIED,
    INVOICE_ALREADY_PAID,
    INVOICE_ALREADY_CANCELLED,
    INVOICE_MUST_BE_SAVED,
    NO_SIGNED_QUOTATIONS_FOR_INVOICE,
    NO_EXPORTABLE_INVOICES,
    INVOICE_NOT_PAYABLE_AND_FINALIZED_AND_TYPE_IS_NOT_CLASSIC,
    INVOICE_CLASSIC_WORDING,
    INVOICE_ADVANCE_PAYMENT_WORDING,
    INVOICE_NOT_FINALIZABLE_PRODUCTS_NOT_VALIDATED,
    INVOICE_HAS_A_SENT_DOSSIER_CEE,
    INVOICE_NOT_FINALIZABLE_OPERATION_NOT_VALIDATED,
} from './messages';
import { isNonEmptyObject, isNullish, isNullishOrEmpty, isValidValue } from '.';
import history from './history';
import dateUtils, { dateFormats } from './date_utils';
import { LABEL_EXPORT_INVOICES_PREFIX, LABEL_RESEND, LABEL_SEND } from './form_labels';
import operationFormHelper from './operation-form-helper';
import { LABEL_EQUIPEMENT_INSTALLE } from './operation-constants';
import { getOperationOfRenovationGlobalFromOperations } from './renovation-global-helper';
import { convertPercentageToEuro } from './math';

const getTechnician = (technician) => {
    const { technicianType, technicianData = {} } = technician;

    return technicianType === quotationTechnicianTypes.ORGANIZATION ? 'Mon organisation' : technicianData.name;
};

const getTotalToPay = (invoice) => {
    return invoice.totalPrice - invoice.energySavingCertificatePremium;
};

function getTotalPriceWithoutTax(invoice) {
    const { pricesPerVatRate = [] } = invoice;

    return pricesPerVatRate.reduce((accumulator, { totalPreTax }) => accumulator + totalPreTax, 0);
}

const formatInvoicesForTable = (invoices) => {
    return invoices.map((invoice) => ({
        key: invoice.uuid,
        uuid: invoice.uuid,
        createdBy: invoice?.createdBy,
        invoiceNumber: invoice.invoiceNumber ? invoice.invoiceNumber : invoice.status,
        customer: quotationHelper.formatCustomerName(invoice.customer, { withGender: false }),
        invoiceIssueDate: entitiesUtils.formatDate(invoice.invoiceIssueDate),
        operations: quotationHelper.getOperationsNames(invoice.operations),
        status: invoice.status,
        paymentDate: invoice.paymentDate,
        totalNetPrice: invoice.totalNetPrice,
        totalPrice: invoice.totalPrice,
        energySavingCertificatePremium: invoice.energySavingCertificatePremium,
        hasAdministrativeProblem: invoice.hasAdministrativeProblem,
        type: invoice.type,
        dossierCEE: invoice.dossierCEE,
        nearestActiveInvoiceIssueDate: invoice.nearestActiveInvoiceIssueDate,
        premiums: { cee: invoice.customerEnergySavingCertificatePremium, mpr: !!invoice.renovationPremium?.representativeType && invoice.renovationPremium?.maPrimeRenovTotalAmount },
        invoice: invoice,
    }));
};

const getStatusLabel = (status) => {
    return invoiceStatusesLabels[status];
};

const isAnInvoiceStatus = (data) => {
    return !invoiceStatusesLabels[data] ? false : true;
};

const getActionButtonLabel = (status) => {
    return invoiceStatusesActionButtonLabels[status];
};

const assignDatesToInvoice = ({ invoiceIssueDate, operations }) => {
    invoiceIssueDate = invoiceIssueDate ? invoiceIssueDate : moment().format();
    operations.forEach((operation) => {
        const { installationDate } = operation;
        operation.installationEndDate = operation.installationEndDate ? operation.installationEndDate : moment().format();
        installationDate.operationInstallationDate = installationDate.operationInstallationDate ? installationDate.operationInstallationDate : moment().format();
    });
    return { invoiceIssueDate, operations };
};

const isDraft = (status) => {
    return status === invoiceStatuses.DRAFT;
};

const hasNecessaryArrangements = (operation) => {
    const specificsOperations = [
        operationSheetNames.BAR_EN_101V_A33_3,
        operationSheetNames.BAR_EN_101V_A33_3_CDP,
        operationSheetNames.BAR_EN_101V_A39_4,
        operationSheetNames.BAR_EN_101V_A39_4_CDP,
        operationSheetNames.BAR_EN_103V_A36_4,
        operationSheetNames.BAR_EN_103V_A36_4_CDP,
        operationSheetNames.BAR_EN_103V_A39_5,
        operationSheetNames.BAR_EN_103V_A39_5_CDP,
        operationSheetNames.BAT_EN_101,
        operationSheetNames.BAT_EN_103,
    ];

    if (operation.otherDetailsToSpecify.length > 0 && operation.otherDetailsToSpecify[0].value)
        return specificsOperations.find((specificOperation) => specificOperation === operation.operationSheetName);
};

const isInvoiceEnergySavingCertificatePremiumValid = (invoice) => {
    const { energySavingCertificatePremium } = invoice;

    return energySavingCertificatePremium > 0;
};

const isProductCreationOriginFromQuotation = (creationOrigin) => {
    return creationOrigin === productCreationOriginTypes.QUOTATION;
};

function areInvoiceProductsValid(invoice) {
    const { operations } = invoice;
    let valid = true;
    operations.forEach((operation) => {
        const { products } = operation;
        products.forEach((product) => {
            valid = valid && product.blockValidity.valid;
        });
    });

    return valid;
}

function isInvoiceSendable(invoice = {}) {
    return [invoiceStatuses.FINALIZED, invoiceStatuses.SENT, invoiceStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION].includes(invoice.status);
}

function isInvoiceRectifiable(invoice = {}) {
    return [invoiceStatuses.FINALIZED, invoiceStatuses.SENT].includes(invoice.status);
}

const isCancelledInvoice = (invoice = {}) => {
    return [invoiceStatuses.RECTIFIED, invoiceStatuses.CANCELLED].includes(invoice.status);
};

const isPaidInvoice = (invoice = {}) => {
    return invoice.status === invoiceStatuses.PAID;
};

const isFinalizedInvoice = (invoice = {}) => {
    return invoice?.status && [invoiceStatuses.FINALIZED, invoiceStatuses.SENT].includes(invoice.status);
};

const isSentInvoice = (invoice = {}) => {
    return invoice.status === invoiceStatuses.SENT;
};

function isInvoicePayable(invoice = {}) {
    return (isFinalizedInvoice(invoice) || isSentInvoice(invoice)) && !isInvoiceCreditNote(invoice);
}

function isInvoiceClassic({ type } = {}) {
    return type === invoiceTypes.CLASSIC;
}

function isInvoiceCreditNote({ type } = {}) {
    return type === invoiceTypes.INVOICE_CREDIT_NOTE;
}

function isInvoiceDeletable(invoice = {}) {
    if (isDossierCEESent(invoice)) return false;

    return invoice.status === invoiceStatuses.DRAFT;
}

function isInvoiceCancellable(invoice = {}) {
    if (isDossierCEESent(invoice) || invoice.isCreditNoteInvoice) return false;

    return [invoiceStatuses.FINALIZED, invoiceStatuses.SENT, invoiceStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION, invoiceStatuses.PAID, invoiceStatuses.RECTIFIED].includes(invoice?.status);
}

function isDossierCEESent(invoice) {
    return [dossierCEEStatuses.SENT, dossierCEEStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION].includes(invoice?.dossierCEE?.status);
}

const getInvoiceSendButtonMessage = (invoice = {}) => {
    if (isDraft(invoice.status)) {
        return INVOICE_NOT_SENDABLE_AND_DRAFT;
    } else if (invoice.status === invoiceStatuses.PAID) {
        return INVOICE_NOT_SENDABLE_AND_PAYED;
    } else if (isCancelledInvoice(invoice)) {
        return INVOICE_NOT_SENDABLE_AND_CANCELLED_OR_RECTIFIED;
    }
    return '';
};

const getInvoicePayButtonMessage = (invoice = {}) => {
    if (isDraft(invoice.status)) {
        return INVOICE_NOT_PAYABLE_AND_DRAFT;
    } else if (isSentInvoice(invoice) && isInvoiceCreditNote(invoice)) {
        return INVOICE_NOT_PAYABLE_AND_FINALIZED_AND_TYPE_IS_NOT_CLASSIC;
    } else if (isCancelledInvoice(invoice)) {
        return INVOICE_NOT_PAYABLE_AND_CANCELLED_OR_RECTIFIED;
    } else if (isPaidInvoice(invoice)) {
        return INVOICE_ALREADY_PAID;
    }
};

const getInvoiceDeleteButtonMessage = (invoice = {}) => {
    if (isCancelledInvoice(invoice)) {
        return INVOICE_ALREADY_CANCELLED;
    } else if ([dossierCEEStatuses.SENT, dossierCEEStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION].includes(invoice?.dossierCEE?.status)) {
        return INVOICE_HAS_A_SENT_DOSSIER_CEE;
    }
};

const getInvoiceDeleteMessage = (invoice = {}) => {
    if (isDraft(invoice.status)) {
        return 'Supprimer';
    } else if ([invoiceStatuses.PAID, invoiceStatuses.CANCELLED].includes(invoice.status)) {
        return 'Annuler';
    }
};

const getAcquitInvoiceMessage = (invoice = {}) => {
    if (!isPaidInvoice(invoice)) {
        return "Vous devez confirmer le paiement de la facture pour l'acquitter";
    }
};

const getSendWording = (invoice = {}) => {
    return isSentInvoice(invoice) ? LABEL_RESEND : LABEL_SEND;
};

const filterInvoiceByStatus = (invoices, status) => {
    return invoices.filter((invoice) => invoice.status === status);
};

const filterFinalizedInvoices = (invoices) => {
    return filterInvoiceByStatus(invoices, invoiceStatuses.FINALIZED);
};

const filterSentInvoices = (invoices) => {
    return filterInvoiceByStatus(invoices, invoiceStatuses.SENT);
};

const isInvoiceSaved = (invoice = {}) => {
    return !!invoice.fileDownloadUrl;
};

const getVisibilityButtonMessage = (invoice = {}) => {
    return isInvoicePrintable(invoice) ? '' : INVOICE_MUST_BE_SAVED;
};

const isVisibilityButtonDisabled = (invoice = {}) => {
    return !isInvoicePrintable(invoice);
};

const hasQuotations = (quotations) => {
    return quotations.length > 0;
};

const getCreateInvoiceButtonMessage = (quotations = []) => {
    return hasQuotations(quotations) ? '' : NO_SIGNED_QUOTATIONS_FOR_INVOICE;
};

function getCustomerEnergySavingCertificatePremiumLabel(invoice, operation) {
    const customerMinimumEnergySavingCertificatePremium = operation.customerMinimumEnergySavingCertificatePremium;

    return `Vous pouvez reverser entre ${entitiesUtils.formatPrice(customerMinimumEnergySavingCertificatePremium)} et ${entitiesUtils.formatPrice(operation.operationVolumesAndPremium.premium)}`;
}

function isInvoiceActive(invoice) {
    return isNonEmptyObject(invoice) && [invoiceStatuses.FINALIZED, invoiceStatuses.SENT, invoiceStatuses.PAID].includes(invoice.status);
}

function handleOpenInvoice({ uuid, status }) {
    const openInvoiceStrategies = {
        [invoiceStatuses.DRAFT]: handleCompleteInvoice,
        [invoiceStatuses.FINALIZED]: handleConsultInvoice,
        [invoiceStatuses.SENT]: handleConsultInvoice,
        [invoiceStatuses.PAID]: handleConsultInvoice,
        [invoiceStatuses.CANCELLED]: handleConsultInvoice,
        [invoiceStatuses.RECTIFIED]: handleConsultInvoice,
        [invoiceStatuses.ACQUITTED]: handleConsultInvoice,
    };
    openInvoiceStrategies[status](uuid);
}

function handleCompleteInvoice(uuid) {
    history.push(`/factures/${uuid}/edition`);
}

function handleConsultInvoice(uuid) {
    history.push(`/factures/${uuid}/apercu`);
}

function canShowCustomerColumn(targetPage) {
    return targetPage !== applicationPages.CUSTOMER;
}

function canShowTableMenuActions(targetPage) {
    return targetPage === applicationPages.INVOICES;
}

function isInvoicePrintable(invoice) {
    return invoice.printable && isInvoiceSaved(invoice);
}

function canShowBackToEditionNotification(invoice = {}) {
    return [invoiceStatuses.DRAFT].includes(invoice.status);
}

function generateInvoicesExportFileName() {
    return LABEL_EXPORT_INVOICES_PREFIX.concat(dateUtils.formatDate({ format: dateFormats.FILE_NAME }));
}

function getExportButtonMessage(disabled) {
    return disabled ? NO_EXPORTABLE_INVOICES : '';
}

function canShowInstallationFinishes(operation) {
    const value = operationFormHelper.getInstallationFinishesValue(operation);

    return !isNullish(value) && operationFormHelper.doesOperationRequireInstallationFinishes(operation);
}

function isOperationFinishesProvided(operation) {
    if (!operationFormHelper.doesOperationRequireInstallationFinishes(operation)) return true;

    return isValidValue(operationFormHelper.getInstallationFinishesValue(operation));
}

function findInvoiceByUUID(invoices, uuid) {
    return invoices.find((invoice) => invoice.uuid === uuid);
}

function canShowOtherDetailsToSpecify(operation) {
    return [operationSheetNames.RES_EC_104].includes(operation.operationSheetName);
}
function showOtherDetailToSpecifyForBarTh112ForBarTh113(operation) {
    return [
        operationSheetNames.BAR_TH_112V_A35_2,
        operationSheetNames.BAR_TH_112V_A35_2_CDP,
        operationSheetNames.BAR_TH_112V_A46_3,
        operationSheetNames.BAR_TH_112V_A46_3_CDP,
        operationSheetNames.BAR_TH_113V_A41_3,
        operationSheetNames.BAR_TH_113V_A41_3_CDP,
    ].includes(operation.operationSheetName);
}

function findDetailsToSpecifyByLabel({ detailsToSpecify = [], label }) {
    return detailsToSpecify.find((detailToSpecify) => detailToSpecify.label === label);
}

function getInvoiceWording(invoice = {}) {
    return isInvoiceClassic(invoice) ? INVOICE_CLASSIC_WORDING : INVOICE_ADVANCE_PAYMENT_WORDING;
}

function isAdvancePayment(invoice = {}) {
    return invoice?.type === invoiceTypes.ADVANCE_PAYMENT;
}

function areEveryRGECertificateUsedInInvoiceValid(invoice) {
    if (isNullishOrEmpty(invoice.organization) || isNullishOrEmpty(invoice.operations)) return true;

    const { rgeCertificates, rgeCertificatesReferences } = invoice.organization;
    if (isNullishOrEmpty(rgeCertificates) || isNullishOrEmpty(rgeCertificatesReferences)) return true;

    const usedRGECertificates = filterUsedRGECertificates(rgeCertificates, rgeCertificatesReferences);
    if (isNullishOrEmpty(usedRGECertificates)) return true;

    const nonCompliantOperation = invoice.operations.find((operation) => operationFormHelper.canCheckTechnicianCompliancy(operation?.technician));
    if (nonCompliantOperation) return false;

    return areAllUsedRGECertificatesValid(usedRGECertificates, invoice) && areSubContractorsRGECertificatesValid(invoice);
}

function areSubContractorsRGECertificatesValid(invoice) {
    let valid = true;
    invoice.operations.forEach((operation) => {
        if (operation.technician?.technicianData?.rgeCertificates && !areOneRGECertificatesValid(operation.technician.technicianData.rgeCertificates, invoice)) valid = false;
    });
    return valid;
}

function filterUsedRGECertificates(rgeCertificates, rgeCertificatesReferences) {
    const formattedRGECertificatesReferences = rgeCertificatesReferences.map((rgeCertificateReference) =>
        isNonEmptyObject(rgeCertificateReference) ? rgeCertificateReference.reference : rgeCertificateReference
    );

    return rgeCertificates.filter((rgeCertificate) => {
        return isNonEmptyObject(rgeCertificate) && formattedRGECertificatesReferences.includes(rgeCertificate.reference);
    });
}

function areAllUsedRGECertificatesValid(rgeCertificates, { operations = [] } = {}) {
    return operations.every((operation) => {
        return rgeCertificates.every((rgeCertificate) => dateUtils.isSameOrBefore(operation.installationEndDate, rgeCertificate.endDate));
    });
}

function areOneRGECertificatesValid(rgeCertificates, { operations = [] } = {}) {
    return operations.some((operation) => {
        return rgeCertificates.every((rgeCertificate) => dateUtils.isSameOrBefore(operation.installationEndDate, rgeCertificate.endDate));
    });
}

function getFinalizeInvoiceButtonTooltipMessage(invoice) {
    if (isNullishOrEmpty(invoice) || isNullishOrEmpty(invoice.operations)) return '';
    if (!areEveryOperationsProductsValid(invoice.operations)) return INVOICE_NOT_FINALIZABLE_PRODUCTS_NOT_VALIDATED;
    if (checkIfInvoicesContainThatNeedASpecificationFromTheUser(invoice)) return INVOICE_NOT_FINALIZABLE_OPERATION_NOT_VALIDATED;

    return '';
}

function isInvoiceFinalizable({ operations = [], type }) {
    if (getOperationOfRenovationGlobalFromOperations({ operations })) {
        const allOperations = _.get(operations, '0.renovationGlobal.operations', []);
        return allOperations.every(({ compliant }) => compliant);
    }
    if (quotationHelper.isOnlyEnergyRenovationWorkOrServiceDocument({ operations })) return areEveryOperationsCompliants(operations);
    if (quotationHelper.isNonEnergyRenovationDocument({ operations })) return areEveryOperationsProductsValid(operations);

    const invoiceShouldNotNeedASpecificationFromTheUser = type === invoiceTypes.ADVANCE_PAYMENT || !checkIfInvoicesContainThatNeedASpecificationFromTheUser({ operations });
    return areEveryOperationsProductsValid(operations) && invoiceShouldNotNeedASpecificationFromTheUser;
}

function areEveryOperationsCompliants(operations) {
    return operations.every(({ compliant }) => compliant);
}

function areEveryOperationsProductsValid(operations) {
    const products = extractOperationsProducts(operations);
    if (isNullishOrEmpty(products)) return false;

    return products.every((product) => product.status !== productStatuses.PENDING);
}

function extractOperationsProducts(operations = []) {
    return operations
        .flatMap(({ products = [] }) => products)
        .map(({ product }) => product)
        .filter(isNonEmptyObject);
}

function checkIfThereIsAttestationToSign({ attestations = [] }) {
    return attestations.some((attestation) => attestation.toSign);
}

function isAnOperationThatNeedASpecificationFromTheUser({ installationDetailsToSpecify }) {
    if (!installationDetailsToSpecify || installationDetailsToSpecify?.length === 0) return false;
    return installationDetailsToSpecify?.find(({ label }) => label === LABEL_EQUIPEMENT_INSTALLE);
}

function checkIfInvoicesContainThatNeedASpecificationFromTheUser({ operations }) {
    return operations?.some((operation) => {
        return isAnOperationThatNeedASpecificationFromTheUser(operation);
    });
}

function filterAdditionalDetails(specifiedDetails = []) {
    return specifiedDetails.filter(({ additional }) => additional);
}

function areAllAdvancePaymentsCreated(quotation) {
    const { advancePayments = [] } = quotation;

    return advancePayments.length > 0 && advancePayments.every((advancePayment) => advancePayment.created);
}

function getSelectAdvancePaymentsOptions(quotation = {}) {
    const { advancePayments = [], totalNetPriceToPay = 0 } = quotation;

    return advancePayments.map((advancePayment, index) => ({
        label: `Acompte n° ${index + 1} - ${
            advancePayment.type === commercialDiscountType.PERCENTAGE
                ? convertPercentageToEuro(totalNetPriceToPay, advancePayment, { withEuroSymbol: true })
                : entitiesUtils.formatPrice(advancePayment.amount)
        }`,
        value: advancePayment.uuid,
        disabled: advancePayment.created,
    }));
}

function canShowNecessaryArrangementsForm({ operationSheetName } = {}) {
    return [
        operationSheetNames.BAR_EN_101V_A33_3,
        operationSheetNames.BAR_EN_101V_A33_3_CDP,
        operationSheetNames.BAR_EN_101V_A39_4,
        operationSheetNames.BAR_EN_101V_A39_4_CDP,
        operationSheetNames.BAR_EN_103V_A36_4,
        operationSheetNames.BAR_EN_103V_A36_4_CDP,
        operationSheetNames.BAR_EN_103V_A39_5,
        operationSheetNames.BAR_EN_103V_A39_5_CDP,
        operationSheetNames.BAT_EN_101,
        operationSheetNames.BAT_EN_103,
        operationSheetNames.BAT_EN_106,
    ].includes(operationSheetName);
}

function shouldShowRegulationSystemOperationForm({ operationSheetName }) {
    return [operationSheetNames.BAR_TH_173V_A56_1, operationSheetNames.BAR_TH_173V_A56_1_CDP].includes(operationSheetName);
}

export default {
    assignDatesToInvoice,
    getTechnician,
    getTotalToPay,
    getTotalPriceWithoutTax,
    formatInvoicesForTable,
    getStatusLabel,
    isAnInvoiceStatus,
    areInvoiceProductsValid,
    getActionButtonLabel,
    isDraft,
    hasNecessaryArrangements,
    isInvoiceEnergySavingCertificatePremiumValid,
    isProductCreationOriginFromQuotation,
    isInvoiceSendable,
    isSentInvoice,
    getInvoiceSendButtonMessage,
    getInvoicePayButtonMessage,
    isInvoicePayable,
    getInvoiceDeleteButtonMessage,
    isInvoiceDeletable,
    getInvoiceDeleteMessage,
    filterFinalizedInvoices,
    filterSentInvoices,
    getVisibilityButtonMessage,
    isVisibilityButtonDisabled,
    getSendWording,
    getCreateInvoiceButtonMessage,
    hasQuotations,
    getCustomerEnergySavingCertificatePremiumLabel,
    isInvoiceActive,
    handleOpenInvoice,
    canShowCustomerColumn,
    canShowTableMenuActions,
    isInvoicePrintable,
    canShowBackToEditionNotification,
    generateInvoicesExportFileName,
    getExportButtonMessage,
    canShowInstallationFinishes,
    isOperationFinishesProvided,
    findInvoiceByUUID,
    showOtherDetailToSpecifyForBarTh112ForBarTh113,
    findDetailsToSpecifyByLabel,
    isInvoiceClassic,
    getInvoiceWording,
    isAdvancePayment,
    isInvoiceFinalizable,
    getFinalizeInvoiceButtonTooltipMessage,
    areEveryRGECertificateUsedInInvoiceValid,
    checkIfThereIsAttestationToSign,
    isAnOperationThatNeedASpecificationFromTheUser,
    filterAdditionalDetails,
    areAllAdvancePaymentsCreated,
    getSelectAdvancePaymentsOptions,
    isInvoiceRectifiable,
    canShowOtherDetailsToSpecify,
    getAcquitInvoiceMessage,
    isPaidInvoice,
    isInvoiceCancellable,
    canShowNecessaryArrangementsForm,
    shouldShowRegulationSystemOperationForm,
};
