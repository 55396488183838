import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../helpers/Container';
import { DrawCadastralDocument } from '../../core/dossier_cee/helpers/DrawCadastralDocument';
import dialogsActions from '../../../store/actions/ui/dialogs';

export const CreateParcelleCadastrale = ({ dossierCEE, onCreate }) => {
    const dispatch = useDispatch();

    const onGetAddressError = useCallback(
        (errorMessage) => {
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
        },
        [dispatch]
    );

    if (!dossierCEE) return null;
    const { quotation, addressGeoCoordinates = {} } = dossierCEE;

    return (
        <>
            <Container backUrl={`/dossiers-financement/${dossierCEE.uuid}`} backButtonTitle='Retour au dossier'>
                <div>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{quotation?.address?.fullAddress}</span>
                </div>
                <DrawCadastralDocument
                    address={quotation?.address?.fullAddress}
                    city={quotation?.address?.city}
                    postalCode={quotation?.address?.postalCode}
                    latitude={addressGeoCoordinates?.latitude}
                    longitude={addressGeoCoordinates?.longitude}
                    onSave={onCreate}
                    onGetAddressError={onGetAddressError}
                />
            </Container>
        </>
    );
};
