import { SET_DOSSIERS_FILTERS, SET_QUOTATIONS_FILTERS, SET_INVOICES_FILTERS } from '../../types/ui/filters';

const initState = () => ({
    dossiers: {},
    quotations: {},
    invoices: {},
});

export default (state = initState(), action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_DOSSIERS_FILTERS:
            return {
                dossiers: payload.filters,
            };
        case SET_QUOTATIONS_FILTERS:
            return {
                quotations: payload.filters,
            };
        case SET_INVOICES_FILTERS:
            return {
                invoices: payload.filters,
            };
        default:
            return state;
    }
};
