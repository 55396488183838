import { GET_CREDIT_FINANCING_CONSENT_SUCCESS, SEND_CREDIT_FINANCING_CONSENT_ACCEPTANCE_SUCCESS } from '../types/financing.types';

function initState() {
    return { financingConsentRequest: {} };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CREDIT_FINANCING_CONSENT_SUCCESS:
        case SEND_CREDIT_FINANCING_CONSENT_ACCEPTANCE_SUCCESS: {
            return {
                financingConsentRequest: payload.financingConsentRequest,
            };
        }

        default:
            return state;
    }
}
