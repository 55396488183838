import { isNullishOrEmpty } from '.';
import { isOperationOfRenovationGlobal } from './renovation-global-helper';

function formatQuotationForSaving(quotation) {
    if (isNullishOrEmpty(quotation)) return;

    const formattedQuotation = {
        ...quotation,
        operations: quotation.operations.map(filterNonValidProductsInOperation).filter((operation) => isOperationOfRenovationGlobal(operation) || operation?.blockValidity.valid),
    };

    return formattedQuotation;
}

function filterNonValidProductsInOperation(operation) {
    if (isOperationOfRenovationGlobal(operation)) return operation;
    return {
        ...operation,
        products: operation?.products ? operation.products.filter(({ blockValidity }) => blockValidity.valid) : [],
    };
}

export default { formatQuotationForSaving };
