export const GET_ORGANIZATION_EMAILS_REQUEST = 'GET_ORGANIZATION_EMAILS_REQUEST';
export const GET_ORGANIZATION_EMAILS_SUCCESS = 'GET_ORGANIZATION_EMAILS_SUCCESS';
export const GET_ORGANIZATION_EMAILS_FAILURE = 'GET_ORGANIZATION_EMAILS_FAILURE';

export const GET_CUSTOMER_EMAILS_REQUEST = 'GET_CUSTOMER_EMAILS_REQUEST';
export const GET_CUSTOMER_EMAILS_SUCCESS = 'GET_CUSTOMER_EMAILS_SUCCESS';
export const GET_CUSTOMER_EMAILS_FAILURE = 'GET_CUSTOMER_EMAILS_FAILURE';

export const RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE';

export const GET_EMAILS_ANALYTICS_REQUEST = 'GET_EMAILS_ANALYTICS_REQUEST';
export const GET_EMAILS_ANALYTICS_SUCCESS = 'GET_EMAILS_ANALYTICS_SUCCESS';
export const GET_EMAILS_ANALYTICS_FAILURE = 'GET_EMAILS_ANALYTICS_FAILURE';
