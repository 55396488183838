import {
    CREATE_UPLOAD_DOCUMENT_SUCCESS,
    GET_UPLOAD_DOCUMENTS_SUCCESS,
    GET_UPLOAD_DOCUMENT_SUCCESS,
    DELETE_UPLOAD_DOCUMENT_SUCCESS,
    GET_UPLOAD_DOCUMENTS_REQUEST,
    DELETE_FILE_UPLOAD_DOCUMENT_SUCCESS,
    GET_CHECK_DOCUMENT_REQUEST,
    GET_CHECK_DOCUMENT_SUCCESS,
    RESET_DOCUMENT_CHECK_MESSAGE,
} from '../types/upload-document.types';

function initState() {
    return {
        uploadDocuments: [],
        loading: true,
        message: {
            select_dossierCEE: '',
        },
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case CREATE_UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                uploadDocuments: [action.payload.uploadDocument, ...state.uploadDocuments.filter(({ uuid }) => uuid !== action.payload.uploadDocument.uuid)],
            };
        case GET_UPLOAD_DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_UPLOAD_DOCUMENTS_SUCCESS:
            return {
                ...state,
                uploadDocuments: action.payload.uploadDocuments,
                loading: false,
            };
        case DELETE_FILE_UPLOAD_DOCUMENT_SUCCESS:
        case GET_UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                uploadDocument: action.payload.uploadDocument,
            };

        case DELETE_UPLOAD_DOCUMENT_SUCCESS:
            return action.payload.goBackToDocumentsPage
                ? state
                : {
                      uploadDocuments: state.uploadDocuments.filter(({ uuid }) => uuid !== action.payload.uploadDocument.uuid),
                  };
        case GET_CHECK_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CHECK_DOCUMENT_SUCCESS:
            return {
                ...state,
                message: { ...state.message, select_dossierCEE: action.payload.message },
            };

        case RESET_DOCUMENT_CHECK_MESSAGE:
            return {
                ...state,
                message: { ...state.message, select_dossierCEE: '' },
            };
        default:
            return state;
    }
}
