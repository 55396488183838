import useModal from './useModal';
import useForm from './useForm';
import { useDispatch } from 'react-redux';
import formValidation from '../../utils/form_validation';
import { confirmPaymentTypeFieldDescriptor } from '../../utils/entity_fields_descriptors';
import invoiceActions from '../../store/actions/invoice.actions';

export const useConfirmPayment = ({ invoice, onSuccess }) => {
    const confirmPaymentModal = useModal();
    const confirmPaymentForm = useForm();
    const dispatch = useDispatch();

    const handleConfirmPayment = () => {
        const { state } = confirmPaymentForm;
        if (!state.paymentDate) state.paymentDate = new Date();

        const { validForm, formErrors } = formValidation.validateForm(state, confirmPaymentTypeFieldDescriptor);
        if (!validForm) {
            confirmPaymentForm.setErrors(formErrors);
        } else {
            const { invoice, ...updates } = state;
            dispatch(invoiceActions.updateInvoiceStatusToPaid(invoice.uuid, updates, onSuccess));
            confirmPaymentModal.handleHide();
        }
    };

    const handleOpenConfirmPaymentModal = () => {
        confirmPaymentForm.setErrors({});
        confirmPaymentForm.reset({ invoice });
        confirmPaymentModal.handleShow();
    };

    return {
        confirmPaymentForm,
        confirmPaymentModal,
        handleConfirmPayment,
        handleOpenConfirmPaymentModal,
    };
};
