import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import logoIcon from '../../assets/img/logo/logo-r.svg';
import { fadeIn } from '../../config/animations';
import zIndices from '../../config/z-indices';

import CircularProgress from './CircularProgress';
import useCreatePortal from '../hooks/useCreatePortal';

function UnStyledLoading({ className }) {
    const { container } = useCreatePortal();

    return ReactDOM.createPortal(
        <div className={className}>
            <div>
                <main>
                    <CircularProgress radius={50} strokeColor='primary' animate />
                    <img src={logoIcon} alt='' />
                </main>
            </div>
        </div>,
        container
    );
}

const StyledLoading = styled(UnStyledLoading)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${zIndices.loading};
    background: rgba(29, 44, 107, 0.55);
    display: ${getDisplay};

    > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        animation: ${fadeIn} 300ms forwards;

        > main {
            position: relative;

            > img {
                position: absolute;
                left: calc(((100% - 2.5rem) / 2) + 5px);
                top: calc((100% - 2.5rem) / 2);
                height: 2.5rem;
            }
        }
    }
`;

function getDisplay({ show }) {
    return show ? 'block' : 'none';
}

export default function Loading({ show = false, ...restProps }) {
    return <StyledLoading show={show} {...restProps} />;
}
Loading.propTypes = {
    show: PropTypes.bool,
};
