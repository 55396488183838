import { isDocumentDeletable, isOtherDocumentType } from '../utils/utils';

function getOtherDocumentLabel(fileName) {
    return `Autre (${fileName})`;
}

export const formatOtherDocumentTypeAsDocument = (dossierCEE) => {
    const { uploadDocuments = [] } = dossierCEE;
    return uploadDocuments.filter(isOtherDocumentType).map(({ documentType, fileName, fileDownloadUrl, fileType, uuid }) => {
        return {
            uuid,
            title: getOtherDocumentLabel(fileName),
            fileDownloadUrl,
            documentType,
            fileType,
            isDeletable: isDocumentDeletable(dossierCEE),
            position: 11,
        };
    });
};
