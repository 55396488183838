export const INVALID_DOSSIER_CEE_QUOTATION = 'À signer par le client';
export const INVALID_DOSSIER_CEE_INVOICE = 'À créer et à finaliser';
export const INVALID_DOSSIER_CEE_POST_RENOVATION_REPORT = 'À ajouter';
export const INVALID_DOSSIER_CEE_SWORN_STATEMENT = "Générée par l'application : à signer (client et installateur)";
export const INVALID_DOSSIER_CEE_INVOICE_TO_FINALIZE = 'À finaliser et à envoyer';
export const INVALID_DOSSIER_CEE_SIZING_NOTE = 'À ajouter';

export const MANDATORY_DOSSIER_CEE_DOCUMENT = 'À ajouter';

export const VALID_DOSSIER_CEE_QUOTATION = 'Signé par le client';
export const VALID_DOSSIER_CEE_CLAIM_ASSIGNMENT = 'Signée par le client';
export const VALID_DOSSIER_CEE_INVOICE_TO_SEND = 'À envoyer au client';
export const VALID_DOSSIER_CEE_INVOICE = 'Adressée au client';

export const DOSSIER_CEE_CANCELLED_DOCUMENT = 'Annulée';
export const DOSSIER_CEE_RECTIFIED_INVOICE = 'Rectifiée';

export const DOSSIER_CEE_QUOTATION_LABEL = 'Devis des travaux';
export const DOSSIER_CEE_CADRE_CONTRIBUTION_LABEL = 'Cadre(s) de contribution';
export const DOSSIER_CEE_INVOICE_LABEL = 'Facture des travaux';
export const DOSSIER_CEE_ADVANCE_PAYMENT_INVOICE_LABEL = "Facture d'acompte des travaux";
export const DOSSIER_CEE_POST_RENOVATION_REPORT_LABEL = 'Rapport post-travaux';
export const DOSSIER_CEE_SIZING_NOTE_LABEL = 'Note de dimensionnement';
export const DOSSIER_CEE_SWORN_STATEMENT_LABEL = "Attestation sur l'honneur";
export const DOSSIER_CEE_CADASTRAL_PLOT_LABEL = 'Parcelle cadastrale';
export const DOSSIER_CEE_SITE_PHOTO_LABEL = 'Photos du site';
export const DOSSIER_CEE_SITE_PHOTO_LABEL_BEFORE_RENOVATION = 'Photos du site avant travaux';
export const DOSSIER_CEE_SITE_PHOTO_LABEL_AFTER_RENOVATION = 'Photos du site après travaux';
export const DOSSIER_CEE_OTHER_DOCUMENT_LABEL = 'Autre';
export const DOSSIER_CEE_SOLAR_CERTIFICATION_LABEL = 'Certification des capteurs solaires';
export const DOSSIER_CEE_TAX_NOTICES_LABEL = "Avis d'imposition";
export const DOSSIER_CEE_SOLAR_FACTOR_CALCULATION_NOTE_LABEL = 'Note de calcul du facteur solaire';
export const DOSSIER_CEE_DOMICILIATION_CERTIFICATE_LABEL = 'Attestation de domiciliation';
export const DOSSIER_CEE_GREEN_FLAME_LABEL_SEVEN = 'Label flamme verte 7*';
export const DOSSIER_CEE_TECHNICAL_SHEET = 'Fiche technique du produit';
export const DOSSIER_CEE_RGE_CERTIFICATES = 'Certificat(s) RGE';
export const DOSSIER_CEE_CLAIM_ASSIGNMENT = 'Lettre de cession de créance';
export const DOSSIER_CEE_VAT_CERTIFICATE = 'Attestation de TVA';
export const DOSSIER_CEE_SIGNATURE_CERTIFICATE = 'Certificat de signature';

export const NO_DOSSIERS_CEE = "Aucun dossier de financement n'a été créé.";

export const DOSSIER_CEE_NOT_SENDABLE = "Merci de compléter votre dossier avant de pouvoir l'envoyer.";
export const DOSSIER_CEE_NOT_DOWNLOADABLE = 'Merci de compléter votre dossier avant de pouvoir le télécharger.';

export const NO_COMPLETED_DOSSIERS_CEE = 'Aucun dossier de financement complété.';
export const NO_SENT_DOSSIERS_CEE = 'Aucun dossier de financement envoyé.';

export const DOSSIER_CEE_TAX_NOTICES_REGENERATED = "Les avis d'imposition ont bien été générés.";

export const DOSSIER_CEE_CANCELLED_BY_BACK_OFFICE = 'Le dossier a été annulé à cause d’un problème administratif. Il ne peut pas être envoyé ni téléchargé.';
export const DOSSIER_CEE_AH_EDF_MESSAGE = 'à renseigner sur la plateforme myCEE d’EDF et à signer (client et installateur)';

export const RENOVATION_GLOBAL_AUDIT = 'Etude énergétique préalable aux travaux';
export const RENOVATION_GLOBAL_AMO_REFUSE = "Refus exprès de la prestation d'AMO";
export const RENOVATION_GLOBAL_COFRAC_BEFORE_WORK = "Rapport Cofrac d'avant travaux";
export const RENOVATION_GLOBAL_COFRAC_AFTER_WORK = 'Rapport Cofrac après travaux';
export const RENOVATION_GLOBAL_ADVISED_OPERATION_LIST = 'Liste des travaux préconisés';
export const RENOVATION_GLOBAL_TECHNICIANS_LIST = 'Liste des entreprises';
export const RENOVATION_GLOBAL_MAPRIMERENOV_WORKING_STATEMENT = 'Attestation de travaux et demande de prime';
export const COMMISSIONING_REPORT = 'Rapport de mise en service ';
export const AUDIT_BEFORE_RENOVATION = 'Audit énergetique avant travaux';
export const AUDIT_AFTER_RENOVATION = 'Audit énergetique après travaux';
export const RENOVATION_GLOBAL_AUDIT_QUOTATION_TRANSLATION = 'Devis audit énergétique signé';
export const TECHNICAL_SHEET = 'Fiche technique produit(s)';
export const MAPRIME_RENOV_CERTIFICATE = "Mandat MaPrimeRénov' signé";
export const GRANT_LETTER = "Lettre d'octroi ANAH";
export const RENOVATION_AMPLEUR_ATTESTATION_QUOTATION = 'Attestation de travaux (devis)';
export const RENOVATION_AMPLEUR_ATTESTATION_INVOICE = 'Attestation de travaux (facture)';

export const DPE_OR_AUDIT_ENERGETIC = 'DPE/Audit énergétique';

export const SUMMARY_STATEMENT = 'État récapitulatif';
export const POST_RENOVATION_CONTROL_REPORT = 'Rapport de contrôle post-travaux';
export const TIME_STAMPED_PHOTO = 'Photos horodatées après-travaux';

export const TIMESTAMPED_PHOTOS_TOOLTIP =
    "La photo horodatée répond aux exigences de l'arrêté du 13 juin 2023 dans le cadre des CEE. Il est obligatoire de joindre aux dossiers ces photos horodatées et géolocalisées afin de contrôler la conformité des travaux. Avec notre partenaire certificall, profitez d'une application mobile simple et intuitive pour les réaliser. Pour en savoir plus cliquez sur ce lien :  https://certificall.app/horodatage-geolocalisation-photos-cles-conformite-dossiers-cee-arrete-13-juin-2023";

export const TIMESTAMPED_PHOTOS_CONDITION = "Une fois que votre dossier est validé, veuillez noter qu'il faudra environ 15 minutes pour que les photos y soient intégrées via l'application.";
