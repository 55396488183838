import { GET_ACTIVE_APPLICATION_CONFIGURATION_SUCCESS } from '../types/application-configuration.types';

function initState() {
    return {
        applicationConfiguration: {},
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case GET_ACTIVE_APPLICATION_CONFIGURATION_SUCCESS:
            return {
                ...state,
                applicationConfiguration: action.payload.applicationConfiguration,
            };

        default:
            return state;
    }
}
