import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Button, Autocomplete } from '@renolib/renolib-ui-kit';
import { Alert } from 'antd';

import uploadDocumentHelper from '../../../../utils/upload-document-helper';
import Modal from '../../../helpers/Modal';
import Form from '../../../helpers/Form';
import DatePicker from '../../../helpers/DatePicker';
import ViewerFormContainer from '../../../helpers/ViewerFormContainer';
import PDFViewer from '../../../helpers/PDFViewer';
import { dossierCEEDocumentTypes, uploadDocumentFileTypes, uploadDocumentTypes } from '../../../../utils/enums';
import ImageViewer from '../../../helpers/ImageViewer';
import { uploadDocumentMaxDates, uploadDocumentMinDates } from '../../../../utils/form_utils/date-picker-config';
import { useFinder } from '../../quotation/edition/main/wasteManagement/hooks/useFinder';
import dossierCEEService from '../../../../store/services/dossier-cee.service';
import { isNonEmptyObject, isValidValue } from '../../../../utils';
import { LABEL_AUTOCOMPLETE_DOSSIER, LABEL_INPUT_COMMENT_OPTIONAL, LABEL_SELECT_DOCUMENT_TYPE, LABEL_SELECT_OPERATION_RELATED_TO_ATTESTATION } from '../../../../utils/form_labels';
import { UPLOAD_DOCUMENT_MODAL_TEST_ID } from '../../../../tests/utils/tests-ids';
import { maPrimeRenovDocumentTypes } from '../helpers/maPrimeRenovDocumentTypes';
import { SelectOperations } from './SelectOperations';
import UploadMultipleFiles from '../../../helpers/UploadMultipleFiles';

function CreateUploadDocumentModal({
    show,
    defaultFormState = undefined,
    formState,
    formErrors,
    onChange,
    onHide,
    onSave,
    updateFormState,
    disabledFieldsSelection = false,
    operationSheets,
    isUploadOtherDocument = false,
    checkIfDocumentExist,
    message,
}) {
    const { documentType, fileName, fileType, file } = formState;
    const fileNameRef = useRef(null);

    const allSitePhotos = [uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION, uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION];

    const formatSearchResult = (results) =>
        results
            .filter((dossierCEE) => (![uploadDocumentTypes.QUOTATION, uploadDocumentTypes.AUDIT_BEFORE_RENOVATION].includes(documentType) ? isValidValue(dossierCEE.number) : true))
            .map((dossierCEE) => ({ label: dossierCEE.filterableQuotationProperties.label, all: dossierCEE, value: dossierCEE._id }));

    const search = useCallback(
        (searchString) => {
            let query = { searchString, filterDossiersCEEWithoutPremium: false, filterDossiersCEEWithCompletedQuotation: true, withDossiersMaPrimeRenov: false };
            if (Object.keys(maPrimeRenovDocumentTypes).includes(documentType)) {
                query = { ...query, withDossiersMaPrimeRenov: true };
            }
            if (documentType === uploadDocumentTypes.CLAIM_ASSIGNMENT) query = { ...query, filterDossiersWithContractNegotiatedByPro: true };
            if (documentType === uploadDocumentTypes.CADRE_CONTRIBUTION) query = { ...query, filterDossiersWithContractNegotiatedByPro: true };
            return dossierCEEService.searchDossiersCEE(query);
        },
        [documentType]
    );

    const {
        searchedEntities: dossiersCEE,
        isLoading,
        onSearch,
    } = useFinder({
        finderService: {
            search,
        },
        formatSearchResult,
    });

    const [informationOperationSheetValidity, setInformationOperationSheetValidity] = useState('');
    const [selectedDossierCee, setSelectedDossierCee] = useState(undefined);

    function handleSelectDossierCEE({ option }) {
        const stateUpdates = {};
        if (isNonEmptyObject(option.all.quotation)) {
            stateUpdates.quotationId = option.all.quotation.id;
            stateUpdates.quotation = option.all.quotation;
        }
        setSelectedDossierCee(option.all);
        onChange({ name: 'dossierCEEId', value: option.value, propertyToResets: ['signatureDate', 'accreditationExpirationDate', 'operations'], stateUpdates });
        checkIfDocumentExist({ value: option.value });
    }

    useEffect(() => {
        const { signatureDate } = formState;
        if (documentType === uploadDocumentTypes.QUOTATION && signatureDate && selectedDossierCee) {
            setInformationOperationSheetValidity(uploadDocumentHelper.getQuotationCompliancyInformationMessage(selectedDossierCee, operationSheets, signatureDate));
        }
        if (formState.fileName && !fileNameRef.current) fileNameRef.current = formState.fileName;
    }, [selectedDossierCee, documentType, formState, operationSheets]);

    const getMinSignatureDate = useCallback(() => {
        return {
            [uploadDocumentTypes.QUOTATION]: uploadDocumentMinDates.quotationSignatureDate(selectedDossierCee?.quotation?.createdAt),
            [uploadDocumentTypes.SWORN_STATEMENT]: uploadDocumentMinDates.swornStatementSignatureDate(selectedDossierCee?.invoice?.invoiceIssueDate),
            [uploadDocumentTypes.POST_RENOVATION_REPORT]: uploadDocumentMinDates.postRenovationSignatureDate(selectedDossierCee?.invoice?.operations),
        }[documentType];
    }, [documentType, selectedDossierCee]);

    const getMaxSignatureDate = useCallback(() => {
        return {
            [uploadDocumentTypes.QUOTATION]: uploadDocumentMaxDates.quotationSignatureDate({ selectedDossierCee }),
            [uploadDocumentTypes.SWORN_STATEMENT]: uploadDocumentMaxDates.swornStatementMaxSignatureDate(),
            [uploadDocumentTypes.POST_RENOVATION_REPORT]: uploadDocumentMaxDates.postRenovationSignatureDate(selectedDossierCee?.invoice?.operations),
        }[documentType];
    }, [documentType, selectedDossierCee]);

    const getExtraJSXByDocumentType = useCallback(() => {
        if (!selectedDossierCee) return <div />;
        const { quotation } = selectedDossierCee;
        if ([uploadDocumentTypes.SITE_PHOTO_AFTER_RENOVATION, uploadDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION].includes(documentType)) {
            return <SelectOperations quotation={quotation} onChange={onChange} error={formErrors?.operations} />;
        }
        if ([uploadDocumentTypes.AUDIT_AFTER_RENOVATION, uploadDocumentTypes.AUDIT_BEFORE_RENOVATION].includes(documentType)) return <>{displayAuditForm(formErrors, onChange)}</>;
        return <div />;
    }, [documentType, selectedDossierCee, formErrors, onChange]);

    function handleDocumentTypeChange({ name, value }) {
        if (value === uploadDocumentTypes.OTHER) onChange({ name: 'fileName', value: '' });
        else if (!fileName) onChange({ name: 'fileName', value: fileNameRef.current });
        onChange({ name, value });
    }

    useEffect(() => {
        if (!defaultFormState) return;
        setSelectedDossierCee(defaultFormState.dossierCEE);
    }, [defaultFormState, onChange]);

    const documentTypeOptions = useMemo(() => {
        const documentTypes = uploadDocumentHelper.getSelectDocumentTypeOptions(operationSheets);
        if (isUploadOtherDocument) {
            return documentTypes.filter(({ value }) =>
                [dossierCEEDocumentTypes.OTHER, dossierCEEDocumentTypes.SITE_PHOTO_BEFORE_RENOVATION, dossierCEEDocumentTypes.SITE_PHOTO_AFTER_RENOVATION].includes(value)
            );
        }
        return documentTypes;
    }, [operationSheets, isUploadOtherDocument]);

    return (
        <Modal
            testId={UPLOAD_DOCUMENT_MODAL_TEST_ID}
            show={show}
            onHide={onHide}
            size='large'
            title='Ajouter un document'
            actionView={
                <Button lightMode='1' onClick={onSave}>
                    Enregistrer
                </Button>
            }>
            <Modal.Body>
                <ViewerFormContainer>
                    {displayFileViewer(fileType, file)}
                    <Form marginY='none'>
                        <Form.Group>
                            <Field
                                type='text'
                                fluid
                                name='fileName'
                                label='Nom du document'
                                defaultValue={fileName}
                                onChange={onChange}
                                error={formErrors.fileName}
                                disabled={!(uploadDocumentTypes.OTHER === documentType)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Field
                                type='select'
                                fluid
                                name='documentType'
                                disabled={!isUploadOtherDocument && disabledFieldsSelection}
                                defaultValue={uploadDocumentHelper.getDocumentTypeLabel(defaultFormState?.documentType)}
                                label={LABEL_SELECT_DOCUMENT_TYPE}
                                options={documentTypeOptions}
                                error={formErrors.documentType}
                                onChange={handleDocumentTypeChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Autocomplete
                                fluid
                                async={true}
                                loading={isLoading}
                                disabled={!documentType || disabledFieldsSelection}
                                defaultValue={defaultFormState?.dossierCEE?.filterableQuotationProperties?.label}
                                label={LABEL_AUTOCOMPLETE_DOSSIER}
                                onChange={onSearch}
                                onSelect={handleSelectDossierCEE}
                                options={dossiersCEE}
                                error={formErrors.dossierCEEId}
                            />
                            {message?.select_dossierCEE && <Alert message={message?.select_dossierCEE} type='warning' showIcon banner />}
                        </Form.Group>

                        {uploadDocumentHelper.isAttestationType(formState) && formState?.quotation && selectedDossierCee && (
                            <Form.Group>
                                <Field
                                    type='select'
                                    fluid
                                    name='operationUUID'
                                    disabled={disabledFieldsSelection}
                                    defaultValue={formState?.attestationOperationName}
                                    label={LABEL_SELECT_OPERATION_RELATED_TO_ATTESTATION}
                                    options={uploadDocumentHelper.getListOfOperationNeedingAnAttestation(formState.quotation, selectedDossierCee, formState?.documentType)}
                                    onChange={onChange}
                                />
                            </Form.Group>
                        )}

                        {getExtraJSXByDocumentType()}
                        {displaySignatureDate(documentType, onChange, getMinSignatureDate(), getMaxSignatureDate(), formState?.signatureDate)}
                        <Form.Group>{informationOperationSheetValidity && <span>Information : {informationOperationSheetValidity}</span>}</Form.Group>

                        {allSitePhotos.includes(documentType) && (
                            <Form.Group>
                                <Field fluid type='textarea' name='comment' label={LABEL_INPUT_COMMENT_OPTIONAL} error={formErrors.comment} onChange={onChange} />
                            </Form.Group>
                        )}

                        {uploadDocumentHelper.canShowHeatingPumpCoverageRateInput(documentType, selectedDossierCee) && (
                            <Form.Group>
                                <Field
                                    fluid
                                    textAppearance='initial'
                                    type='number'
                                    name='heatingPumpCoverageRate'
                                    label={uploadDocumentHelper.formatHeatingPumpCoverageRateInputLabel(selectedDossierCee)}
                                    min={uploadDocumentHelper.extractHeatingPumpCoverageRate(selectedDossierCee)}
                                    error={formErrors.heatingPumpCoverageRate}
                                    onChange={onChange}
                                />
                            </Form.Group>
                        )}

                        {displayPostRenovationReportForm(
                            documentType,
                            formErrors,
                            onChange,
                            uploadDocumentMinDates.postRenovationReportAccreditationDate(selectedDossierCee?.quotation?.signatureDate),
                            formState?.accreditationExpirationDate
                        )}
                        {allSitePhotos.includes(documentType) && <UploadMultipleFiles file={file} onChange={onChange} updateFormState={updateFormState} formState={formState} />}
                    </Form>
                </ViewerFormContainer>
            </Modal.Body>
        </Modal>
    );
}
CreateUploadDocumentModal.propTypes = {
    show: PropTypes.bool.isRequired,
    formState: PropTypes.shape({
        documentType: PropTypes.string,
    }).isRequired,
    formErrors: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

const fileTypeViewers = {
    [uploadDocumentFileTypes.IMAGE]: ImageViewer,
    [uploadDocumentFileTypes.PDF]: PDFViewer,
};

function displayFileViewer(fileType, file) {
    const Viewer = fileTypeViewers[fileType];

    return Viewer ? <Viewer file={file} minHeight='60vh' /> : <></>;
}

function displayPostRenovationReportForm(documentType, formErrors, onChange, minDate, accreditationExpirationDate) {
    return (
        uploadDocumentHelper.isPostRenovationReport(documentType) && (
            <>
                <Form.Group>
                    <Field type='text' fluid name='companyName' label="Raison sociale de l'organisme de contrôle" error={formErrors.companyName} onChange={onChange} />
                </Form.Group>
                <Form.Group>
                    <Field type='text' fluid name='siren' label="Numéro SIREN de l'organisme de contrôle" error={formErrors.siren} onChange={onChange} />
                </Form.Group>
                <Form.Group>
                    <Field
                        type='text'
                        fluid
                        name='accreditationNumber'
                        label="Numéro d’accréditation (COFRAC) ou équivalent de l'organisme de contrôle"
                        error={formErrors.accreditationNumber}
                        onChange={onChange}
                    />
                </Form.Group>
                <Form.Group>
                    <DatePicker
                        name='accreditationExpirationDate'
                        defaultValue={accreditationExpirationDate}
                        label="Date de fin de validité de l’accréditation de l'organisme de contrôle"
                        onChange={onChange}
                        minDate={minDate}
                    />
                </Form.Group>
                <Form.Group>
                    <Field type='text' fluid name='reference' label="Référence du rapport établi par l'organisme de contrôle" error={formErrors.reference} onChange={onChange} />
                </Form.Group>
            </>
        )
    );
}

function displaySignatureDate(documentType, onChange, minDate, maxDate, signatureDate) {
    return (
        uploadDocumentHelper.isSignatureDateRequired(documentType) && (
            <Form.Group>
                <DatePicker name='signatureDate' label='Date de signature' onChange={onChange} defaultValue={signatureDate} minDate={minDate} maxDate={maxDate} />
            </Form.Group>
        )
    );
}

function displayAuditForm(formErrors, onChange) {
    return (
        <>
            <Form.Group>
                <Field
                    type='select'
                    fluid
                    name='energeticClass'
                    label={'Sélectionner la classe énergétique du bâtiment'}
                    options={[
                        { label: 'A', value: 'A' },
                        { label: 'B', value: 'B' },
                        { label: 'C', value: 'C' },
                        { label: 'D', value: 'D' },
                        { label: 'E', value: 'E' },
                        { label: 'F', value: 'F' },
                        { label: 'G', value: 'G' },
                    ]}
                    error={formErrors.energeticClass}
                    onChange={onChange}
                />
            </Form.Group>
            <Form.Group>
                <DatePicker name='signatureDate' label='Date de signature' onChange={onChange} />
            </Form.Group>
        </>
    );
}

export default CreateUploadDocumentModal;
