import { isNonEmptyArray, isNonEmptyObject, isNullishOrEmpty } from '.';
import { invoiceStatuses, invoiceStatusesLabels } from './enums';
import history from './history';
import quotationHelper from './quotation-helper';
import entitiesUtils from './entities-utils';
import { DELEGATEE_INVOICE_MUST_BE_COMPLETED, NO_MORE_INVOICEABLE_OPERATION } from './messages';
import companies from '../data/companies';

function formatInvoiceableDelegateesAutocompleteOptions(invoiceableDelegatees) {
    return invoiceableDelegatees.map((delegatee) => ({
        label: delegatee.name,
        value: delegatee,
    }));
}

function formatDelegateeInvoiceReference({ reference, status, historical }) {
    if (historical.length > 0) {
        const invoiceStatuse = invoiceStatusesLabels[status];
        const invoiceReference = historical[0].reference;

        return historical[0].status === invoiceStatuses.RECTIFIED ? `${invoiceStatuse} (remplace la facture n° ${invoiceReference})` : `${invoiceStatuse} (annule la facture n° ${invoiceReference})`;
    }

    return reference ? reference : invoiceStatusesLabels.DRAFT;
}

function formatDelegateeInvoiceCustomerName({ name, siren, recipient }) {
    if (isNonEmptyObject(recipient)) return `${recipient.name} (${name})`;
    if (name !== companies.RENOLIB.name) return name;

    const company = extractCompanyUsingSiren(siren);

    return isNonEmptyObject(company) ? `${companies.RENOLIB.name} (${company.name})` : name;
}

function extractCompanyUsingSiren(siren) {
    return Object.values(companies).find((company) => company.siren === siren);
}

function formatDelegateesInvoiceForTable(delegateeInvoices) {
    return delegateeInvoices.map((delegateeInvoice) => ({
        ...delegateeInvoice,
        delegateeInvoice,
    }));
}

function openDelegateeInvoiceEditionPage(delegateeInvoice) {
    history.push(`/factures-obliges/${delegateeInvoice.uuid}/edition`);
}

function openDelegateeInvoicePreviewPage(delegateeInvoice) {
    history.push(`/factures-obliges/${delegateeInvoice.uuid}/apercu`);
}

function openDelegateeInvoicesPage() {
    if (history.location.pathname !== '/factures-obliges') history.push(`/factures-obliges`);
}

function formatOperationsOptions(invoiceableOperations, selectedOperations = []) {
    return invoiceableOperations
        .filter((invoiceableOperation) => {
            return !selectedOperations.some((selectedOperation) => selectedOperation.uuid === invoiceableOperation.uuid);
        })
        .map((invoiceableOperation) => ({
            value: invoiceableOperation,
            label: formatOperationOption(invoiceableOperation),
        }));
}

function formatOperationOption(operation) {
    if (isNullishOrEmpty(operation)) return '';

    const { operationSheetName, operationVolumesAndPremium, customerEnergySavingCertificatePremium, invoice } = operation;
    const formattedCustomerName = quotationHelper.formatCustomerName(invoice.customer);
    const formattedOperationSheetName = quotationHelper.formatOperationSheetName(operationSheetName);
    const formattedVolume = formatTotalVolume(operationVolumesAndPremium);
    const formattedCustomerPremium = formatCustomerPremium(customerEnergySavingCertificatePremium);

    return `${formattedCustomerName} - ${formattedOperationSheetName} - ${formattedVolume} - ${formattedCustomerPremium}`;
}

function formatTotalVolume(operationVolumesAndPremium) {
    return `Volume ${operationVolumesAndPremium.volumeTotal} kWh cumac`;
}

function formatCustomerPremium(customerMinimumEnergySavingCertificatePremium) {
    return `Prime CEE client ${customerMinimumEnergySavingCertificatePremium} €`;
}

function formatTotalVolumeForOperations(operations) {
    const totalVolume = operations.filter(isNonEmptyObject).reduce((total, { operationVolumesAndPremium }) => {
        return total + operationVolumesAndPremium.volumeTotal;
    }, 0);

    return entitiesUtils.formatVolume(totalVolume);
}

function formatDisbursementsTotalPriceForOperations(operations) {
    const disbursementsTotalPrice = operations.filter(isNonEmptyObject).reduce((total, { customerEnergySavingCertificatePremium }) => {
        return total + customerEnergySavingCertificatePremium;
    }, 0);

    return entitiesUtils.formatPrice(disbursementsTotalPrice);
}

function formatServicesTotalPriceForOperations(operations) {
    const servicesTotalPrice = operations.filter(isNonEmptyObject).reduce((total, { operationVolumesAndPremium, customerEnergySavingCertificatePremium }) => {
        return total + operationVolumesAndPremium.premium - customerEnergySavingCertificatePremium;
    }, 0);

    return entitiesUtils.formatPrice(servicesTotalPrice);
}

function formatOperationsForRequest(operations) {
    return operations.map((operation) => ({
        ...operation,
        invoice: isNonEmptyObject(operation.invoice) ? operation.invoice.id : operation.invoice,
    }));
}

function filterSelectedOperations(operations, delegateeInvoice) {
    return operations.filter((operation) => !delegateeInvoice.operations.some(({ uuid }) => uuid === operation.uuid));
}

function filterFinalizedDelegateeInvoices(delegateeInvoices = []) {
    return delegateeInvoices.filter((delegateeInvoice) => delegateeInvoice.status === invoiceStatuses.FINALIZED);
}

function filterSentDelegateeInvoices(delegateeInvoices = []) {
    return delegateeInvoices.filter((delegateeInvoice) => [invoiceStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION, invoiceStatuses.SENT].includes(delegateeInvoice.status));
}

function isDelegateeInvoiceCompleted(delegateeInvoice) {
    return isNonEmptyObject(delegateeInvoice.billingNote) && isNonEmptyArray(delegateeInvoice.operations);
}

function getFinalizeButtonTooltipContent(delegateeInvoice) {
    return isDelegateeInvoiceCompleted(delegateeInvoice) ? '' : DELEGATEE_INVOICE_MUST_BE_COMPLETED;
}

function removeUnSpecifiedOperations(operations = []) {
    return operations.filter(isNonEmptyObject);
}

function getAddOperationButtonTooltipContent(invoiceableOperations = []) {
    return invoiceableOperations.length === 0 ? NO_MORE_INVOICEABLE_OPERATION : '';
}

function updateDelegateeInvoiceOperation(delegateeInvoice, { operationUUID, changes }) {
    return delegateeInvoice.operations.map((operation) => {
        if (operation.uuid === operationUUID) {
            return { ...operation, ...changes };
        }

        return operation;
    });
}

function extractDelegateeInvoiceOperationsFromState(getState) {
    return getState().delegateeInvoicesState.delegateeInvoice?.operations || [];
}

function isInvoiceRectifiable(delegateeInvoice) {
    return delegateeInvoice.status === invoiceStatuses.FINALIZED;
}

function isDelegateeInvoiceCancellable(delagateeInvoice) {
    if (delagateeInvoice.isCreditNoteInvoice) return false;

    return [invoiceStatuses.FINALIZED, invoiceStatuses.SENT, invoiceStatuses.SENT_FOR_BACK_OFFICE_VERIFICATION, invoiceStatuses.PAID, invoiceStatuses.RECTIFIED].includes(delagateeInvoice?.status);
}

export default {
    formatInvoiceableDelegateesAutocompleteOptions,
    formatDelegateeInvoiceReference,
    formatDelegateesInvoiceForTable,
    openDelegateeInvoiceEditionPage,
    openDelegateeInvoicePreviewPage,
    formatOperationsOptions,
    formatOperationOption,
    openDelegateeInvoicesPage,
    formatTotalVolumeForOperations,
    formatDisbursementsTotalPriceForOperations,
    formatServicesTotalPriceForOperations,
    formatOperationsForRequest,
    filterSelectedOperations,
    filterFinalizedDelegateeInvoices,
    filterSentDelegateeInvoices,
    isDelegateeInvoiceCompleted,
    getFinalizeButtonTooltipContent,
    removeUnSpecifiedOperations,
    getAddOperationButtonTooltipContent,
    updateDelegateeInvoiceOperation,
    extractDelegateeInvoiceOperationsFromState,
    formatDelegateeInvoiceCustomerName,
    isInvoiceRectifiable,
    isDelegateeInvoiceCancellable,
};
