import { LABEL_INPUT_SHOWER_HEAD_CLASS, LABEL_INPUT_JET_REGULATOR_CLASS } from '../../form_labels';
import { productSubCategories } from '../../enums';

export default [
    {
        property: LABEL_INPUT_SHOWER_HEAD_CLASS,
        label: LABEL_INPUT_SHOWER_HEAD_CLASS,
        type: 'radio',
        requiredIf: isShowerHeadSubCaterogry,
    },
    {
        property: LABEL_INPUT_JET_REGULATOR_CLASS,
        label: LABEL_INPUT_JET_REGULATOR_CLASS,
        type: 'radio',
        requiredIf: isJetRegulatorSubCaterogry,
    },
];

function isShowerHeadSubCaterogry(formState) {
    return formState.subCategory === productSubCategories.WATER_SAVING_SYSTEMS.SHOWER_HEAD;
}

function isJetRegulatorSubCaterogry(formState) {
    return formState.subCategory === productSubCategories.WATER_SAVING_SYSTEMS.JET_REGULATOR;
}
