import { v1 as uuidV1 } from 'uuid';
import { getDocumentDownloadLink, getDocumentFileTypeFromDownloadUrl, getDocumentTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes, invoiceStatuses } from '../../enums';
import { DOSSIER_CEE_CANCELLED_DOCUMENT, DOSSIER_CEE_RECTIFIED_INVOICE } from '../../messages';

export const extractCancelledDocuments = (dossierCEE) => {
    if (!dossierCEE.invoice || dossierCEE.invoice.historical.length === 0) return [];

    const { invoice } = dossierCEE.invoice.historical[0];
    const { swornStatement } = invoice;

    return [
        {
            uuid: uuidV1(),
            title: getDocumentTitle(dossierCEEDocumentTypes.INVOICE),
            subTitle: invoice.status === invoiceStatuses.CANCELLED ? DOSSIER_CEE_CANCELLED_DOCUMENT : DOSSIER_CEE_RECTIFIED_INVOICE,
            documentType: DOSSIER_CEE_CANCELLED_DOCUMENT,
            available: isDocumentDownloadLinkAvailable(invoice),
            fileDownloadUrl: getDocumentDownloadLink(invoice),
            fileType: dossierCEESidebarDocumentFileTypes.PDF,
            position: 3,
            valid: true,
        },
        {
            uuid: uuidV1(),
            documentType: DOSSIER_CEE_CANCELLED_DOCUMENT,
            title: getDocumentTitle(dossierCEEDocumentTypes.SWORN_STATEMENT),
            available: isDocumentDownloadLinkAvailable(swornStatement),
            fileDownloadUrl: getDocumentDownloadLink(swornStatement),
            fileType: getDocumentFileTypeFromDownloadUrl(swornStatement),
            subTitle: DOSSIER_CEE_CANCELLED_DOCUMENT,
            position: 6,
            valid: true,
        },
    ];
};
