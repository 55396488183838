import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { getOperationOfRenovationGlobalIndex } from '../../renovation-global-helper';
import { getDocumentTitle, getDownloadUrl } from '../utils/utils';
export const formatRenovationGlobalAdvisedOperations = (dossierCEE) => {
    const { invoice } = dossierCEE;
    if (!invoice) {
        return {
            uuid: uuidV1(),
            title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST),
            subTitle: '',
            defaultDocument: invoice,
            available: false,
            documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST,
            fileDownloadUrl: undefined,
            fileType: dossierCEESidebarDocumentFileTypes.PDF,
            message: 'À signer par le client après finalisation de la facture',
            position: 9,
        };
    }
    const renovationGlobalIndex = getOperationOfRenovationGlobalIndex(invoice);

    const { documents } = invoice.operations[renovationGlobalIndex].renovationGlobal;
    const documentUrl = getDownloadUrl(documents.operationsList);
    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST),
        subTitle: '',
        available: !!documentUrl,
        fileDownloadUrl: documentUrl,
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        defaultDocument: invoice,
        documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_ADVISED_OPERATIONS_LIST,
        message: !documentUrl ? 'À ajouter après finalisation de la facture' : '',
        position: 9,
    };
};
