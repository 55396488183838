import { shouldRetrieveAllOccupants } from '../../dossier-cee-helper';
import { formatTaxNoticesAsDocument } from './formatTaxNotice';
import { isSitePhotoMandatoryDocument } from '../utils/utils';
import { formatSitePhotosAsDocument } from './formatSitePhoto';
import { formatSitePhotosBeforeRenovationAsDocument } from './formatSitePhotoBeforeRenovation';
import { formatSitePhotosAfterRenovationAsDocument } from './formatSitePhotosAfterRenovation';
import { formatOtherDocumentTypeAsDocument } from './formatOtherDocument';
import { formatOtherUploadedDocumentsAsDocuments } from './formatOtherUploadedDocuments';
import { extractProductFilesDocuments } from './formatProductFilesDocuments';
import { extractProductDocuments } from './formatProductDocuments';
import { extractAdvancePaymentInvoice } from './formatAdvancePaymentOldVersion';
import { extractAdvancePaymentsInvoice } from './formatAdvancePayment';

export const extractAdditionalDocuments = (dossierCEE) => {
    const additionalDocuments = [];
    const { quotation, mandatoryDocuments = [] } = dossierCEE;

    if (!shouldRetrieveAllOccupants(quotation)) {
        const taxNoticesDocument = formatTaxNoticesAsDocument(dossierCEE);
        if (taxNoticesDocument) additionalDocuments.push(taxNoticesDocument);
    }

    if (!isSitePhotoMandatoryDocument(mandatoryDocuments)) {
        const sitePhotosAsImagesDocument = formatSitePhotosAsDocument(dossierCEE);
        if (sitePhotosAsImagesDocument) additionalDocuments.push(sitePhotosAsImagesDocument);
        const sitePhotosBeforeRenovationAsImagesDocument = formatSitePhotosBeforeRenovationAsDocument(dossierCEE);
        if (sitePhotosBeforeRenovationAsImagesDocument) additionalDocuments.push(sitePhotosBeforeRenovationAsImagesDocument);
        const sitePhotosAfterRenovationAsImagesDocument = formatSitePhotosAfterRenovationAsDocument(dossierCEE);
        if (sitePhotosAfterRenovationAsImagesDocument) additionalDocuments.push(sitePhotosAfterRenovationAsImagesDocument);
    }

    const otherDocsAsDocument = formatOtherDocumentTypeAsDocument(dossierCEE);
    if (otherDocsAsDocument) additionalDocuments.push(...otherDocsAsDocument);

    //@todo i think it is neither used.
    const otherDocuments = formatOtherUploadedDocumentsAsDocuments(dossierCEE);
    additionalDocuments.push(...otherDocuments);

    const productDocuments = extractProductDocuments(dossierCEE);
    additionalDocuments.push(...productDocuments);

    const productFilesDocuments = extractProductFilesDocuments(dossierCEE);
    additionalDocuments.push(...productFilesDocuments);

    if (dossierCEE.advancePaymentInvoice) {
        const advancePaymentInvoice = extractAdvancePaymentInvoice(dossierCEE);
        additionalDocuments.push(advancePaymentInvoice);
    }

    if (dossierCEE.advancePaymentsInvoice?.length > 0) {
        const advancePaymentsInvoice = extractAdvancePaymentsInvoice(dossierCEE);

        additionalDocuments.push(...advancePaymentsInvoice);
    }

    return additionalDocuments.map((document) => ({
        ...document,
        available: true,
        valid: true,
    }));
};
