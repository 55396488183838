import { v1 as uuidV1 } from 'uuid';
import { getDocumentDownloadLink, getDocumentFileTypeFromDownloadUrl, getDocumentTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';
import { dossierCEEDocumentTypes } from '../../enums';
import { INVALID_DOSSIER_CEE_SIZING_NOTE } from '../../messages';

export const formatSizingNoteAsDocument = (dossierCEE) => {
    const { sizingNote } = dossierCEE;

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.SIZING_NOTE),
        documentType: dossierCEEDocumentTypes.SIZING_NOTE,
        available: isDocumentDownloadLinkAvailable(sizingNote),
        fileDownloadUrl: getDocumentDownloadLink(sizingNote),
        fileType: getDocumentFileTypeFromDownloadUrl(sizingNote),
        message: INVALID_DOSSIER_CEE_SIZING_NOTE,
        position: 6,
    };
};
