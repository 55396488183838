import React from 'react';
import { Text, TextBold } from '@renolib/renolib-ui-kit';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import fileUploadIcon from '../../assets/img/actions/file-upload.svg';
import colors from '../../config/colors';

const InnerDefaultDropZone = () => {
    return (
        <div>
            <TextBold>Glissez </TextBold>
            <Text>votre document ici ou </Text>
            <TextBold>cliquez </TextBold>
            <Text>dans la zone</Text>
        </div>
    );
};
export const UnStyledUploadDocumentsDropZone = ({ className, maxFileSize = 10, renderInner = () => <InnerDefaultDropZone /> }) => {
    return (
        <div className={className}>
            <img src={fileUploadIcon} alt='' />
            {renderInner()}
            <div>
                <TextBold>(taille {maxFileSize}Mo maximum)</TextBold>
            </div>
        </div>
    );
};
UnStyledUploadDocumentsDropZone.propTypes = {
    maxFileSize: PropTypes.number,
};

export const UploadDocumentsDropZone = styled(UnStyledUploadDocumentsDropZone)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    border: 1px dashed #99a3ac;
    border-radius: 5px;
    transition: all 400ms ease-in-out;
    background: transparent;

    > img {
        height: 3rem;
    }

    > div {
        margin-top: 1rem;

        > span {
            color: ${colors.primary};
        }
    }
`;
