import { v1 as uuidV1 } from 'uuid';
import { getDocumentDownloadLink, getDocumentFileTypeFromDownloadUrl, getDocumentTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';
import { dossierCEEDocumentTypes } from '../../enums';
import { MANDATORY_DOSSIER_CEE_DOCUMENT } from '../../messages';

export const formatCommissioningReportAsDocument = (dossierCEE) => {
    const { commissioningReport } = dossierCEE;
    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.COMMISSIONING_REPORT),
        documentType: dossierCEEDocumentTypes.COMMISSIONING_REPORT,
        available: isDocumentDownloadLinkAvailable(commissioningReport),
        fileDownloadUrl: getDocumentDownloadLink(commissioningReport),
        fileType: getDocumentFileTypeFromDownloadUrl(commissioningReport),
        message: MANDATORY_DOSSIER_CEE_DOCUMENT,
        position: 14,
    };
};
