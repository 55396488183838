import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes } from '../../enums';
import { INVALID_DOSSIER_CEE_QUOTATION, VALID_DOSSIER_CEE_QUOTATION } from '../../messages';
import { getDocumentDownloadLink, getDocumentFileTypeFromDownloadUrl, getDocumentTitle, isDocumentDownloadLinkAvailable } from '../utils/utils';

export const formatQuotationAsDocument = (dossierCEE) => {
    const { quotation } = dossierCEE;
    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.QUOTATION),
        documentType: dossierCEEDocumentTypes.QUOTATION,
        subTitle: VALID_DOSSIER_CEE_QUOTATION,
        defaultDocument: quotation,
        isDeletable: true,
        available: isDocumentDownloadLinkAvailable(quotation),
        fileDownloadUrl: getDocumentDownloadLink(quotation),
        fileType: getDocumentFileTypeFromDownloadUrl(quotation),
        message: INVALID_DOSSIER_CEE_QUOTATION,
        position: 1,
    };
};
