import { getOperationOfRenovationGlobalIndex } from '../../renovation-global-helper';
import { v1 as uuidV1 } from 'uuid';
import { dossierCEEDocumentTypes, dossierCEESidebarDocumentFileTypes } from '../../enums';
import { MANDATORY_DOSSIER_CEE_DOCUMENT } from '../../messages';
import { getDocumentTitle, getDownloadUrl } from '../utils/utils';

export const formatRenovationGlobalCofracBeforeWork = (dossierCEE) => {
    const { quotation } = dossierCEE;
    const renovationGlobalIndex = getOperationOfRenovationGlobalIndex(quotation);
    const { documents } = quotation.operations[renovationGlobalIndex].renovationGlobal;

    const documentUrl = getDownloadUrl(documents.cofracBeforeWork);

    return {
        uuid: uuidV1(),
        title: getDocumentTitle(dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_BEFORE_WORK),
        subTitle: '',
        documentType: dossierCEEDocumentTypes.RENOVATION_GLOBAL_COFRAC_BEFORE_WORK,
        available: !!documentUrl,
        fileDownloadUrl: documentUrl,
        fileType: dossierCEESidebarDocumentFileTypes.PDF,
        message: !documentUrl ? MANDATORY_DOSSIER_CEE_DOCUMENT : '',
        position: 9,
    };
};
