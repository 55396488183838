import { isNonEmptyArray, isNonEmptyObject, isNullishOrEmpty, toNonNullishArray, toNonNullishObject } from '.';
import { accountRoles, accountRolesTranslations, applicationPages, roles } from './enums';
import { MUST_BE_ADMIN } from './messages';
import storage from './storage';

function getIsVerifiedLabel(isVerified) {
    return isVerified ? 'Acceptée' : 'Envoyée';
}

function isOperatorRole(account) {
    return account?.role?.name === roles.OPERATOR;
}

function isAdministratorRole(account) {
    return account?.role?.name === roles.ADMIN;
}

function canShowRoleColumn(account = {}, organization, invitations) {
    const role = extractAccountRole(account, organization);

    return [roles.ADMIN].includes(role?.name) && !isAccountInvited(account, invitations);
}

function isAccountInvited(account, invitations) {
    return invitations.find((invitation) => invitation.invited && invitation?.account?.id === account.id);
}

function canShowAccessRightCell(invitation) {
    return (invitation.invited && isNonEmptyObject(invitation?.account?.role)) || isAdministorUser(invitation);
}

function areAllAccountsLimited(invitations = []) {
    return invitations
        .filter(isInvitedOperator)
        .map(({ account }) => account)
        .every(isOperatorRole);
}

function isInvitedOperator({ invited, account }) {
    return invited && isNonEmptyObject(account) && account.role?.name !== roles.TECHNICIAN;
}

function isAdministorUser({ invited, account }) {
    return !invited && isNonEmptyObject(account) && !isSupportAccount(account);
}

function isSupportAccount({ email }) {
    const regex = /.*support@renolib\.fr/gi;

    return regex.test(email);
}

function isRenolibControllerRole(account) {
    return account?.role?.name === roles.RENOLIB_CONTROLLER;
}

function isOrganizationSwitchCriticalForPage({ pathname, search }) {
    const pageVerificationsStrategies = {
        [applicationPages.QUOTATION_EDITION]() {
            return /\/devis\/.+\/edition/.test(pathname);
        },
        [applicationPages.INVOICE_EDITION]() {
            return /\/factures\/.+\/edition/.test(pathname);
        },
        [applicationPages.CUSTOMER_PP_CREATION]() {
            return /\/creer-client-particulier/.test(pathname);
        },
        [applicationPages.CUSTOMER_PM_CREATION]() {
            return /\/clients\/.+/.test(pathname) && search === '?type=morale&action=creer';
        },
    };
    for (const strategy in pageVerificationsStrategies) {
        if (pageVerificationsStrategies[strategy]()) {
            return true;
        }
    }

    return false;
}

function extractAccountRolePages(account, organization) {
    const organizationUUID = storage.getOrganizationUUID();
    if (isNullishOrEmpty(account) || isNullishOrEmpty(account?.organizationsAccess) || (isNullishOrEmpty(organization) && isNullishOrEmpty(organizationUUID))) return [];

    const organizationAccess = account?.organizationsAccess?.find((access) => access?.organization?.id === organization?.id || access?.organization?.uuid === organizationUUID);

    return isNonEmptyObject(organizationAccess) ? toNonNullishArray(organizationAccess.role.pages).concat(toNonNullishArray(organization?.pages)) : [];
}

function extractAccountRoleActions(account, organization) {
    const defaultPermissions = toNonNullishObject(organization?.defaultPermissions);
    if (isNullishOrEmpty(account) || isNullishOrEmpty(account.organizationsAccess)) return defaultPermissions;

    const organizationAccess = account.organizationsAccess.find((access) => access?.organization?.id === organization?.id);

    return isNonEmptyObject(organizationAccess) ? { ...toNonNullishArray(organizationAccess.role.actions), ...toNonNullishObject(organization.defaultPermissions) } : {};
}

function extractAccountRole(account, organization) {
    if (isNullishOrEmpty(account) || isNullishOrEmpty(account.organizationsAccess) || isNullishOrEmpty(organization)) return {};

    const organizationAccess = account.organizationsAccess.find((access) => access.organization.id === organization.id);

    return isNonEmptyObject(organizationAccess) ? toNonNullishObject(organizationAccess.role) : {};
}

function extractAccountRoleAfterLogin(account) {
    if (isNullishOrEmpty(account) || isNullishOrEmpty(account.organizationsAccess) || isNullishOrEmpty(account.uuidOrganization)) return {};

    const organizationAccess = account.organizationsAccess.find((access) => access.organization.uuid === account.uuidOrganization);

    return isNonEmptyObject(organizationAccess) ? toNonNullishObject(organizationAccess.role) : {};
}

function formatAccountRoleForTable(account, organization) {
    const role = extractAccountRole(account, organization);

    return isNonEmptyObject(role) ? accountRolesTranslations[role.name] : '';
}

function isAccountRoleUpdateDisabled(account, organization) {
    return isNonEmptyArray(organization.accounts) ? organization.accounts[0].id === account.id : true;
}

function isAccountInvitationDisabled(account, organization) {
    const role = extractAccountRole(account, organization);

    return role?.name !== accountRoles.ADMIN;
}

function getInvitationButtonTooltipContent(account, organization) {
    return isAccountInvitationDisabled(account, organization) ? MUST_BE_ADMIN : '';
}

export default {
    getIsVerifiedLabel,
    isOperatorRole,
    isAdministratorRole,
    canShowRoleColumn,
    canShowAccessRightCell,
    areAllAccountsLimited,
    isRenolibControllerRole,
    isOrganizationSwitchCriticalForPage,
    extractAccountRolePages,
    extractAccountRoleActions,
    extractAccountRole,
    extractAccountRoleAfterLogin,
    formatAccountRoleForTable,
    isAccountRoleUpdateDisabled,
    isAccountInvitationDisabled,
    getInvitationButtonTooltipContent,
};
