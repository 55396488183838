import { endpointV2, authHeaders } from '../../api';

const createContract = (uuidOrganization, contractFormData) =>
    endpointV2.post(`/organizations/${uuidOrganization}/contracts`, contractFormData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...authHeaders(),
        },
    });

const getContracts = (uuidOrganization, options) =>
    endpointV2.get(`/organizations/${uuidOrganization}/contracts`, {
        headers: authHeaders(),
        params: options,
    });

const getContract = (uuidOrganization, uuidContract) =>
    endpointV2.get(`/organizations/${uuidOrganization}/contracts/${uuidContract}`, {
        headers: authHeaders(),
    });

const deleteContract = (uuidOrganization, uuidAccount, contractUUID) =>
    endpointV2.delete(
        `/organizations/${uuidOrganization}/contracts/${contractUUID}`,

        {
            headers: authHeaders(),
            params: { uuidAccount },
        }
    );

const patchContractWithFile = async (uuidOrganization, uuidAccount, uuidContract, formData) =>
    endpointV2.patch(`/organizations/${uuidOrganization}/contracts/${uuidContract}`, formData, {
        headers: {
            ...authHeaders(),
        },
        params: { uuidAccount },
    });

const patchContractWithJsonObject = async (uuidOrganization, uuidAccount, uuidContract, operationName, path, value) =>
    endpointV2.patch(
        `/organizations/${uuidOrganization}/contracts/${uuidContract}`,
        {
            op: operationName,
            path,
            value,
        },
        {
            headers: { 'Content-Type': 'application/json', ...authHeaders() },
            params: { uuidAccount },
        }
    );

const getContractsAnalytics = async (uuidOrganization) =>
    endpointV2.get(`/organizations/${uuidOrganization}/analytical-data/contracts`, {
        headers: authHeaders(),
    });

export default {
    createContract,
    getContracts,
    deleteContract,
    patchContractWithFile,
    patchContractWithJsonObject,
    getContractsAnalytics,
    getContract,
};
