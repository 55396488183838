import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import heic2any from 'heic2any';

import dialogsActions from '../../store/actions/ui/dialogs';

export default function ({ maxFileSize = 10 } = {}) {
    const inputFileRef = useRef(null);

    const dispatch = useDispatch();

    const resetFileInput = () => {
        if (!inputFileRef.current) return;
        inputFileRef.current.value = null;
    };

    function isMaximumFileSizeExceeded(file = {}) {
        const value = file?.size > maxFileSize * 1000 * 1000;

        return value;
    }

    function onFileSizeExceed() {
        dispatch(
            dialogsActions.showFailureDialog({
                message: `La taille de votre fichier est supérieure à ${maxFileSize}Mo (taille maximale autorisée). Merci de réduire la taille de votre fichier avant de l’ajouter à nouveau.`,
            })
        );
        resetFileInput();
    }

    function isAnHeicFile(file) {
        return file.name?.includes('.HEIC');
    }

    const convertFileToHeicBlob = (file, config) => heic2any({ blob: file, toType: 'image/jpeg', quality: 0.5, ...config });
    const convertHeicBlobToFile = (file, blob) => new File([blob], file.name.replace('.heic', '.jpeg'), { type: 'image/jpeg' });

    const convertHeicToJpeg = async (file, config) => convertFileToHeicBlob(file, config).then((blob) => convertHeicBlobToFile(file, blob));

    return { isMaximumFileSizeExceeded, onFileSizeExceed, inputFileRef, isAnHeicFile, convertHeicToJpeg };
}
